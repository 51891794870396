import { Box, Grid, Modal, styled, TextField, Typography } from "@mui/material";
import { randomUUID } from "crypto";
import { useEffect, useState } from "react";
import { getCRDataSet, parseCr, parseNumericCR } from "../../../constants/Cr.constants";
import Status from "../../../constants/CreatureStatus";
import Teams, { teamDataSet } from "../../../constants/Teams.constants";
import { validateInt, validatePosInt } from "../../../helper/validation/inputValidation";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import myTheme from "../../../myTheme";
import { addCreatureToInit } from "../../../redux/slices/initSlice";
import { IInitItem } from "../../../types/InitiativeTypes";
import DsButton from "../../input/DsButton/DsButton";
import DsDropdown from "../../input/DsDropdown/DsDropdown";
import DsInput from "../../input/DsInput/DsInput";
import { BiSave } from 'react-icons/bi';
import AdvantagePicker from "../../inititivePageComponents/AdvantagePicker/AdvantagePicker";
import { v4 } from "uuid";
import { selectCopyCreature, selectCreatures, selectSelectedCreature, setCopyCreature, setSelectedCreature } from "../../../redux/slices/creatureSlice";
import StatblockAutocomplete from "../../input/StatblockAutocomplete/StatblockAutocomplete";
import { ICreature, IMovement, ISave, ISaveLi, ISenseStat, ISimpleSchema, ISkill, ISpeed } from "../../../types/CreatureTypes";
import { Wrapper } from "../../dataDisplay/AccordionList/AccordionList";
import ToggleButton from "../../input/buttons/ToggleButton";
import { StatLabel } from "../ViewCreatureModal/ViewCreatureModal";
import { getAbilityMod, getAbilityModFromString, getSavesLiList, getSavesList } from "../../../helper/creatureHelper/creatureHelper";
import SimpleSchemaEditor from "../../input/SimpleSchemaEditor/SimpleSchemaEditor";
import SimpleSchemaListEditor from "../../input/listEditors/SimpleSchemaListEditor/SimpleSchemaListEditor";
import { selectAuthToken } from "../../../redux/slices/userSlice";
import { createCreature } from "../../../api/postData";
import { selectConfirmActive, setAddCreatureActive, setConfirmActive } from "../../../redux/slices/modalSlice";
import { updateCreature } from "../../../api/updateData";
import { addNewCondition, addNewCreatureType, addNewDamageType, addNewLanguage, addNewMovement, addNewSense, addNewSize, addNewSkill, addNewSource, selectCSBConditions, selectCSBCreatureTypes, selectCSBDamageTypes, selectCSBLanguages, selectCSBMovements, selectCSBSenses, selectCSBSizes, selectCSBSkills, selectCSBSources } from "../../../redux/slices/statblockItemSlice";
import { createNewItems, createNewItemsNoValue } from "../../../helper/createNewItems";
import SimpleSchemaValueListEditor from "../../input/listEditors/SimpleSchemaValueListEditor/SimpleSchemaValueListEditor";
import { ABILITY_SCORES, ALIGNMENTS } from "../../../constants/SbItem.constants";
import GenericModal from "../GenericModal/GenericModal";
import ConfirmModal from "../ConfirmModal/ConfirmModal";

const ContentGrid = styled(Grid)({});

const crDataSet = getCRDataSet();

const AddCreatureModal = () => {

    const [name, setName] = useState<string>("");
    const [hp, setHp] = useState<string>("1");
    const [ac, setAc] = useState<string>("10");
    const [cr, setCr] = useState<string>("0");
    const [str, setStr] = useState<string>("10");
    const [dex, setDex] = useState<string>("10");
    const [con, setCon] = useState<string>("10");
    const [int, setInt] = useState<string>("10");
    const [wis, setWis] = useState<string>("10");
    const [cha, setCha] = useState<string>("10");
    const [pp, setPP] = useState<string>("10");
    const [creatureTypes, setCreatureTypes] = useState<ISimpleSchema[]>([]);
    const [sizes, setSizes] = useState<ISimpleSchema[]>([]);
    const [alignment, setAlignment] = useState<ISimpleSchema[]>([]);
    const [speeds, setSpeeds] = useState<ISpeed[]>([]);
    const [abilities, setAbilities] = useState<ISimpleSchema[]>([]);
    const [actions, setActions] = useState<ISimpleSchema[]>([]);
    const [legendaryActions, setLegendaryActions] = useState<ISimpleSchema[]>([]);
    const [saves, setSaves] = useState<ISaveLi[]>([]);
    const [skills, setSkills] = useState<ISkill[]>([]);
    const [vulnerabilities, setVulnerabilities] = useState<ISimpleSchema[]>([]);
    const [resistances, setResistances] = useState<ISimpleSchema[]>([]);
    const [immunities, setImmunities] = useState<ISimpleSchema[]>([]);
    const [condImmunities, setCondImmunities] = useState<ISimpleSchema[]>([]);
    const [senses, setSenses] = useState<ISenseStat[]>([]);
    const [languages, setLanguages] = useState<ISimpleSchema[]>([]);
    const [source, setSource] = useState<ISimpleSchema[]>([]);

    const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const authToken = useAppSelector(selectAuthToken);
    const selectedCreature = useAppSelector(selectSelectedCreature);
    const copyCreature = useAppSelector(selectCopyCreature);
    const sbCreatureTypes = useAppSelector(selectCSBCreatureTypes);
    const sbSizes = useAppSelector(selectCSBSizes);
    const sbMovements = useAppSelector(selectCSBMovements);
    const sbSkills = useAppSelector(selectCSBSkills);
    const sbDamageTypes = useAppSelector(selectCSBDamageTypes);
    const sbConditions = useAppSelector(selectCSBConditions);
    const sbSenses = useAppSelector(selectCSBSenses);
    const sbLanguages = useAppSelector(selectCSBLanguages);
    const sbSources = useAppSelector(selectCSBSources);

    useEffect(() => {
        if (sbMovements && speeds.length === 0 && showAdvanced && !selectedCreature) {
            for (let i = 0; i < sbMovements.length; i++) {
                const m = sbMovements[i];
                if (m.name === "walk") {
                    setSpeeds((prev: ISpeed[]) => [...prev, {
                        movement: m,
                        speed: 30
                    }]);
                    break;
                }
            }
        }
    }, [sbMovements, showAdvanced]);

    useEffect(() => {
        if (selectedCreature) {
            setName(copyCreature ? "Copy of " + selectedCreature.name : selectedCreature.name);
            setHp(selectedCreature.hit_points + "");
            setAc(selectedCreature.armor_class + "");
            setCr(parseNumericCR(selectedCreature.challenge_rating));
            setStr(selectedCreature.strength ? selectedCreature.strength + "" : "10");
            setDex(selectedCreature.dexterity ? selectedCreature.dexterity + "" : "10");
            setCon(selectedCreature.constitution ? selectedCreature.constitution + "" : "10");
            setInt(selectedCreature.intelligence ? selectedCreature.intelligence + "" : "10");
            setWis(selectedCreature.wisdom ? selectedCreature.wisdom + "" : "10");
            setCha(selectedCreature.charisma ? selectedCreature.charisma + "" : "10");
            setCreatureTypes(selectedCreature.creatureType ? [...selectedCreature.creatureType] : []);
            setSizes(selectedCreature.size ? [{ ...selectedCreature.size }] : []);
            setAlignment(selectedCreature.alignment ? [{ _id: "1112", name: selectedCreature.alignment }] : []);
            setSpeeds(selectedCreature.speed ? [...selectedCreature.speed] : []);
            setAbilities(selectedCreature.special_abilities ? [...selectedCreature.special_abilities] : []);
            setActions(selectedCreature.actions ? [...selectedCreature.actions] : []);
            setLegendaryActions(selectedCreature.legendary_actions ? [...selectedCreature.legendary_actions] : []);
            setSaves(selectedCreature.saves ? getSavesLiList(selectedCreature.saves) : []);
            setSkills(selectedCreature.skills ? [...selectedCreature.skills] : []);
            setVulnerabilities(selectedCreature.damage_vulnerabilities ? [...selectedCreature.damage_vulnerabilities] : []);
            setResistances(selectedCreature.damage_resistances ? [...selectedCreature.damage_resistances] : []);
            setImmunities(selectedCreature.damage_immunities ? [...selectedCreature.damage_immunities] : []);
            setCondImmunities(selectedCreature.condition_immunities ? [...selectedCreature.condition_immunities] : []);
            setSenses(selectedCreature.senses ? [...selectedCreature.senses] : []);
            setLanguages(selectedCreature.languages ? [...selectedCreature.languages] : []);
            setSource(selectedCreature.source && typeof selectedCreature.source === "object" ? [{ ...selectedCreature.source }] : []);
        }
    }, [selectedCreature]);

    const handleAdd = async () => {
        const hpNum = parseInt(hp)
        const acNum = parseInt(ac)
        const crNum = parseCr(cr);
        const strNum = parseInt(str);
        const dexNum = parseInt(dex);
        const conNum = parseInt(con);
        const intNum = parseInt(int);
        const wisNum = parseInt(wis);
        const chaNum = parseInt(cha);
        const ppNum = parseInt(pp);

        const newCreatureTypes = await createNewItemsNoValue(creatureTypes, "creatureType", addNewCreatureType);
        const newSizes = await createNewItemsNoValue(sizes, "size", addNewSize);
        const newSpeeds = await createNewItems(speeds, "movement", "movement", addNewMovement);
        const newAbilities = abilities.map(li => ({
            name: li.name,
            desc: li.desc
        }));
        const newActions = actions.map(li => ({
            name: li.name,
            desc: li.desc
        }));
        const newLegendaryActions = legendaryActions.map(li => ({
            name: li.name,
            desc: li.desc
        }));
        const newSaves = getSavesList(saves);
        const newSkills = await createNewItems(skills, "skill", "skill", addNewSkill);
        const newVulns = await createNewItemsNoValue(vulnerabilities, "damageType", addNewDamageType);
        const newResits = await createNewItemsNoValue(resistances, "damageType", addNewDamageType);
        const newImmunes = await createNewItemsNoValue(immunities, "damageType", addNewDamageType);
        const newCondImmunes = await createNewItemsNoValue(condImmunities, "condition", addNewCondition);
        const newSenses = await createNewItems(senses, "sense", "sense", addNewSense);
        const newLangs = await createNewItemsNoValue(languages, "language", addNewLanguage);
        const newSources = await createNewItemsNoValue(source, "source", addNewSource);

        const newCreature: ICreature = selectedCreature && !copyCreature ? {
            ...selectedCreature,
            name,
            hit_points: hpNum,
            armor_class: acNum,
            challenge_rating: crNum,
            strength: strNum,
            dexterity: dexNum,
            constitution: conNum,
            intelligence: intNum,
            wisdom: wisNum,
            charisma: chaNum,
            creatureType: newCreatureTypes,
            size: newSizes ? newSizes[0] : undefined,
            alignment: alignment[0] ? alignment[0].name : undefined,
            speed: newSpeeds,
            special_abilities: newAbilities,
            actions: newActions,
            legendary_actions: newLegendaryActions,
            saves: newSaves,
            skills: newSkills,
            damage_vulnerabilities: newVulns,
            damage_resistances: newResits,
            damage_immunities: newImmunes,
            condition_immunities: newCondImmunes,
            senses: newSenses,
            pSenses: {
                pp: ppNum
            },
            languages: newLangs,
            source: newSources && newSources?.length > 0 ? newSources[0] : selectedCreature.source
        } : {
            name,
            hit_points: hpNum,
            armor_class: acNum,
            challenge_rating: crNum,
            strength: strNum,
            dexterity: dexNum,
            constitution: conNum,
            intelligence: intNum,
            wisdom: wisNum,
            charisma: chaNum,
            creatureType: newCreatureTypes,
            size: newSizes ? newSizes[0] : undefined,
            alignment: alignment[0] ? alignment[0].name : undefined,
            speed: newSpeeds,
            special_abilities: newAbilities,
            actions: newActions,
            legendary_actions: newLegendaryActions,
            saves: newSaves,
            skills: newSkills,
            damage_vulnerabilities: newVulns,
            damage_resistances: newResits,
            damage_immunities: newImmunes,
            condition_immunities: newCondImmunes,
            senses: newSenses,
            pSenses: {
                pp: ppNum
            },
            languages: newLangs,
            source: newSources && newSources?.length > 0 ? newSources[0] : "62081d41cd68980016f931af"
        }

        let res;

        if (selectedCreature && !copyCreature) {
            res = await updateCreature(newCreature);
        } else {
            res = await createCreature(newCreature, authToken);
        }

        if (res) {
            dispatch(setSelectedCreature(undefined));
            dispatch(setCopyCreature(false));
            dispatch(setAddCreatureActive(false));
        }
    }

    const handleClickAdvanced = () => {
        setShowAdvanced((prev: boolean) => !prev);
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <ContentGrid container rowSpacing={"10px"} columnSpacing={"20px"} sx={{ boxSizing: "border-box" }}>
                {/* Row */}
                <DsInput xs={12} label={"Name"} value={name} setValue={setName} name="name" />

                {/* Row */}
                <DsDropdown xs={12}
                    name="cr"
                    label="CR"
                    value={cr}
                    setValue={setCr}
                    data={crDataSet}
                    attribute="cr"
                />

                {/* Row */}
                <DsInput xs={6}
                    name="hp"
                    label="HP"
                    value={hp}
                    setValue={setHp}
                    validate={validatePosInt}
                    selectAllOnFocus
                />
                <DsInput xs={6}
                    name="ac"
                    label="AC"
                    value={ac}
                    setValue={setAc}
                    validate={validatePosInt}
                    selectAllOnFocus
                />

                <ToggleButton
                    label="Advanced"
                    selected={showAdvanced}
                    selectedCust={{
                        backgroundColor: myTheme.primary,
                        borderColor: myTheme.primary,
                        textColor: myTheme.light
                    }}
                    deselectedCust={{
                        backgroundColor: myTheme.bg,
                        borderColor: myTheme.primary,
                        textColor: myTheme.light
                    }}
                    onClick={handleClickAdvanced}
                />

                {showAdvanced && (
                    <>
                        <Grid item xs={12}>
                            <StatLabel>Ability Scores</StatLabel>
                            <Grid container rowSpacing={"10px"} columnSpacing={"20px"} sx={{ boxSizing: "border-box" }}>
                                <DsInput xs={4}
                                    name="str"
                                    label={`Str ${getAbilityModFromString(str)}`}
                                    value={str}
                                    setValue={setStr}
                                    validate={validatePosInt}
                                    selectAllOnFocus
                                />
                                <DsInput xs={4}
                                    name="dex"
                                    label={`Dex ${getAbilityModFromString(dex)}`}
                                    value={dex}
                                    setValue={setDex}
                                    validate={validatePosInt}
                                    selectAllOnFocus
                                />
                                <DsInput xs={4}
                                    name="con"
                                    label={`Con ${getAbilityModFromString(con)}`}
                                    value={con}
                                    setValue={setCon}
                                    validate={validatePosInt}
                                    selectAllOnFocus
                                />
                                <DsInput xs={4}
                                    name="int"
                                    label={`Int ${getAbilityModFromString(int)}`}
                                    value={int}
                                    setValue={setInt}
                                    validate={validatePosInt}
                                    selectAllOnFocus
                                />
                                <DsInput xs={4}
                                    name="wis"
                                    label={`Wis ${getAbilityModFromString(wis)}`}
                                    value={wis}
                                    setValue={setWis}
                                    validate={validatePosInt}
                                    selectAllOnFocus
                                />
                                <DsInput xs={4}
                                    name="cha"
                                    label={`Cha ${getAbilityModFromString(cha)}`}
                                    value={cha}
                                    setValue={setCha}
                                    validate={validatePosInt}
                                    selectAllOnFocus
                                />
                                <DsInput xs={12}
                                    name="pp"
                                    label="Passive Perception"
                                    value={pp}
                                    setValue={setPP}
                                    validate={validatePosInt}
                                    selectAllOnFocus
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Creature Type"
                                itemName="Creature Type"
                                list={creatureTypes}
                                setList={setCreatureTypes}
                                dropdownList={sbCreatureTypes}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Size"
                                itemName="Size"
                                list={sizes}
                                setList={setSizes}
                                dropdownList={sbSizes}
                                maxListLength={1}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Alignment"
                                itemName="Alignment"
                                list={alignment}
                                setList={setAlignment}
                                dropdownList={ALIGNMENTS}
                                maxListLength={1}
                                permitNewItems={false}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaValueListEditor
                                label="Speed"
                                itemName="Movement"
                                list={speeds}
                                setList={setSpeeds}
                                dropdownList={sbMovements}
                                valueName="Speed"
                                valueAttrName="speed"
                                itemAttrName="movement"
                                defaultValue={30}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaValueListEditor
                                label="Saves"
                                itemName="Ability Score"
                                list={saves}
                                setList={setSaves}
                                dropdownList={ABILITY_SCORES}
                                valueName="Mod"
                                valueAttrName="value"
                                itemAttrName="abilityScore"
                                defaultValue={0}
                                permitNewItems={false}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaValueListEditor
                                label="Skills"
                                itemName="Skill"
                                list={skills}
                                setList={setSkills}
                                dropdownList={sbSkills}
                                valueName="Mod"
                                valueAttrName="value"
                                itemAttrName="skill"
                                defaultValue={0}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Vulnerabilities"
                                itemName="Damage Type"
                                list={vulnerabilities}
                                setList={setVulnerabilities}
                                dropdownList={sbDamageTypes}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Resistances"
                                itemName="Damage Type"
                                list={resistances}
                                setList={setResistances}
                                dropdownList={sbDamageTypes}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Immunities"
                                itemName="Damage Type"
                                list={immunities}
                                setList={setImmunities}
                                dropdownList={sbDamageTypes}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Condition Immunities"
                                itemName="Condition"
                                list={condImmunities}
                                setList={setCondImmunities}
                                dropdownList={sbConditions}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaValueListEditor
                                label="Senses"
                                itemName="Sense"
                                list={senses}
                                setList={setSenses}
                                dropdownList={sbSenses}
                                valueName="Range"
                                valueAttrName="range"
                                itemAttrName="sense"
                                defaultValue={60}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Languages"
                                itemName="Language"
                                list={languages}
                                setList={setLanguages}
                                dropdownList={sbLanguages}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Abilities"
                                itemName="Ability"
                                list={abilities}
                                setList={setAbilities}
                                alwaysEdit
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Actions"
                                itemName="Action"
                                list={actions}
                                setList={setActions}
                                alwaysEdit
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Legendary Actions"
                                itemName="Action"
                                list={legendaryActions}
                                setList={setLegendaryActions}
                                alwaysEdit
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SimpleSchemaListEditor
                                label="Source"
                                itemName="Source"
                                list={source}
                                setList={setSource}
                                dropdownList={sbSources}
                                maxListLength={1}
                            />
                        </Grid>
                    </>
                )}

                {/* Row */}
                <DsButton xs={12}
                    name="add"
                    icon={<BiSave fontSize={"20px"} />}
                    label="Save Creature"
                    onClick={handleAdd}
                />
            </ContentGrid>
        </Box>
    )
}

export default AddCreatureModal;
