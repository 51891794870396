import { Box, styled, Typography } from "@mui/material";
import Status from "../../../constants/CreatureStatus";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import myTheme from "../../../myTheme";
import { deleteInitItem, editInitItem, selectSelectedInitItem } from "../../../redux/slices/initSlice";
import { selectDeleteInitItemActive, setDeleteInitItemActive } from "../../../redux/slices/modalSlice";
import { TbGrave2 } from "react-icons/tb";
import { IoMdTrash } from "react-icons/io";
import DeleteButton from "../../input/buttons/DeleteButton";
import KillButton from "../../input/buttons/KillButton";

const ButtonContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
})

const LabelText = styled(Typography)({
    color: myTheme.medGray,
    fontSize: "14px",
    textAlign: "center"
})

const DeleteInitItemModal = () => {

    const modalVisible = useAppSelector(selectDeleteInitItemActive);
    const item = useAppSelector(selectSelectedInitItem);

    const dispatch = useAppDispatch();

    const handleDelete = () => {
        if (!item) return;
        dispatch(deleteInitItem(item));
        dispatch(setDeleteInitItemActive(false));
    }

    const handleKill = () => {
        if (!item) return;
        dispatch(editInitItem({ ...item, status: Status.DEAD }));
        dispatch(setDeleteInitItemActive(false));
    }

    return (
        <>
            {item?.status === Status.ALIVE && (
                <ButtonContainer sx={{marginBottom: "10px"}}>
                    <LabelText>Remove creature and send to graveyard</LabelText>
                    <KillButton onClick={handleKill} />
                </ButtonContainer>
            )}
            <ButtonContainer>
                <LabelText>Remove creature entirely</LabelText>
                <DeleteButton onClick={handleDelete} />
            </ButtonContainer>
        </>
    )
}

export default DeleteInitItemModal;