import { Box, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import myTheme from "../../../myTheme";
import { IInitItem } from "../../../types/InitiativeTypes";
import D20Icon from "../../icons/D20Icon/D20Icon";
import AcIcon from "../../icons/AcIcon/AcIcon";
import HealthBar from "../../dataDisplay/HealthBar/HealthBar";
import { InfoBlock, InnerContainer, NameText, OuterContainer, Row, Lock, Unlock, TeamText, Delete, StatBubble, StatBubbleText, AcWrapper, StatBubbleTextSmall, InfoButton, HealthBarWrapper, DeadText, ArrowUp, XpText } from "./InitLi.styled";
import Teams from "../../../constants/Teams.constants";
import DeathSaves from "../../dataDisplay/DeathSaves/DeathSaves";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { editInitItem, setSelectedInitItem } from "../../../redux/slices/initSlice";
import { setDeathSavesActive, setDeleteInitItemActive, setEditInitItemActive, setInitHpActive, setViewCreatureActive, setViewInitCreatureActive } from "../../../redux/slices/modalSlice";
import Status from "../../../constants/CreatureStatus";
import { getXpByCr } from "../../../constants/Cr.constants";
import { GiHeartWings } from 'react-icons/gi';
import { FaSkull } from 'react-icons/fa';
import { setSelectedCreature } from "../../../redux/slices/creatureSlice";

export interface InitLiProps {
    item: IInitItem,
    index?: number
}

const InitLi = ({ item, index = 1 }: InitLiProps) => {

    const theme = useTheme();

    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const dispatch = useAppDispatch();

    const getCreatureTeamColor = () => {
        switch (item.team) {
            case Teams.ENEMY: return myTheme.enemy
            case Teams.ALLY: return myTheme.friendly
            case Teams.PLAYER: return myTheme.player
            case Teams.NEUTRAL: return myTheme.light
            case Teams.ENVIRONMENT: return myTheme.healthYellow
            default: return myTheme.light
        }
    }

    const handleClickInitItem = () => {
        dispatch(setSelectedInitItem(item));
        dispatch(setEditInitItemActive(true));
    }

    const handleClickHp = (e: any) => {
        e.stopPropagation();
        dispatch(setSelectedInitItem(item));
        dispatch(setInitHpActive(true));
    }

    const handleClickDeathSaves = (e: any) => {
        e.stopPropagation();
        dispatch(setSelectedInitItem(item));
        dispatch(setDeathSavesActive(true));
    }

    const handleClickLock = (e: any) => {
        e.stopPropagation();
        dispatch(editInitItem({ ...item, locked: !item.locked }));
    }

    const handleClickDelete = (e: any) => {
        e.stopPropagation();
        dispatch(setSelectedInitItem(item));
        dispatch(setDeleteInitItemActive(true));
    }

    const handleClickRevive = (e: any) => {
        e.stopPropagation();
        dispatch(editInitItem({ ...item, status: Status.ALIVE }));
    }

    const handleClickInfo = (e: any) => {
        if (item.statblock) {
            e.stopPropagation();
            dispatch(setSelectedInitItem(item));
            dispatch(setViewInitCreatureActive(true));
        }
    }

    return (
        <OuterContainer data-testid={`initLi${index}__outerContainer`}
            sx={{
                opacity: item.status === Status.DEAD ? 0.5 : 1
            }}
        >
            <InnerContainer
                data-testid={`initLi${index}__innerContainer`}
                sx={{
                    borderLeft: "6px solid " + getCreatureTeamColor(),
                }}
                onClick={handleClickInitItem}
            >
                <Row>
                    <NameText data-testid={`initLi${index}__name`}>{item.name}</NameText>
                    <InfoBlock>
                        {item.locked ?
                            <Lock data-testid={`initLi${index}__lock`} onClick={handleClickLock} />
                            :
                            <Unlock data-testid={`initLi${index}__unlock`} onClick={handleClickLock} />
                        }

                        <TeamText data-testid={`initLi${index}__team`} sx={{ color: getCreatureTeamColor() }} >{item.team}</TeamText>

                        <Delete data-testid={`initLi${index}__delete`} onClick={handleClickDelete} />
                    </InfoBlock>

                </Row>

                <Row sx={{ alignItems: item.status === Status.ALIVE ? "flex-end" : "center" }}>
                    <StatBubble>
                        <D20Icon strokeWidth={20} stroke={myTheme.darkest} />
                        <StatBubbleText data-testid={`initLi${index}__initText`}>{item.initiative}</StatBubbleText>
                    </StatBubble>

                    {item.status === Status.ALIVE ? (
                        <>
                            <HealthBarWrapper>
                                <HealthBar
                                    onClick={handleClickHp}
                                    hp={item.hp}
                                    maxHp={item.maxHp}
                                    tempHp={item.tempHp}
                                />
                            </HealthBarWrapper>
                            <InfoBlock sx={{ alignItems: "flex-end", flex: 0, gap: "20px" }}>
                                <AcWrapper>
                                    <AcIcon strokeWidth={20} fill={myTheme.primary} />
                                    <StatBubbleTextSmall data-testid={`initLi${index}__acText`}>{item.ac}</StatBubbleTextSmall>
                                </AcWrapper>

                                <InfoButton
                                    data-testid={`initLi${index}__infoButton`}
                                    sx={{
                                        color: item.statblock ? myTheme.light : myTheme.medDark
                                    }}
                                    onClick={handleClickInfo}
                                />

                                <Box>
                                    <DeathSaves onClick={handleClickDeathSaves} saves={item.saves} size={smallScreen ? 15 : 20} />
                                </Box>
                            </InfoBlock>
                        </>
                    ) : (
                        <>
                            <FaSkull color={myTheme.light} fontSize={"48px"} />
                            <GiHeartWings
                                data-testid={`initLi${index}__resurrectButton`}
                                color={myTheme.light} fontSize={"70px"}
                                onClick={handleClickRevive}
                            />
                            <XpText>XP: {getXpByCr(item.cr)}</XpText>
                        </>
                    )}

                </Row>
            </InnerContainer>
        </OuterContainer>
    )
}

export default InitLi;