import { MdClose } from "react-icons/md";
import DsButton, { DsButtonProps } from "../DsButton/DsButton";
import myTheme from "../../../myTheme";
import { Box } from "@mui/material";
import ConfirmModal from "../../modals/ConfirmModal/ConfirmModal";
import { DeleteButtonProps } from "./DeleteButtonMd";
import { useState } from "react";

const XButton = ({ label, confirmDelete, onClick, ...props }: DeleteButtonProps) => {
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    const handleDelete = (e: any) => {
        if (confirmDelete) {
            setConfirmOpen(true);
        } else {
            handleConfirm(e);
        }
    }

    const handleConfirm = (e: any) => {
        setConfirmOpen(false);
        if (onClick) {
            onClick(e);
        }
    }

    const handleCancel = () => {
        setConfirmOpen(false);
    };

    return (
        <>
            <DsButton
                label={label}
                onClick={handleDelete}
                buttonProps={{
                    style: {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                        width: "30px",
                        height: "30px",
                        padding: "0 1px 1px 0",
                        fontSize: "18px",
                        backgroundColor: myTheme.healthRed,
                        color: myTheme.light,
                        borderRadius: "5px",
                        boxSizing: "border-box",
                        border: "none"
                    }
                }}
                icon={<MdClose />}
                sx={{
                    width: "min-content",
                    boxSizing: "border-box"
                }}
                {...props}
            />
            <ConfirmModal
                open={confirmOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        </>
    );
}

export default XButton;