import { Box, styled } from "@mui/material";
import myTheme from "../../../../myTheme";
import { useAppDispatch } from "../../../../hooks/reduxHooks";
import { GiRollingDices } from "react-icons/gi";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

const FabWrapper = styled(Box)(({ theme }) => ({
    position: "absolute",
    left: "3vw",
    bottom: "10vh",
    backgroundColor: myTheme.spellPurple,
    width: "60px",
    height: "60px",
    paddingTop: "5px",
    boxSizing: "border-box",
    borderRadius: "100%",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
}))

export interface FabProps {
    visibilityToggle: ActionCreatorWithPayload<boolean, string>;
}

const RandomFab = ({ visibilityToggle }: FabProps) => {

    const dispatch = useAppDispatch()

    const handleClick = () => {
        dispatch(visibilityToggle(true));
    }

    return (
        <FabWrapper data-testid="filterFab__wrapper" onClick={handleClick}>
            <GiRollingDices color={myTheme.light} size={"45px"}  />
        </FabWrapper>
    )
}

export default RandomFab;