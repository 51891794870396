import { Box, Grid, Modal, styled, TextField, Typography } from "@mui/material";
import { randomUUID } from "crypto";
import { useEffect, useState } from "react";
import CR, { getCRDataSet, parseCr } from "../../../constants/Cr.constants";
import Status from "../../../constants/CreatureStatus";
import Teams, { teamDataSet } from "../../../constants/Teams.constants";
import { validateInt, validatePosInt } from "../../../helper/validation/inputValidation";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import myTheme from "../../../myTheme";
import { addCreatureToInit, editInitItem, selectSelectedInitItem } from "../../../redux/slices/initSlice";
import { selectEditInitItemActive, setEditInitItemActive } from "../../../redux/slices/modalSlice";
import { IInitItem } from "../../../types/InitiativeTypes";
import DsButton from "../../input/DsButton/DsButton";
import DsDropdown from "../../input/DsDropdown/DsDropdown";
import DsInput from "../../input/DsInput/DsInput";
import { BiSave } from "react-icons/bi";

const Heading = styled(Typography)({
    color: myTheme.light,
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold"
})

const crDataSet = getCRDataSet();

const EditInitItemModal = () => {

    const [name, setName] = useState<string>("");
    const [hp, setHp] = useState<string>("1");
    const [ac, setAc] = useState<string>("10");
    const [cr, setCr] = useState<string>("0");
    const [team, setTeam] = useState<string>(Teams.ENEMY);
    const [initMod, setInitMod] = useState<string>("0");
    const [staticInit, setStaticInit] = useState<string>("");
    const [qty, setQty] = useState<string>("1");

    //Redux selectors
    const modalVisible = useAppSelector(selectEditInitItemActive);
    const selectedInitItem = useAppSelector(selectSelectedInitItem);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (modalVisible && selectedInitItem) {
            setInitMod(selectedInitItem.initMod + '')
            setName(selectedInitItem.name)
            setHp(selectedInitItem.maxHp + '')
            setAc(selectedInitItem.ac + '')
            // setSecondRoll(selectedCreature.secondRoll)
            setStaticInit(selectedInitItem.initiative + '')
            setTeam(selectedInitItem.team)
            // setReroll(false)
            setCr(selectedInitItem.cr + "")
        }
    }, [modalVisible])

    const handleSave = () => {
        if (!selectedInitItem) return;

        let newMax = parseInt(hp);
        let currHp = selectedInitItem!.hp
        const oldMax = selectedInitItem!.maxHp
        const hadTemp = currHp > oldMax
        const tempAmount = currHp - oldMax
        const diffMax = newMax - oldMax
        
        if (diffMax > 0) {
            currHp += diffMax
            if (!hadTemp && currHp > newMax) {
                currHp = newMax
            }
        } else {
            if (!hadTemp && currHp > newMax) {
                currHp = newMax
            }
            if (hadTemp) {
                currHp = Number(newMax) + tempAmount
            }
        }

        const newCreature: IInitItem = {
            ...selectedInitItem,
            initMod: parseInt(initMod),
            name,
            maxHp: newMax,
            ac: parseInt(ac),
            // secondRoll,
            initiative: parseInt(staticInit),
            team,
            hp: currHp,
            cr: parseCr(cr)
        }

        console.log(selectedInitItem, newCreature);

        dispatch(editInitItem(newCreature))
        dispatch(setEditInitItemActive(false))
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Grid container rowSpacing={"10px"} columnSpacing={"20px"} sx={{boxSizing: "border-box"}}>

                <Grid item xs={12}><Heading>{selectedInitItem?.name}</Heading></Grid>

                <DsInput xs={12} label={"Name"} value={name} setValue={setName} name="name" />

                {/* Row */}
                <DsDropdown xs={8}
                    name="team" 
                    label="Team" 
                    value={team} 
                    setValue={setTeam} 
                    data={teamDataSet} 
                    attribute="team" 
                />
                <DsDropdown xs={4} 
                    name="cr"
                    label="CR" 
                    value={cr} 
                    setValue={setCr} 
                    data={crDataSet} 
                    attribute="cr" 
                />

                {/* Row */}
                <DsInput xs={4} 
                    name="hp"
                    label="HP" 
                    value={hp} 
                    setValue={setHp} 
                    validate={validatePosInt} 
                />
                <DsInput xs={4} 
                    name="ac"
                    label="AC" 
                    value={ac} 
                    setValue={setAc} 
                    validate={validatePosInt} 
                />
                <DsInput xs={4} 
                    name="initMod"
                    label="Init. Mod" 
                    value={initMod} 
                    setValue={setInitMod} 
                    validate={validateInt} 
                />

                {/* Row */}
                <DsInput xs={6} 
                    name="statInit"
                    label="Static Initiative" 
                    value={staticInit} 
                    setValue={setStaticInit} 
                    validate={validatePosInt} 
                />
                <DsInput xs={6} 
                    name="qty"
                    label="Quantity" 
                    value={qty} 
                    setValue={setQty} 
                    validate={validatePosInt}
                />

                {/* Row */}
                <DsButton xs={12} 
                    name="save"
                    icon={<BiSave fontSize={"20px"} />}
                    label="Save" 
                    onClick={handleSave} 
                />
            </Grid>
        </Box>
    )
}

export default EditInitItemModal;