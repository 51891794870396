import { Grid, styled } from "@mui/material";
import { Wrapper } from "../../dataDisplay/AccordionList/AccordionList";
import { StatLabel } from "../../modals/ViewCreatureModal/ViewCreatureModal";
import SimpleSchemaListEditor from "../listEditors/SimpleSchemaListEditor/SimpleSchemaListEditor";
import { ISimpleSchema } from "../../../types/CreatureTypes";
import DsInput, { InputLabel } from "../DsInput/DsInput";
import { useEffect, useState } from "react";

export const DsRadioInput = styled("input")({

});

export interface DsFilterProps {
    label: string;
    name: string;
    list: ISimpleSchema[];
    setList: any;
    textValue: string;
    setTextValue: any;
    dropdownList: ISimpleSchema[] | undefined;
}

const DsFilter = ({
    label,
    name,
    list,
    setList,
    textValue,
    setTextValue,
    dropdownList
}: DsFilterProps) => {
    const [radioValue, setRadioValue] = useState<string>("input");

    useEffect(() => {
        if (list.length > 0) {
            setRadioValue("list");
        }
    }, []);

    const handleChangeRadioValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "list") {
            setTextValue("");
        } else {
            setList((prev: any) => []);
        }
        setRadioValue(e.target.value);
    }

    return (
        <Wrapper>
            <StatLabel>{label}</StatLabel>
            <Grid container>
                <Grid item xs={1} sx={{
                    paddingTop: radioValue === "input" ? "4px" : "0px"
                }}>
                    <DsRadioInput type="radio"
                        name={name}
                        value="input"
                        checked={radioValue === "input"}
                        onChange={handleChangeRadioValue}
                    />
                </Grid>
                <Grid item xs={11}>
                    {radioValue === "input" ? (
                        <DsInput
                            value={textValue}
                            setValue={setTextValue}
                        />
                    ) : (
                        <InputLabel>Filter by text</InputLabel>
                    )}
                </Grid>
                <Grid item xs={1} sx={{
                    paddingTop: radioValue === "list" ? "13px" : "0px"
                }}>
                    <DsRadioInput type="radio"
                        name={name}
                        value="list"
                        checked={radioValue === "list"}
                        onChange={handleChangeRadioValue}
                    />
                </Grid>
                <Grid item xs={11}>
                    {radioValue === "list" ? (
                        <SimpleSchemaListEditor
                            itemName={name}
                            list={list}
                            setList={setList}
                            dropdownList={dropdownList}
                            permitNewItems={false}
                        />
                    ) : (
                        <InputLabel>Select from a list</InputLabel>
                    )}
                </Grid>
            </Grid>
        </Wrapper>
    );
};

export default DsFilter;