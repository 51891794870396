import DsButton, { DsButtonProps } from "../DsButton/DsButton";
import myTheme from "../../../myTheme";
import { TbGrave2 } from "react-icons/tb";


const KillButton = ({ label = "Kill", ...props }: DsButtonProps) => {

    return (
        <DsButton
            icon={<TbGrave2 />}
            label={label}
            buttonProps={{
                style: {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    width: "100%",
                    padding: "8px 15px",
                    fontSize: "32px",
                    backgroundColor: myTheme.darkest,
                    color: myTheme.medGray,
                    borderRadius: "5px",
                    fontWeight: "bold",
                    boxSizing: "border-box",
                    border: "none"
                }
            }}
            {...props}
        />
    );
}

export default KillButton;