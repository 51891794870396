import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../types/UserTypes";
import { RootState } from "../store";

export interface DungeonsuiteState {
    path: string
}

const initialState: DungeonsuiteState = {
    path: "/"
}

export const dungeonsuiteSlice = createSlice({
    name: "dungeonsuite",
    initialState,
    reducers: {
        setPath: (state, action: PayloadAction<string>) => {
            state.path = action.payload;
        }
    }
});

export const {
    setPath
} = dungeonsuiteSlice.actions;

export const selectPath = (state: RootState) => state.dungeonsuite.path;

export default dungeonsuiteSlice.reducer;