import { IoMdTrash } from "react-icons/io";
import DsButton, { DsButtonProps } from "../DsButton/DsButton";
import myTheme from "../../../myTheme";
import { MdEdit } from "react-icons/md";


const EditButtonMd = ({ label = "Edit", ...props }: DsButtonProps) => {

    return (
        <DsButton
            label={label}
            buttonProps={{
                style: {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    width: "100%",
                    backgroundColor: myTheme.primary,
                    color: myTheme.light,
                    borderRadius: "5px",
                    boxSizing: "border-box",
                    border: "none"
                }
            }}
            icon={<MdEdit />}
            {...props}
        />
    );
}

export default EditButtonMd;