import { useCallback, useEffect, useState } from "react";
import { get, set } from "idb-keyval";

export function usePersistedState<TState>(keyToPersistWith: string, defaultState: TState) {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [state, setState] = useState<TState>(defaultState);

    useEffect(() => {
        get<TState>(keyToPersistWith).then(retrievedState => {
            // If a value is retrieved then use it; otherwise default to defaultValue
            setLoaded(!!retrievedState);
            setState(retrievedState ?? defaultState)
        });
    }, [keyToPersistWith, setState, defaultState]);

    const setPersistedValue = useCallback((newValue: TState) => {
        setState(newValue);
        set(keyToPersistWith, newValue);
    }, [keyToPersistWith, setState]);

    return [state, setPersistedValue, loaded] as const;
}