
const myTheme = {
    bg: '#45454A',
    friendly: '#3aeb34',
    enemy: 'red',
    primary: '#29ABE2',
    player: '#29ABE2',
    healthGreen: '#3aeb34',
    healthYellow: 'orange',
    healthRed: 'red',
    light: 'white',
    darkest: 'black',
    medDark: '#666666',
    offWhite: '#CCCCCC',
    medGray: '#E6E6E6',
    creatureRed: "#ff5a6a",
    spellPurple: "#9a8dbf",
    itemYellow: "#fcd876",
    settingBrown: "#c68d66",
    green: "#72CC7A"
}

export default myTheme;