import { Box, Typography, styled } from "@mui/material";
import D20Dis from "../../icons/D20Dis/D20Dis";
import D20Icon from "../../icons/D20Icon/D20Icon";
import D20Adv from "../../icons/D20Adv/D20Adv";
import myTheme from "../../../myTheme";
import { InputLabel } from "../../input/DsInput/DsInput";


const OuterContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
})

const Container = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly"
})

export interface AdvantagePickerProps {
    secondRoll: number;
    setSecondRoll: any;
}

const AdvantagePicker = ({ secondRoll, setSecondRoll }: AdvantagePickerProps) => {

    const getString = () => {
        switch (secondRoll) {
            case -1:
                return "Disadvantage"
            case 0:
                return "Normal Roll"
            case 1:
                return "Advantage"
            default:
                return ""
        }
    }

    return (
        <OuterContainer>
            <InputLabel sx={{textAlign: "center"}}>{getString()}</InputLabel>
            <Container>
                <D20Dis
                    size={secondRoll === -1 ? 80 : 60}
                    strokeWidth={15}
                    fill={secondRoll === -1 ? myTheme.healthRed : myTheme.medDark}
                    stroke={secondRoll === -1 ? myTheme.darkest : myTheme.bg}
                    onClick={() => setSecondRoll(-1)}
                />
                <D20Icon
                    size={secondRoll === 0 ? 80 : 60}
                    strokeWidth={15}
                    fill={secondRoll === 0 ? myTheme.primary : myTheme.medDark}
                    stroke={secondRoll === 0 ? myTheme.darkest : myTheme.bg}
                    onClick={() => setSecondRoll(0)}
                />
                <D20Adv
                    size={secondRoll === 1 ? 80 : 60}
                    strokeWidth={15}
                    fill1={secondRoll === 1 ? myTheme.healthGreen : myTheme.medDark}
                    fill2={secondRoll === 1 ? myTheme.offWhite : myTheme.medDark}
                    stroke={secondRoll === 1 ? myTheme.darkest : myTheme.bg}
                    onClick={() => setSecondRoll(1)}
                />
            </Container>
        </OuterContainer>
    )
}

export default AdvantagePicker;