import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import myTheme from '../../../myTheme';
import { Wrapper } from '../AccordionList/AccordionList';
import { StatLabel } from '../../modals/ViewCreatureModal/ViewCreatureModal';

export const Tag = styled(Typography)({
    padding: "5px 10px",
    color: myTheme.light,
    fontSize: "14px",
    textAlign: "center",
    backgroundColor: myTheme.medDark,
    borderRadius: "5px",
    whiteSpace: "nowrap"
});

const ListContainer = styled(Box)({
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    overflowX: "scroll"
});

export interface TagScrollViewProps<T> {
    label: string;
    list: T[];
    RenderItem: ({ item }: { item: T }) => JSX.Element;
}

const TagScrollView = <T,>({ label, list, RenderItem }: TagScrollViewProps<T>) => {
    return (
        <Wrapper>
            <StatLabel>{label}</StatLabel>
            <ListContainer>
                {list.map((item, i) => (
                    <RenderItem item={item} key={i} />
                ))}
            </ListContainer>
        </Wrapper>
    );
};

export default TagScrollView;