
const baseUrl = 'https://dungeonsuite-backend.herokuapp.com/'

const makeRequest = async (endpoint, authToken, body, method = 'GET') => {

    let url = baseUrl + 'api/' + endpoint

    if (endpoint === 'signup' || endpoint === 'signin') {
        url = baseUrl + endpoint
    }

    const res = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authToken ? 'Bearer ' + authToken : ''
        },
        body: JSON.stringify(body)
    })
    return res
}

const APIInterface = {
    //Authentication
    signIn: async (loginInfo) => {
        const data = await makeRequest('signin', null, loginInfo, 'POST')
        return data
    },
    signup: async (loginInfo) => {
        const data = await makeRequest('signup', null, loginInfo, 'POST')
        return data
    },
    //Users
    getUser: async (authToken) => {
        const res = await makeRequest('user', authToken)
        if (res.ok) {
            const d = await res.json()
            return d.data
        }
        return null
    },
    //Creatures
    getAllCreatures: async (authToken) => {
        const data = await makeRequest('creature', authToken)
        return data
    },
    postCreature: async (creature, authToken) => {
        const data = await makeRequest('creature', authToken, creature, 'POST')
        return data
    },
    postMagicItem: async (magicItem, authToken) => {
        const data = await makeRequest('magicItem', authToken, magicItem, 'POST')
        return data
    },
    postSpell: async (spell, authToken) => {
        const data = await makeRequest('spell', authToken, spell, 'POST')
        return data
    },
    deleteCreature: async (creature, authToken) => {
        const data = await makeRequest("creature/" + creature._id, authToken, undefined, "DELETE");
        return data;
    },
    deleteMagicItem: async (magicItem, authToken) => {
        const data = await makeRequest("magicItem/" + magicItem._id, authToken, undefined, "DELETE");
        return data;
    },
    deleteSpell: async (spell, authToken) => {
        const data = await makeRequest("spell/" + spell._id, authToken, undefined, "DELETE");
        return data;
    },
    //Creature Stat block items
    getAllCreatureStatBlockItems: async (authToken) => {
        const data = await makeRequest('creature/statBlockItems', authToken)
        return data
    },
    //General
    postItem: async (endpoint, body, authToken) => {
        const data = await makeRequest(endpoint + '/', authToken, body, 'POST')
        return data
    },
    updateItem: async (endpoint, body, authToken) => {
        const res = await makeRequest(endpoint + '/' + body._id, authToken, body, 'PUT')
        
        if (res.ok) {
            const d = await res.json()
            return d.data
        }
        return null
    },
    deleteItem: async (endpoint, body, authToken) => {
        const data = await makeRequest(endpoint + '/' + body._id, authToken, undefined, "DELETE");
        return data;
    },
}


export default APIInterface