import { Box, Grid, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { selectCSBCreatureTypes } from "../../../redux/slices/statblockItemSlice";
import { resetCreatureFilters, selectCreatureFilters, setCreatureFilters } from "../../../redux/slices/creatureSlice";
import DsDropdown from "../../input/DsDropdown/DsDropdown";
import { CreatureSearchFilters } from "../../../types/SearchTypes";
import DsDropdownRange from "../../input/DsDropdownRange/DsDropdownRange";
import { getCRDataSet, parseCr, parseNumericCR } from "../../../constants/Cr.constants";
import DsFilter from "../../input/DsFilter/DsFilter";
import DsButton from "../../input/DsButton/DsButton";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

const ContentGrid = styled(Grid)({});

const FilterCreatureModal = () => {
    const sbCreatureTypes = useAppSelector(selectCSBCreatureTypes);

    const filters = useAppSelector(selectCreatureFilters);
    // const sortMagicItemsBy = useAppSelector(selectSortItemsBy);
    // const rarityFilterList = useAppSelector(selectItemRarityFilterList);
    // const rarityFilterText = useAppSelector(selectItemRarityFilterText);
    // const typeFilterList = useAppSelector(selectItemTypeFilterList);
    // const typeFilterText = useAppSelector(selectItemTypeFilterText);
    // const filterByAttunement = useAppSelector(selectItemFilterbyAttunement);

    const dispatch = useAppDispatch();

    const handleSetLowCR = (e: any) => {
        const cr = parseCr(e);
        const newFilters = { ...filters, crLow: cr };
        if (cr > filters.crHigh) {
            newFilters.crHigh = cr;
        }
        dispatch(setCreatureFilters(newFilters));
    }

    const handleSetHighCR = (e: any) => {
        const cr = parseCr(e);
        const newFilters = { ...filters, crHigh: cr };
        if (cr < filters.crLow) {
            newFilters.crLow = cr;
        }
        dispatch(setCreatureFilters(newFilters));
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <ContentGrid container rowSpacing={"10px"} columnSpacing={"20px"} sx={{ boxSizing: "border-box" }}>

                <DsDropdown xs={12}
                    name="sortBy"
                    label="Sort By"
                    value={filters.sortBy}
                    setValue={(e: any) => dispatch(setCreatureFilters({ ...filters, sortBy: e }))}
                    data={[{ value: CreatureSearchFilters.NAME }, { value: CreatureSearchFilters.CR }]}
                    attribute="value"
                />

                <Grid item xs={12}>
                    <DsDropdownRange
                        label="CR Range"
                        lowValue={parseNumericCR(filters.crLow)}
                        highValue={parseNumericCR(filters.crHigh)}
                        setLowValue={handleSetLowCR}
                        setHighValue={handleSetHighCR}
                        data={getCRDataSet()}
                        attribute="cr"
                    />
                </Grid>

                <Grid item xs={12}>
                    <DsFilter
                        label="Type"
                        name="type"
                        list={filters.typeList}
                        setList={(e: any) => {
                            dispatch(setCreatureFilters({ ...filters, typeList: e(filters.typeList) }));
                        }}
                        textValue={filters.typeString}
                        setTextValue={(e: any) => dispatch(setCreatureFilters({ ...filters, typeString: e }))}
                        dropdownList={sbCreatureTypes}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DsButton
                        label="Reset Filters"
                        icon={<ReplayOutlinedIcon sx={{ fontSize: "16px" }} />}
                        onClick={() => dispatch(resetCreatureFilters())}
                    />
                </Grid>
            </ContentGrid>
        </Box>
    )
}

export default FilterCreatureModal;
