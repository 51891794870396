// import { editCondition, editCreatureType, editDamageType, editLanguage, editMovement, editSense, editSize, editSkill, editSource } from "../../redux/creatureStatBlockItemSlice"
import { editCreature } from "../redux/slices/creatureSlice";
import { editMagicItem } from "../redux/slices/magicItemSlice";
import { editSpell } from "../redux/slices/spellSlice";
import store from "../redux/store"
import APIInterface from "./APIInterface"

export const updateCreature = async (body) => {
    const res = await APIInterface.updateItem('creature', body, store.getState().user.authToken);
    console.log('update creature', res);
    if (res) {
        store.dispatch(editCreature(res));
    }
    return res;
}

export const updateMagicItem = async (body) => {
    const res = await APIInterface.updateItem('magicItem', body, store.getState().user.authToken);
    console.log('update magic item', res);
    if (res) {
        store.dispatch(editMagicItem(res));
    }
    return res;
}

export const updateSpell = async (body) => {
    const res = await APIInterface.updateItem('spell', body, store.getState().user.authToken);
    console.log('update spell', res);
    if (res) {
        store.dispatch(editSpell(res));
    }
    return res;
}

export const updateItem = async (endpoint, body, editFunction, authToken) => {
    const res = await APIInterface.updateItem(endpoint, body, authToken);
    if (res) {
        store.dispatch(editFunction(res));
    }
    return res;
}

// export const updateCondition = async (dispatch, body, authToken) => {
//     const res = await APIInterface.updateItem('condition', body, authToken)
//     console.log('update condition', res)
//     dispatch(editCondition(res))
//     return res
// }

// export const updateCreatureType = async (dispatch, body, authToken) => {
//     const res = await APIInterface.updateItem('creatureType', body, authToken)
//     console.log('update creature type', res)
//     dispatch(editCreatureType(res))
//     return res
// }

// export const updateDamageType = async (dispatch, body, authToken) => {
//     const res = await APIInterface.updateItem('damageType', body, authToken)
//     console.log('updateData', res)
//     dispatch(editDamageType(res))
//     return res
// }

// export const updateLanguage = async (dispatch, body, authToken) => {
//     const res = await APIInterface.updateItem('language', body, authToken)
//     console.log('update langauge', res)
//     dispatch(editLanguage(res))
//     return res
// }

// export const updateMovement = async (dispatch, body, authToken) => {
//     const res = await APIInterface.updateItem('movement', body, authToken)
//     console.log('update movement', res)
//     dispatch(editMovement(res))
//     return res
// }

// export const updateSense = async (dispatch, body, authToken) => {
//     const res = await APIInterface.updateItem('sense', body, authToken)
//     console.log('update sense', res)
//     dispatch(editSense(res))
//     return res
// }

// export const updateSize = async (dispatch, body, authToken) => {
//     const res = await APIInterface.updateItem('size', body, authToken)
//     console.log('update size', res)
//     dispatch(editSize(res))
//     return res
// }

// export const updateSkill = async (dispatch, body, authToken) => {
//     const res = await APIInterface.updateItem('skill', body, authToken)
//     console.log('update skill', res)
//     dispatch(editSkill(res))
//     return res
// }

// export const updateSource = async (dispatch, body, authToken) => {
//     const res = await APIInterface.updateItem('source', body, authToken)
//     console.log('update source', res)
//     dispatch(editSource(res))
//     return res
// }