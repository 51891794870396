import { Box, Grid, Typography, styled } from "@mui/material";
import { ISimpleSchema } from "../../../../types/CreatureTypes";
import { Wrapper } from "../../../dataDisplay/AccordionList/AccordionList";
import { StatLabel } from "../../../modals/ViewCreatureModal/ViewCreatureModal";
import SimpleSchemaEditor from "../../SimpleSchemaEditor/SimpleSchemaEditor";
import { useEffect, useState } from "react";
import PrimaryButton from "../../buttons/PrimaryButton";
import XButton from "../../buttons/XButton";
import EditButton from "../../buttons/EditButton";
import myTheme from "../../../../myTheme";
import DsDropdown from "../../DsDropdown/DsDropdown";
import { validateInt } from "../../../../helper/validation/inputValidation";
import DsInput from "../../DsInput/DsInput";

const ItemNameText = styled(Typography)({
    width: "100%",
    backgroundColor: myTheme.medDark,
    color: myTheme.light,
    borderRadius: "5px",
    height: "30px",
    fontSize: "14px",
    padding: "5px 5px",
    margin: "auto 0",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
    overflow: "hidden",
    overflowX: "clip",
    textOverflow: "ellipsis"
});

export interface SimpleSchemaListEditorProps {
    label: string;
    itemName: string;
    list: any[];
    setList: React.Dispatch<React.SetStateAction<any[]>>;
    dropdownList?: ISimpleSchema[],
    valueName: string,
    valueAttrName: string,
    itemAttrName: string,
    defaultValue?: number;
    maxListLength?: number;
    permitNewItems?: boolean;
}

const SimpleSchemaValueListEditor = ({
    label,
    itemName,
    list,
    setList,
    dropdownList,
    valueName,
    valueAttrName,
    itemAttrName,
    defaultValue = 0,
    maxListLength,
    permitNewItems = true
}: SimpleSchemaListEditorProps) => {
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<ISimpleSchema>();
    const [dropdownItem, setDropdownItem] = useState<ISimpleSchema>();
    const [shouldAutoFocus, setShouldAutoFocus] = useState<boolean>(false);

    useEffect(() => {
        if (dropdownItem) {
            let dup = false;
            list.forEach(li => {
                if (li[itemAttrName]._id === dropdownItem._id) {
                    dup = true;
                }
            });
            if (!dup) {
                console.log("adding item", dropdownItem);
                handleSubmit("add", dropdownItem);
            }
        }
    }, [dropdownItem]);

    const updateItem = (item: any) => {
        setList(prev => {
            const newList = [...prev];
            const i = newList.findIndex(li => li[itemAttrName]._id === item[itemAttrName]._id);
            newList[i] = item;
            return newList;
        });
    };

    const setValue = (i: number, value: string) => {
        console.log(i, value);

        let newValue = parseInt(value);
        if (isNaN(newValue)) {
            newValue = 0;
        }

        setList(prev => {
            const newList = [...prev];
            newList[i][valueAttrName] = newValue;
            return newList;
        });
    }

    const deleteItem = (item: any) => {
        setList(prev => prev.filter(li => li[itemAttrName]._id !== item[itemAttrName]._id));
    };

    const handleSubmit = (action: string, item: ISimpleSchema) => {
        setShouldAutoFocus(true);
        const newItem: any = {};
        newItem[itemAttrName] = item;
        newItem[valueAttrName] = defaultValue;

        console.log(newItem);

        if (action === "add") {
            setList((prev: any[]) => [...prev, newItem]);
            setShowEditor(false);
        } else if (action === "update") {
            updateItem(newItem);
            setSelectedItem(undefined);
        }
    };

    const handleAddNewItem = () => {
        setShowEditor(true);
        setSelectedItem(undefined);
    };

    const handleCancel = () => {
        setShowEditor(false);
        setSelectedItem(undefined);
    };

    return (
        <Wrapper>
            <StatLabel>{label}</StatLabel>

            {(!maxListLength || list.length < maxListLength) 
                && dropdownList && dropdownList.length > 0 && (
                <Wrapper>
                    <DsDropdown xs={12}
                        name={itemName}
                        value={dropdownItem}
                        setValue={setDropdownItem}
                        data={dropdownList}
                        attribute="name"
                        placeholder={"Select " + itemName}
                        selectWholeObject={true}
                    />
                </Wrapper>
            )}


            <Grid container rowSpacing={"5px"} columnSpacing={"20px"} sx={{
                boxSizing: "border-box",
                marginBottom: "5px"
            }}>
                {list.map((item: any, i: number) => (
                    <Grid item container key={i} direction={"row"} columnSpacing={"20px"}>
                        {selectedItem && selectedItem?._id === item._id ? (
                            <Grid item xs={12}>
                                <SimpleSchemaEditor
                                    item={item}
                                    onSubmit={handleSubmit}
                                    onCancel={handleCancel}
                                />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={5.5} sx={{
                                    display: "flex",
                                    boxSizing: "border-box",
                                    alignItems: "flex-end",
                                    justifyContent: "center"
                                }}>
                                    <ItemNameText sx={{ margin: "0" }}>
                                        {item[itemAttrName].name}
                                    </ItemNameText>
                                </Grid >

                                <DsInput xs={3}
                                    name={valueAttrName}
                                    label={valueName}
                                    value={item[valueAttrName] + ""}
                                    setValue={(newVal: any) => {
                                        console.log(newVal);
                                        setValue(i, newVal);
                                    }}
                                    validate={validateInt}
                                    autoFocus={shouldAutoFocus}
                                    selectAllOnFocus
                                />

                                <Grid item xs={3.5} sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                    gap: "5px"
                                }}>
                                    {item[itemAttrName].new && (
                                        <EditButton
                                            onClick={() => setSelectedItem(item)}
                                        />
                                    )}
                                    <XButton
                                        onClick={() => deleteItem(item)}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                ))
                }
            </Grid >

            {showEditor && !selectedItem ? (
                <SimpleSchemaEditor
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                />
            ) : (
                <>
                    {(!maxListLength || list.length < maxListLength)
                        && permitNewItems && (
                            <PrimaryButton
                                label={`Add New ${itemName}`}
                                onClick={handleAddNewItem}
                            />
                        )}
                </>
            )}
        </Wrapper >
    );
}

export default SimpleSchemaValueListEditor;