import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ICreature, ISimpleSchema } from "../../types/CreatureTypes"
import { CreatureSearchFilters, SearchOrder } from "../../types/SearchTypes"
import { sortByName } from "../../helper/searching/sorts"
import { RootState } from "../store"

interface CreatureFilters {
    sortBy: string;
    crLow: number;
    crHigh: number;
    typeList: ISimpleSchema[];
    typeString: string;
}

interface CreaturesState {
    creatures: ICreature[]
    filteredCreatures: ICreature[]
    creatureSearchTerm?: string
    selectedCreature?: ICreature
    creatureSearchBy: CreatureSearchFilters
    creatureSortBy: CreatureSearchFilters
    creatureOrder: SearchOrder
    crLow: number
    crHigh: number
    copyCreature: boolean;
    creatureFilters: CreatureFilters;
    creatureSearch: string;
}

const initialState: CreaturesState = {
    creatures: [],
    filteredCreatures: [],
    creatureSearchBy: CreatureSearchFilters.NAME,
    creatureSortBy: CreatureSearchFilters.NAME,
    creatureOrder: SearchOrder.ASC,
    crLow: 0,
    crHigh: 30,
    copyCreature: false,
    creatureFilters: {
        sortBy: CreatureSearchFilters.NAME,
        crLow: 0,
        crHigh: 30,
        typeList: [],
        typeString: ""
    },
    creatureSearch: ""
}

export const creatureSlice = createSlice({
    name: "creatures",
    initialState,
    reducers: {
        setCreatures: (state, action: PayloadAction<ICreature[]>) => {
            state.creatures = sortByName(action.payload);
        },
        addCreature: (state, action: PayloadAction<ICreature>) => {
            const newList = sortByName([...state.creatures, action.payload])
            state.creatures = newList;
        },
        editCreature: (state, action: PayloadAction<ICreature>) => {
            const newItem = action.payload;
            let i = 0;
            state.creatures.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.creatures];
            newArr[i] = newItem;
            state.creatures = newArr;
        },
        removeCreature: (state, action: PayloadAction<ICreature>) => {
            const newList = state.creatures.filter(li => li._id !== action.payload._id);
            state.creatures = newList;
        },
        setSelectedCreature: (state, action: PayloadAction<ICreature | undefined>) => {
            state.selectedCreature = action.payload
        },
        setFilteredCreatures: (state, action: PayloadAction<ICreature[]>) => {
            state.filteredCreatures = action.payload
        },
        setCreatureSearchTerm: (state, action: PayloadAction<string>) => {
            state.creatureSearchTerm = action.payload
        },
        setCreatureSortBy: (state, action: PayloadAction<CreatureSearchFilters>) => {
            state.creatureSortBy = action.payload
        },
        setCreatureSearchBy: (state, action: PayloadAction<CreatureSearchFilters>) => {
            state.creatureSearchBy = action.payload
        },
        setCreatureOrder: (state, action: PayloadAction<SearchOrder>) => {
            state.creatureOrder = action.payload
        },
        setCRLow: (state, action: PayloadAction<number>) => {
            state.crLow = action.payload
        },
        setCRHigh: (state, action: PayloadAction<number>) => {
            state.crHigh = action.payload
        },
        setCopyCreature: (state, action: PayloadAction<boolean>) => {
            state.copyCreature = action.payload;
        },
        setCreatureFilters: (state, action: PayloadAction<CreatureFilters>) => {
            state.creatureFilters = action.payload;
        },
        setCreatureSearch: (state, action: PayloadAction<string>) => {
            state.creatureSearch = action.payload;
        },
        resetCreatureFilters: (state) => {
            state.creatureFilters = { ...initialState.creatureFilters };
        }
    }
});

export const { 
    setCreatures, 
    addCreature, 
    editCreature,
    removeCreature,
    setSelectedCreature,
    setFilteredCreatures,
    setCreatureSearchTerm,
    setCreatureSearchBy,
    setCreatureSortBy,
    setCreatureOrder,
    setCRLow,
    setCRHigh,
    setCopyCreature,
    setCreatureFilters,
    setCreatureSearch,
    resetCreatureFilters
} = creatureSlice.actions;

export const selectCreatures = (state: RootState) => state.creatures.creatures
export const selectSelectedCreature = (state: RootState) => state.creatures.selectedCreature
export const selectFilteredCreatures = (state: RootState) => state.creatures.filteredCreatures
export const selectCreatureSearchTerm = (state: RootState) => state.creatures.creatureSearchTerm
export const selectCreatureSearchBy = (state: RootState) => state.creatures.creatureSearchBy
export const selectCreatureSortBy = (state: RootState) => state.creatures.creatureSortBy
export const selectCreatureOrder = (state: RootState) => state.creatures.creatureOrder
export const selectCRLow = (state: RootState) => state.creatures.crLow
export const selectCRHigh = (state: RootState) => state.creatures.crHigh
export const selectCopyCreature = (state: RootState) => state.creatures.copyCreature;
export const selectCreatureFilters = (state: RootState) => state.creatures.creatureFilters;
export const selectCreatureSearch = (state: RootState) => state.creatures.creatureSearch;

export default creatureSlice.reducer