import { removeCreature } from "../redux/slices/creatureSlice";
import { removeMagicItem } from "../redux/slices/magicItemSlice";
import { removeSpell } from "../redux/slices/spellSlice";
import store from "../redux/store";
import APIInterface from "./APIInterface"

//Creatures
export const deleteCreature = async (creature, authToken) => {

    const res = await APIInterface.deleteCreature(creature, authToken);

    if (res.ok) {
        store.dispatch(removeCreature(creature));
        return (await res.json()).data;
    }
    return null;
}

export const deleteMagicItem = async (magicItem, authToken) => {

    const res = await APIInterface.deleteMagicItem(magicItem, authToken);

    if (res.ok) {
        store.dispatch(removeMagicItem(magicItem));
        return (await res.json()).data;
    }
    return null;
}

export const deleteSpell = async (spell, authToken) => {

    const res = await APIInterface.deleteSpell(spell, authToken);

    if (res.ok) {
        store.dispatch(removeSpell(spell));
        return (await res.json()).data;
    }
    return null;
}

export const deleteItem = async (endpoint, body, authToken) => {
    const res = await APIInterface.deleteItem(endpoint, body, authToken);
    return res;
}