import { Box, Grid, GridProps, Input, styled, SxProps, Theme, Typography } from "@mui/material";
import myTheme from "../../../myTheme";
import { MdClose } from "react-icons/md";
import { useEffect, useRef } from "react";

export const InputWrapper = styled(Grid)({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    boxSizing: "border-box",
    position: "relative"
})

export const InputLabel = styled(Typography)({
    color: myTheme.medGray,
    fontSize: "14px"
})

export const StyledInput = styled("input")({
    width: "100%",
    background: myTheme.medDark,
    color: myTheme.medGray,
    borderRadius: "5px",
    height: "30px",
    fontSize: "14px",
    paddingLeft: "5px",
    paddingRight: "5px",
    border: "none",
    boxSizing: "border-box",
    ":focus": {
        outline: "none",
        textDecoration: "none",
        border: "2px solid " + myTheme.primary
    }
});

const ClearButtonWrapper = styled(Box)({
    position: "absolute",
    right: "10px",
    top: "4px",
    fontSize: "20px"
});

export interface DsInputProps extends GridProps {
    label?: string;
    value: string;
    setValue: Function;
    validate?: (item: string) => boolean
    name?: string;
    inputStyles?: SxProps<Theme> | undefined;
    placeholder?: string;
    useClearButton?: boolean;
    type?: string;
    selectAllOnFocus?: boolean;
    autoFocus?: boolean;
}

const DsInput = ({
    label,
    value,
    setValue,
    validate,
    name = "default",
    inputStyles,
    placeholder,
    useClearButton,
    type = "text",
    selectAllOnFocus,
    autoFocus,
    ...props
}: DsInputProps) => {

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (autoFocus) {
            ref.current?.focus();
        }
    }, []);

    const handleChange = (e: React.ChangeEvent<{ value: string }>) => {
        if (!validate) {
            setValue(e.target.value);
            return;
        }

        if (validate(e.target.value)) {
            setValue(e.target.value);
        }
    }

    const handleClear = () => {
        setValue("");
        ref.current?.focus();
    };

    const handleFocus = () => {
        if (selectAllOnFocus) {
            ref.current?.select();
        }
    }

    return (
        <InputWrapper data-testid={`dsInput__${name}__container`} item {...props}>
            {label && <InputLabel data-testid={`dsInput__${name}__label`}>{label}</InputLabel>}
            <StyledInput
                ref={ref}
                data-testid={`dsInput__${name}__input`}
                value={value}
                onChange={handleChange}
                sx={inputStyles}
                placeholder={placeholder}
                type={type}
                onFocus={handleFocus}
            />
            {useClearButton && value && (
                <ClearButtonWrapper>
                    <MdClose onClick={handleClear} />
                </ClearButtonWrapper>
            )}
        </InputWrapper>
    )
}

export default DsInput;