import { GridProps, MenuItem, Select, SelectChangeEvent, styled, InputLabel as ILabel, Box } from "@mui/material";
import { useState } from "react";
import myTheme from "../../../myTheme";
import { InputLabel, InputWrapper } from "../DsInput/DsInput";

const StyledSelect = styled(Select)({
    width: "100%",
    background: myTheme.medDark,
    color: myTheme.medGray,
    borderRadius: "5px",
    height: "30px",
    fontSize: "14px",
    paddingLeft: "5px",
    paddingRight: "5px",
    border: "none",
    ":focus": {
        backgroundColor: "purple",
        outline: "none",
        textDecoration: "none",
    },
    "& .MuiSelect-select": {
        padding: 0
    },
    "& svg": {
        color: myTheme.offWhite
    }
})

const StyledOption = styled(MenuItem)({
    background: myTheme.medDark,
    color: myTheme.medGray,
    height: "30px",
    fontSize: "14px",
    paddingLeft: "5px",
    paddingRight: "5px",
    "& :checked": {
        backgroundColor: "red"
    }
});

const Placeholder = styled("span")({
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 10,
    pointerEvents: "none",
    color: myTheme.medGray,
    fontSize: "14px",
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "5px"
});

export interface DsDropdownProps extends GridProps {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    label: string;
    data: [{ [key: string]: any }];
    attribute: string;
    name?: string;
    placeholder?: string;
    selectWholeObject?: boolean;
}

const DsDropdown = ({ selectWholeObject, placeholder, value, setValue, label, data, attribute, name = "default", ...props }: any) => {

    const handleChange = (e: SelectChangeEvent<unknown>) => {
        setValue(e.target.value);
    }

    return (
        <InputWrapper data-testid={`dsDropdown__${name}__container`} item {...props}>
            <InputLabel data-testid={`dsDropdown__${name}__label`}>{label}</InputLabel>
            <Box sx={{position: "relative"}}>
                {!value && <Placeholder>{placeholder}</Placeholder>}
                <StyledSelect
                    data-testid={`dsDropdown__${name}__select`}
                    value={value ? value : "test"}
                    onChange={handleChange}
                    MenuProps={
                        {
                            sx: {
                                "& .MuiPaper-root": {
                                    backgroundColor: "transparent"
                                },
                                "& ul": {
                                    backgroundColor: myTheme.bg,
                                    borderRadius: "5px",
                                    border: "1px solid black",
                                    // borderTop: "none"
                                },
                                "& li": {
                                    backgroundColor: myTheme.bg + " !important",
                                    ":hover": {
                                        backgroundColor: myTheme.medDark
                                    }
                                },
                                "& .Mui-selected": {
                                    backgroundColor: myTheme.medDark + " !important"
                                }
                            }
                        }
                    }>
                    {data && data.map((item: any, i: number) => {
                        const option = item[attribute];
                        if (!option) return null;
                        return (
                            <StyledOption key={i} data-testid={`dsDropdown__${name}__option--${i}`} value={selectWholeObject ? item : option}>{option}</StyledOption>
                        )
                    })}
                </StyledSelect>
            </Box>
        </InputWrapper>
    )
}

export default DsDropdown;