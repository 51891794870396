import { Box, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import InitLi from "../../components/inititivePageComponents/InitLi/InitLi";
import { usePersistedState } from "../../hooks/usePersistedState/usePersistedState";
import myTheme from "../../myTheme";
import { IInitItem } from "../../types/InitiativeTypes";
import TuneIcon from '@mui/icons-material/Tune';
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { selectInitOrder, selectSelectedInitItem, setInitOrder } from "../../redux/slices/initSlice";
import AddInitItemModal from "../../components/modals/AddInitItemModal/AddInitItemModal";
import GenericModal from "../../components/modals/GenericModal/GenericModal";
import { selectAddInitItemActive, selectClearInitActive, selectDeathSavesActive, selectDeleteInitItemActive, selectEditInitItemActive, selectInitHpActive, selectRerollinitActive, selectViewCreatureActive, selectViewinitCreatureActive, selectXpActive, setAddInitItemActive, setClearInitActive, setDeathSavesActive, setDeleteInitItemActive, setEditInitItemActive, setInitHpActive, setRerollInitActive, setViewCreatureActive, setViewInitCreatureActive, setXpActive } from "../../redux/slices/modalSlice";
import EditInitItemModal from "../../components/modals/EditInitItemModal/EditInitItemModal";
import InitHpModal from "../../components/modals/InitHpModal/InitHpModal";
import DeathSavesModal from "../../components/modals/DeathSavesModal/DeathSavesModal";
import DeleteInitItemModal from "../../components/modals/DeleteInitItemModal/DeleteInitItemModal";
import Status from "../../constants/CreatureStatus";
import RerollInitModal from "../../components/modals/RerollInitModal/RerollinitModal";
import RerollInitFab from "../../components/input/fabs/RerollInitFab/RerollInitFab";
import { TbGrave2 } from "react-icons/tb";
import ClearInitFab from "../../components/input/fabs/ClearInitFab/ClearInitFab";
import ClearInitModal from "../../components/modals/ClearInitModal/ClearInitModal";
import XpModal from "../../components/modals/XpModal/XpModal";
import calculateXp from "../../helper/initHelper/totalXp";
import { ContentContainer, HeaderContainer, OuterContainer } from "../Pages.styled";
import ViewCreatureModal from "../../components/modals/ViewCreatureModal/ViewCreatureModal";
import { setCopyCreature, setSelectedCreature } from "../../redux/slices/creatureSlice";
import AddFab from "../../components/input/fabs/AddFab/AddFab";



const TotalXPText = styled(Typography)(({ theme }) => ({
    fontSize: "26px",
    fontWeight: "bold",
    color: myTheme.light,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
        fontSize: "18px"
    }
}))

const CustTuneIcon = styled(TuneIcon)(({ theme }) => ({
    position: "absolute",
    right: "10px",
    fontSize: "26px",
    color: myTheme.light,
    [theme.breakpoints.down("sm")]: {
        fontSize: "18px"
    }
}))

const InitiativePage = () => {

    const [loadedInitOrder, setLoadedInitOrder] = useState<boolean>(false);
    const [pInitOrder, setPInitOrder, pInitOrderLoaded] = usePersistedState<IInitItem[]>("initOrder", []);
    const [aliveInit, setAliveInit] = useState<IInitItem[]>([]);
    const [deadInit, setDeadInit] = useState<IInitItem[]>([]);
    const [showTotalXp, setShowTotalXp] = useState<boolean>(true);
    const [totalXp, setTotalXp] = useState<number>(0);
    const [nPlayers, setNPlayers] = useState<number>(0);

    const dispatch = useAppDispatch();

    const initOrder = useAppSelector(selectInitOrder);
    const selectedInitItem = useAppSelector(selectSelectedInitItem);

    useEffect(() => {
        if (!loadedInitOrder && pInitOrder && pInitOrderLoaded) {
            setLoadedInitOrder(true);
            dispatch(setInitOrder(pInitOrder!));
        }
    }, [pInitOrder])

    useEffect(() => {
        if (loadedInitOrder) {
            setPInitOrder(initOrder);
        }
        setAliveInit(initOrder.filter(item => item.status === Status.ALIVE));
        setDeadInit(initOrder.filter(item => item.status === Status.DEAD));
        const [t, n] = calculateXp(initOrder);
        setTotalXp(t);
        setNPlayers(n);
    }, [initOrder])

    const handleClickXpCalc = () => {
        dispatch(setXpActive(true));
    }

    return (
        <OuterContainer data-testid="initiativePage__container">
            <GenericModal
                title="XP Summary"
                visibilitySelector={selectXpActive}
                visibilityToggle={setXpActive}
                Component={<XpModal />}
            />
            <GenericModal
                title="Add to Initiative"
                visibilitySelector={selectAddInitItemActive}
                visibilityToggle={setAddInitItemActive}
                Component={<AddInitItemModal />}
            />
            <GenericModal
                title="Reroll Initiative"
                visibilitySelector={selectRerollinitActive}
                visibilityToggle={setRerollInitActive}
                Component={<RerollInitModal />}
            />
            <GenericModal
                title="Clear Initiative"
                visibilitySelector={selectClearInitActive}
                visibilityToggle={setClearInitActive}
                Component={<ClearInitModal />}
            />
            <GenericModal
                title="Edit Template"
                visibilitySelector={selectEditInitItemActive}
                visibilityToggle={setEditInitItemActive}
                Component={<EditInitItemModal />}
            />
            <GenericModal
                title="Hit Points"
                visibilitySelector={selectInitHpActive}
                visibilityToggle={setInitHpActive}
                Component={<InitHpModal />}
            />
            <GenericModal
                title="Death Saves"
                visibilitySelector={selectDeathSavesActive}
                visibilityToggle={setDeathSavesActive}
                Component={<DeathSavesModal />}
            />
            <GenericModal
                title="Delete Creature"
                visibilitySelector={selectDeleteInitItemActive}
                visibilityToggle={setDeleteInitItemActive}
                Component={<DeleteInitItemModal />}
            />
            <GenericModal
                visibilitySelector={selectViewinitCreatureActive}
                visibilityToggle={setViewInitCreatureActive}
                Component={<ViewCreatureModal disableEditing creature={selectedInitItem?.statblock} />}
                onClose={() => {
                    setSelectedCreature(undefined);
                    setCopyCreature(false);
                }}
            />
            <HeaderContainer data-testid="initiativePage__headerContainer"
                sx={{
                    minHeight: "50px"
                }}
            >
                <TotalXPText
                    onClick={() => setShowTotalXp(prev => !prev)}
                >
                    {showTotalXp ?
                        `Total XP: ${Math.floor(totalXp)}`
                        : 
                        `${Math.floor(totalXp / nPlayers)} / player`
                            .replace("Infinity", "---")
                            .replace("NaN", "---")
                    }
                </TotalXPText>
                <CustTuneIcon onClick={handleClickXpCalc} />
            </HeaderContainer>
            <ContentContainer>
                {aliveInit.map((initItem, i) => {
                    if (initItem.status === Status.ALIVE) {
                        return (
                            <InitLi key={i} item={initItem} index={i} />
                        )
                    }
                })}
                <Box
                    sx={{
                        width: "100vw",
                        height: "100px"
                    }}
                />
                {deadInit.length > 0 && (
                    <>
                        <Box
                            sx={{
                                textAlign: "center",
                                borderBottom: "3px solid black"
                            }}
                        >
                            <TbGrave2 color={myTheme.medDark} fontSize={"180px"} />
                        </Box>
                        {initOrder.map((initItem, i) => {
                            if (initItem.status === Status.DEAD) {
                                return (
                                    <InitLi key={i} item={initItem} index={i} />
                                )
                            }
                        })}
                        <Box
                            sx={{
                                width: "100vw",
                                height: "100px"
                            }}
                        />
                    </>
                )}

            </ContentContainer>
            <AddFab
                visibilityToggle={setAddInitItemActive}
            />
            <RerollInitFab />
            <ClearInitFab />
        </OuterContainer>
    )
}

export default InitiativePage;