import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { styled } from "@mui/system";
import { ReactElement } from "react";
import MobileNavBar from "../../input/MobileNavBar/MobileNavBar";

const OuterContainer = styled("div")({
    width: "100%",
    minHeight: "100%",
    boxSizing: "border-box"
});


export interface PageContainerProps {
    Page: JSX.Element
}

const PageContainer = ({ Page }: PageContainerProps) => {

    return (
        <OuterContainer>
            {Page}
        </OuterContainer>
    )
}

export default PageContainer;