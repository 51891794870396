import { Box, styled, Theme, Typography } from '@mui/material';
import React from 'react';
import myTheme from '../../../myTheme';
import ProgressBar, { ProgressBarProps } from "@ramonak/react-progress-bar";
import { display, MUIStyledCommonProps } from '@mui/system';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';

const getHPColor = (hp: number, maxHp: number) => {
    if (maxHp === 0) return myTheme.healthRed
    let prog = hp / maxHp
    if (prog > 0.5) return myTheme.healthGreen
    if (prog <= 0.5 && prog > 0.2) return myTheme.healthYellow
    else return myTheme.healthRed
}

const getBarBorderColor = (hp: number, maxHp: number, tempHp: number) => {
    if (tempHp > 0) return myTheme.primary
    return getHPColor(hp, maxHp);
}

const getBarBorderWidth = (tempHp: number) => {
    let borderWidth = 2;
    if (tempHp > 0) {
        borderWidth = Math.floor(tempHp / 3) + 1;
    }
    if (borderWidth > 10) {
        borderWidth = 10;
    }
    return borderWidth;
};

const HPWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    boxSizing: "content-box"
}))

type t1 = {
    hp: number
    maxHp: number
    tempHp: number
}
type t2 = ProgressBarProps & MUIStyledCommonProps<Theme> & { theme: Theme; } & t1

const StyledProgressBar = styled(ProgressBar)(({ theme, hp, maxHp, tempHp }: t2) => {

    const borderColor = getBarBorderColor(hp, maxHp, tempHp);
    const borderWidth = getBarBorderWidth(tempHp);
    const outerHeight = tempHp > 0 ? 20 + (2 * borderWidth) : 20;

    return ({
        ">div": {
            border: `${borderWidth}px solid ${borderColor}`,
            boxSizing: "border-box",
            height: `${outerHeight}px !important`,
            ">div": {
                height: `${outerHeight - (2 * borderWidth)}px !important`
            }
        }
    })
})

const HeartContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    display: "flex",
    width: "100%",
    height: "100%",
    border: "0px solid green",
    top: 0,
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "border-box"
}))

const HpText = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    color: myTheme.light,
    [theme.breakpoints.down("sm")]: {
        fontSize: "12px"
    }
}))

export interface HealthBarProps {
    hp?: number
    maxHp?: number
    tempHp?: number
    onClick?: any
}

const HealthBar = ({ hp = 1, maxHp = 1, tempHp = 0, onClick }: HealthBarProps) => {

    return (
        <HPWrapper>
            <HpText data-testid="healthBar__hpText">
                <span>{hp}/{maxHp}</span>
                {tempHp > 0 && <span style={{ color: myTheme.primary }}>&nbsp;({tempHp})</span>}
            </HpText>
            <Box sx={{ position: "relative" }} onClick={onClick}>
                <StyledProgressBar
                    data-testid="healthBar__progressBar"
                    hp={hp}
                    maxHp={maxHp}
                    tempHp={tempHp}
                    completed={hp}
                    maxCompleted={maxHp}
                    baseBgColor={myTheme.bg}
                    bgColor={getHPColor(hp, maxHp)}
                    isLabelVisible={false}
                    className="hpBar"
                    transitionDuration='0.3s'
                />
                {hp / maxHp > 0.5 &&
                    <HeartContainer>
                        <FavoriteIcon
                            data-testid="healthBar__heart--full"
                            sx={{
                                fontSize: "20px",
                                color: myTheme.light
                            }}
                        />
                    </HeartContainer>
                }
                {hp / maxHp <= 0.5 && hp / maxHp > 0 &&
                    <HeartContainer>
                        <HeartBrokenIcon
                            data-testid="healthBar__heart--half"
                            sx={{
                                fontSize: "20px",
                                color: myTheme.light
                            }}
                        />
                    </HeartContainer>
                }
                {hp / maxHp <= 0 &&
                    <HeartContainer>
                        <HeartBrokenOutlinedIcon
                            data-testid="healthBar__heart--empty"
                            sx={{
                                fontSize: "20px",
                                color: myTheme.light
                            }}
                        />
                    </HeartContainer>
                }
            </Box>
        </HPWrapper>
    )
}


export default HealthBar