import { Box, Grid, Modal, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { selectCSBCreatureTypes } from "../../../redux/slices/statblockItemSlice";
import { resetCreatureFilters, selectCreatureFilters, setCreatureFilters } from "../../../redux/slices/creatureSlice";
import DsDropdown from "../../input/DsDropdown/DsDropdown";
import { CreatureSearchFilters } from "../../../types/SearchTypes";
import DsDropdownRange from "../../input/DsDropdownRange/DsDropdownRange";
import { getCRDataSet, parseCr, parseNumericCR } from "../../../constants/Cr.constants";
import DsFilter from "../../input/DsFilter/DsFilter";
import DsButton from "../../input/DsButton/DsButton";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { LabelText, ModalContentContainer, ModalInnerContainer, ModalOuterContainer, ModalTitle } from "../GenericModal/GenericModal";
import { Wrapper } from "../../dataDisplay/AccordionList/AccordionList";

const ContentGrid = styled(Grid)({});

export interface ConfirmModalProps {
    onConfirm: (e: any) => void;
    onCancel: () => void;
    open: boolean;
}

const ConfirmModal = ({ onConfirm, onCancel, open }: ConfirmModalProps) => {
    return (
        <Modal
            open={open}
            onClose={onCancel}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none"
            }}
            data-testid={`modal__confirm__modal`}
        >
            <ModalOuterContainer>
                <ModalContentContainer>
                    <ModalTitle sx={{ padding: "10px 20px" }}>Are you sure you want to delete this item?</ModalTitle>
                    <ModalInnerContainer>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            <LabelText>This operation can't be undone</LabelText>
                            <Wrapper sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px"
                            }}>
                                <DsButton label="Yes, delete it" onClick={onConfirm} />
                                <DsButton label="No, save it" onClick={onCancel} />
                            </Wrapper>
                        </Box>
                    </ModalInnerContainer>
                </ModalContentContainer>
            </ModalOuterContainer>
        </Modal>
    )
}

export default ConfirmModal;
