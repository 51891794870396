import { Box, Typography, styled } from "@mui/material";
import { ICreature } from "../../../types/CreatureTypes";
import ListItem from "../../dataDisplay/ListItem/ListItem";
import { NameText } from "../../inititivePageComponents/InitLi/InitLi.styled";
import myTheme from "../../../myTheme";
import { parseNumericCR } from "../../../constants/Cr.constants";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { setSelectedCreature } from "../../../redux/slices/creatureSlice";
import { CSSProperties } from "react";
import { setViewCreatureActive, setViewSpellActive } from "../../../redux/slices/modalSlice";
import { ISpell } from "../../../types/SpellTypes";
import { setSelectedSpell } from "../../../redux/slices/spellSlice";
import { getSpellTypeLine1, getSpellTypeLine2 } from "../../../helper/spellHelper";
// import { getSpellTypeLine } from "../../../helper/spellHelper";

const CreatureLiWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
});

const CreatureTextContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 5,
    whiteSpace: "nowrap",
    width: "30%"
});

const CreatureCRContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    width: "30%",
    whiteSpace: "nowrap"
});

const SubText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.light,
    paddingLeft: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    overflowX: "clip",
    textOverflow: "ellipsis",
});

export interface SpellLiProps {
    spell: ISpell,
    style: CSSProperties
}

const SpellLi = ({ spell, style }: SpellLiProps) => {

    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(setSelectedSpell(spell));
        dispatch(setViewSpellActive(true));
    }

    return (
        <ListItem onClick={handleClick} style={style}>
            <CreatureLiWrapper>
                <CreatureTextContainer>
                    <NameText>{spell.name}</NameText>
                    <SubText>{getSpellTypeLine1(spell)}</SubText>
                    <SubText>{getSpellTypeLine2(spell)}</SubText>
                </CreatureTextContainer>
                <CreatureCRContainer>
                    <NameText sx={{textAlign: "right"}}>{spell.level === 0 ? "Cantrip" : "Lv: " + spell.level}</NameText>
                </CreatureCRContainer>
            </CreatureLiWrapper>
        </ListItem>
    );
};

export default SpellLi;