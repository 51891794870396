import { Grid, Typography, styled } from "@mui/material";
import { ISimpleSchema } from "../../../../types/CreatureTypes";
import { Wrapper } from "../../../dataDisplay/AccordionList/AccordionList";
import { StatLabel } from "../../../modals/ViewCreatureModal/ViewCreatureModal";
import SimpleSchemaEditor from "../../SimpleSchemaEditor/SimpleSchemaEditor";
import { useEffect, useState } from "react";
import PrimaryButton from "../../buttons/PrimaryButton";
import XButton from "../../buttons/XButton";
import EditButton from "../../buttons/EditButton";
import myTheme from "../../../../myTheme";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { updateItem } from "../../../../api/updateData";
import { selectAuthToken } from "../../../../redux/slices/userSlice";
import { createNewItemsNoValue } from "../../../../helper/createNewItems";
import { deleteItem } from "../../../../api/deleteData";
import { MdIosShare } from "react-icons/md";
import SmallButton from "../../buttons/SmallButton";
import ShareModal from "../../../modals/ShareModal/ShareModal";

const ItemNameText = styled(Typography)({
    width: "100%",
    backgroundColor: myTheme.medDark,
    color: myTheme.light,
    borderRadius: "5px",
    height: "30px",
    fontSize: "14px",
    padding: "5px 5px",
    margin: "auto 0",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
    overflow: "hidden",
    overflowX: "clip",
    textOverflow: "ellipsis",
});

export interface SimpleSchemaItemEditorProps {
    label?: string;
    itemName: string;
    endpoint: string;
    list: ISimpleSchema[];
    editItem: ActionCreatorWithPayload<ISimpleSchema, string>;
    addItem: ActionCreatorWithPayload<ISimpleSchema, string>;
    setList: ActionCreatorWithPayload<ISimpleSchema[], string>;
    sharable?: boolean;
    shareModalMessage?: string;
}

const SimpleSchemaItemEditor = ({
    label,
    itemName,
    endpoint,
    list,
    addItem,
    editItem,
    setList,
    sharable,
    shareModalMessage
}: SimpleSchemaItemEditorProps) => {
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<ISimpleSchema>();
    const [sharingItem, setSharingItem] = useState<ISimpleSchema>();
    const [dropdownItem, setDropdownItem] = useState<ISimpleSchema | "">();
    const [shareOpen, setShareOpen] = useState<boolean>(false);

    const authToken = useAppSelector(selectAuthToken);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (dropdownItem && !list.includes(dropdownItem)) {
            console.log("adding item", dropdownItem);
            handleSubmit("add", dropdownItem);
        }
        setDropdownItem("");
    }, [dropdownItem]);

    const handleDelete = async (item: ISimpleSchema) => {
        await deleteItem(endpoint, item, authToken);
        dispatch(setList(list.filter(li => li._id !== item._id)));
    };

    const handleSubmit = async (action: string, item: ISimpleSchema) => {
        if (action === "add") {
            await createNewItemsNoValue([item], endpoint, addItem);
            setShowEditor(false);
        } else if (action === "update") {
            const newItem = await updateItem(endpoint, item, editItem, authToken);
            setSelectedItem(undefined);
        }
    };

    const handleAddNewItem = () => {
        setShowEditor(true);
        setSelectedItem(undefined);
    };

    const handleCancel = () => {
        setShowEditor(false);
        setSelectedItem(undefined);
    };

    return (
        <Wrapper>
            {label && (
                <StatLabel>{label}</StatLabel>
            )}

            <Grid container rowSpacing={"5px"} columnSpacing={"20px"} sx={{
                boxSizing: "border-box",
                marginBottom: "5px"
            }}>
                {list.map((item: ISimpleSchema) => (
                    <>
                        {selectedItem && selectedItem?._id === item._id ? (
                            <Grid item xs={12}>
                                <SimpleSchemaEditor
                                    item={item}
                                    onSubmit={handleSubmit}
                                    onCancel={handleCancel}
                                    mode="Save"
                                />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={sharable ? 7.5 : 8.5} sx={{
                                    display: "flex",
                                    boxSizing: "border-box",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <ItemNameText>{item.name}</ItemNameText>
                                </Grid >
                                <Grid item xs={sharable ? 4.5 : 3.5} sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    gap: "5px"
                                }}>
                                    {sharable && (
                                        <SmallButton
                                            icon={<MdIosShare size={20} />}
                                            buttonProps={{
                                                style: { paddingLeft: "2px" }
                                            }}
                                            onClick={() => {
                                                console.log(item);
                                                setSharingItem(item);
                                                setShareOpen(true);
                                            }}
                                        />
                                    )}
                                    <EditButton
                                        onClick={() => setSelectedItem(item)}
                                    />
                                    <XButton
                                        onClick={() => handleDelete(item)}
                                        confirmDelete
                                    />
                                </Grid>
                            </>
                        )}
                    </>
                ))
                }
            </Grid >

            {showEditor && !selectedItem ? (
                <SimpleSchemaEditor
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                />
            ) : (
                <>
                    <PrimaryButton
                        label={`Add New ${itemName}`}
                        onClick={handleAddNewItem}
                    />
                </>
            )}

            {sharingItem && shareOpen && (
                <ShareModal
                    open={shareOpen}
                    setOpen={setShareOpen}
                    onSubmit={async (list) => {
                        await updateItem(endpoint,
                            {
                                ...sharingItem,
                                sharedWith: list
                            }, editItem, authToken);
                        setSharingItem(undefined);
                        setShareOpen(false);
                    }}
                    sharedWith={sharingItem.sharedWith ? sharingItem.sharedWith : []}
                    message={shareModalMessage}
                />
            )}
        </Wrapper >
    );
}

export default SimpleSchemaItemEditor;