import React, { useEffect, useState } from 'react'
import InitiativePage from './pages/InitiativePage/InitiativePage';
import { styled } from "@mui/material/styles";
import myTheme from './myTheme';
import PageContainer from './components/layout/PageContainer/PageContainer';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MobileNavBar from './components/input/MobileNavBar/MobileNavBar';
import CreaturePage from './pages/CreaturePage/CreaturePage';
import SpellPage from './pages/SpellPage/SpellPage';
import ItemPage from './pages/ItemPage/ItemPage';
import SettingPage from './pages/SettingPage/SettingPage';
import { getUser } from './api/authApi';
import { useAppDispatch } from './hooks/reduxHooks';
import { setAuthToken, setUser } from './redux/slices/userSlice';
import { loadFromDb } from './helper/dungeonsuiteHelper';
import LandingPage from './pages/LandingPage/LandingPage';
import { usePersistedState } from './hooks/usePersistedState/usePersistedState';
import PasswordResetPage from './pages/PasswordResetPage/PasswordResetPage';
import EmailVerificationPage from './pages/EmailVerificationPage/EmaillVerificationPage';
import WebHome from './pages/web/WebHome';



const AppContainer = styled("div")({
    // theme.theme
    backgroundColor: myTheme.bg,
    width: "100vw",
    height: "100vh",
    boxSizing: "border-box",
    overflow: "hidden"
});

const router = createBrowserRouter([
    {
        path: "/",
        element: <WebHome />,
    },
    {
        path: "/ds/",
        element: <LandingPage />,
    },
    {
        path: "/ds/passwordReset",
        element: <PasswordResetPage />
    },
    {
        path: "/ds/emailVerification",
        element: <EmailVerificationPage />
    },
    {
        path: "/ds/initTracker",
        element: <PageContainer Page={<InitiativePage />} />
    }, {
        path: "/ds/creatures",
        element: <PageContainer Page={<CreaturePage />} />
    }, {
        path: "/ds/spells",
        element: <PageContainer Page={<SpellPage />} />
    }, {
        path: "/ds/items",
        element: <PageContainer Page={<ItemPage />} />
    }, {
        path: "/ds/settings",
        element: <PageContainer Page={<SettingPage />} />
    }
])

function App() {
    const [dsToken, setDsToken] = usePersistedState<string>("dsToken", "");

    const dispatch = useAppDispatch();

    useEffect(() => {
        const checkToken = async () => {
            if (window.location.pathname !== "/" && dsToken) {
                const user = await getUser(dsToken);
                if (!user) return false;
                setDsToken(dsToken);
                dispatch(setAuthToken(dsToken));      
                dispatch(setUser(user));
                loadFromDb(dsToken);
            }
        };

        checkToken();
    }, [dsToken]);

    return (
        <AppContainer>
            <RouterProvider router={router} />
            <MobileNavBar navigate={router.navigate} />
        </AppContainer>
    );
}

export default App

