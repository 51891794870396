import { Box, Grid, Modal, Typography, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { selectCSBCreatureTypes } from "../../../redux/slices/statblockItemSlice";
import { resetCreatureFilters, selectCreatureFilters, setCreatureFilters } from "../../../redux/slices/creatureSlice";
import DsDropdown from "../../input/DsDropdown/DsDropdown";
import { CreatureSearchFilters } from "../../../types/SearchTypes";
import DsDropdownRange from "../../input/DsDropdownRange/DsDropdownRange";
import { getCRDataSet, parseCr, parseNumericCR } from "../../../constants/Cr.constants";
import DsFilter from "../../input/DsFilter/DsFilter";
import DsButton from "../../input/DsButton/DsButton";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { LabelText, ModalContentContainer, ModalInnerContainer, ModalOuterContainer, ModalTitle } from "../GenericModal/GenericModal";
import { Wrapper } from "../../dataDisplay/AccordionList/AccordionList";
import { IFriend } from "../../../types/FriendTypes";
import { useState } from "react";
import SimpleSchemaListEditor from "../../input/listEditors/SimpleSchemaListEditor/SimpleSchemaListEditor";
import { ISimpleSchema } from "../../../types/CreatureTypes";
import { selectFriends } from "../../../redux/slices/friendSlice";
import myTheme from "../../../myTheme";

const ContentGrid = styled(Grid)({});

export interface ShareModalProps {
    onSubmit: (e: any) => void;
    sharedWith: IFriend[];
    open: boolean;
    setOpen: (e: boolean) => void;
    message?: string;
}

const ShareModal = ({ onSubmit, sharedWith, open, setOpen, message }: ShareModalProps) => {
    const [list, setList] = useState<ISimpleSchema[]>(sharedWith.map(f => {
        const newF: ISimpleSchema = {
            name: f.username,
            _id: f._id
        }
        return newF;
    }));

    const friends = useAppSelector(selectFriends);

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none"
            }}
            data-testid={`modal__share__modal`}
        >
            <ModalOuterContainer>
                <ModalContentContainer>
                    <ModalTitle sx={{ padding: "10px 20px" }}>Sharing</ModalTitle>
                    {message && <LabelText sx={{ padding: "0px 24px 10px 24px" }}>{message}</LabelText>}
                    <ModalInnerContainer>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            <SimpleSchemaListEditor
                                label="Friends"
                                itemName="Friend"
                                list={list}
                                setList={setList}
                                dropdownList={friends.map(f => {
                                    const newF: ISimpleSchema = {
                                        name: f.username,
                                        _id: f._id
                                    }
                                    return newF;
                                })}
                                permitNewItems={false}
                            />
                            <Wrapper sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px"
                            }}>
                                <DsButton label="Submit" onClick={() => onSubmit(list)} />
                            </Wrapper>
                        </Box>
                    </ModalInnerContainer>
                </ModalContentContainer>
            </ModalOuterContainer>
        </Modal>
    )
}

export default ShareModal;
