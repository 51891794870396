import { Box, Grid, Typography, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { selectSelectedCreature, setCopyCreature, setSelectedCreature } from "../../../redux/slices/creatureSlice";
import myTheme from "../../../myTheme";
import AcIcon from "../../icons/AcIcon/AcIcon";
import { getXpByCr, parseNumericCR } from "../../../constants/Cr.constants";
import { FaRegHeart } from "react-icons/fa";
import { getAbilityMod } from "../../../helper/creatureHelper/creatureHelper";
import AccordionList, { Wrapper } from "../../dataDisplay/AccordionList/AccordionList";
import TagScrollView, { Tag } from "../../dataDisplay/TagScrollView/TagScrollView";
import { IDamageType, ILanguage, ISave, ISenseStat, ISkill } from "../../../types/CreatureTypes";
import { ICondition } from "../../../types/StatblockTypes";
import { useEffect, useState } from "react";
import DeleteButton from "../../input/buttons/DeleteButton";
import { selectAuthToken, selectUser } from "../../../redux/slices/userSlice";
import { deleteCreature, deleteMagicItem } from "../../../api/deleteData";
import { setAddCreatureActive, setAddMagicItemActive, setViewCreatureActive, setViewMagicItemActive } from "../../../redux/slices/modalSlice";
import PrimaryButton from "../../input/buttons/PrimaryButton";
import { MdEdit } from "react-icons/md";
import EditButtonLg from "../../input/buttons/EditButtonMd";
import DeleteButtonMd from "../../input/buttons/DeleteButtonMd";
import EditButtonMd from "../../input/buttons/EditButtonMd";
import CopyButtonMd from "../../input/buttons/CopyButtonMd";
import { selectSelectedMagicItem, setCopyMagicItem, setSelectedMagicItem } from "../../../redux/slices/magicItemSlice";
import { getMagicItemTypeLine } from "../../../helper/magicItemHelper";
import DsButton from "../../input/DsButton/DsButton";
import ShareModal from "../ShareModal/ShareModal";
import { updateMagicItem } from "../../../api/updateData";
import { loadAllFriends } from "../../../helper/dungeonsuiteHelper";

export const ContentGrid = styled(Grid)({
    boxSizing: "border-box",
    flexDirection: "column",
    alignItems: "center"
});

export const MagicItemDescContainer = styled(Grid)({
    marginTop: "10px",
    width: "100%",
    border: "2px solid " + myTheme.primary,
    borderRadius: "5px",
    color: myTheme.light,
    textAlign: "left",
    padding: "5px 10px",
    boxSizing: "border-box",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word"
});

const ItalicLabel = styled(Typography)({
    color: myTheme.light,
    fontSize: "14px",
    fontStyle: "italic",
    textAlign: "center"
});

const Heading = styled(Typography)({
    color: myTheme.light,
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold"
});

const SubText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.light
});

interface ViewMagicItemModalProps {
    disableEditing?: boolean
}

const ViewMagicItemModal = ({ disableEditing }: ViewMagicItemModalProps) => {
    const [shareOpen, setShareOpen] = useState<boolean>(false);

    const magicItem = useAppSelector(selectSelectedMagicItem);
    const user = useAppSelector(selectUser);
    const authToken = useAppSelector(selectAuthToken);

    const dispatch = useAppDispatch();

    useEffect(() => {
        console.log(magicItem);
    }, [magicItem]);

    const handleEdit = () => {
        if (magicItem) {
            dispatch(setSelectedMagicItem(magicItem));
            dispatch(setAddMagicItemActive(true));
            dispatch(setViewMagicItemActive(false));
        }
    };

    const handleCopy = () => {
        if (magicItem) {
            dispatch(setSelectedMagicItem(magicItem));
            dispatch(setCopyMagicItem(true));
            dispatch(setAddMagicItemActive(true));
            dispatch(setViewMagicItemActive(false));
        }
    }

    const handleDelete = async () => {
        await deleteMagicItem(magicItem, authToken);
        dispatch(setViewMagicItemActive(false));
    };

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                paddingTop: "15px"
            }}
        >
            <ContentGrid container>
                <Grid item xs={12}>
                    <Heading>{magicItem?.name}</Heading>
                </Grid>
                <Grid item xs={12}>
                    <SubText sx={{ textAlign: "center" }}>{getMagicItemTypeLine(magicItem)}</SubText>
                </Grid>

                <MagicItemDescContainer item container xs={12} direction="row">
                    <Grid item xs={12}>
                        {magicItem?.description}
                    </Grid>
                </MagicItemDescContainer>

                <Wrapper>
                    {/* @ts-ignore */}
                    <ItalicLabel>{magicItem?.source.name + ""}</ItalicLabel>
                </Wrapper>

                <Wrapper sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px"
                }}>
                    <CopyButtonMd onClick={handleCopy} />
                </Wrapper>

                {!disableEditing && (<>
                    {magicItem?.createdBy === user?._id && (
                        <Wrapper sx={{ gap: "5px" }}>
                            <DsButton
                                label="Share"
                                onClick={() => setShareOpen(true)}
                                buttonProps={{ style: { backgroundColor: myTheme.primary, color: myTheme.light } }}
                            />
                            <Wrapper sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px"
                            }}>
                                <EditButtonMd onClick={handleEdit} />
                                <DeleteButtonMd onClick={handleDelete} confirmDelete />
                            </Wrapper>
                        </Wrapper>

                    )}
                </>)}

                <ShareModal
                    open={shareOpen}
                    setOpen={setShareOpen}
                    onSubmit={async (list) => {
                        await updateMagicItem({
                            ...magicItem,
                            sharedWith: list
                        });
                        setShareOpen(false);
                    }}
                    sharedWith={(magicItem && magicItem.sharedWith) ? magicItem.sharedWith : []}
                />
            </ContentGrid>
        </Box>
    );
};

export default ViewMagicItemModal;