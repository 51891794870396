import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ISimpleSchema } from "../../types/CreatureTypes"
import { sortByName } from "../../helper/searching/sorts"
import { RootState } from "../store"
import { ISpell, SpellSortByOptions } from "../../types/SpellTypes";

interface SpellState {
    spells: ISpell[];
    selectedSpell?: ISpell;
    copySpell: boolean;

    sortSpellsBy: string;
    levelRangeLow: number;
    levelRangeHigh: number;
    spellSchoolFilterList: ISimpleSchema[];
    spellSchoolFilterText: string;
    spellClassFilterList: ISimpleSchema[];
    spellClassFilterText: string;
    spellSubclassFilterList: ISimpleSchema[];
    spellSubclassFilterText: string;
    spellFilterByRitual: string;
    spellFilterByConcentration: string;
    spellSearch: string;
}

const initialState: SpellState = {
    spells: [],
    copySpell: false,
    sortSpellsBy: SpellSortByOptions.level,
    levelRangeLow: 0,
    levelRangeHigh: 10,
    spellSchoolFilterList: [],
    spellSchoolFilterText: "",
    spellClassFilterList: [],
    spellClassFilterText: "",
    spellSubclassFilterList: [],
    spellSubclassFilterText: "",
    spellFilterByRitual: "Either",
    spellFilterByConcentration: "Either",
    spellSearch: ""
}

export const spellSlice = createSlice({
    name: "spells",
    initialState,
    reducers: {
        setSpells: (state, action: PayloadAction<ISpell[]>) => {
            state.spells = sortByName(action.payload);
        },
        addSpell: (state, action: PayloadAction<ISpell>) => {
            const newList = sortByName([...state.spells, action.payload])
            state.spells = newList;
        },
        editSpell: (state, action: PayloadAction<ISpell>) => {
            const newItem = action.payload;
            let i = 0;
            state.spells.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.spells];
            newArr[i] = newItem;
            state.spells = newArr;
        },
        removeSpell: (state, action: PayloadAction<ISpell>) => {
            const newList = state.spells.filter(li => li._id !== action.payload._id);
            state.spells = newList;
        },
        setSelectedSpell: (state, action: PayloadAction<ISpell | undefined>) => {
            state.selectedSpell = action.payload
        },
        setCopySpell: (state, action: PayloadAction<boolean>) => {
            state.copySpell = action.payload;
        },
        setSortSpellsBy: (state, action: PayloadAction<string>) => {
            state.sortSpellsBy = action.payload;
        },
        setLevelRangeLow: (state, action: PayloadAction<number>) => {
            state.levelRangeLow = action.payload;
        },
        setLevelRangeHigh: (state, action: PayloadAction<number>) => {
            state.levelRangeHigh = action.payload;
        },
        setSpellSchoolFilterList: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.spellSchoolFilterList = [...action.payload];
        },
        setSpellSchoolFilterText: (state, action: PayloadAction<string>) => {
            state.spellSchoolFilterText = action.payload;
        },
        setSpellClassFilterList: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.spellClassFilterList = [...action.payload];
        },
        setSpellClassFilterText: (state, action: PayloadAction<string>) => {
            state.spellClassFilterText = action.payload;
        },
        setSpellSubclassFilterList: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.spellSubclassFilterList = [...action.payload];
        },
        setSpellSubclassFilterText: (state, action: PayloadAction<string>) => {
            state.spellSubclassFilterText = action.payload;
        },
        setSpellFilterByRitual: (state, action: PayloadAction<string>) => {
            state.spellFilterByRitual = action.payload;
        },
        setSpellFilterByConcentration: (state, action: PayloadAction<string>) => {
            state.spellFilterByConcentration = action.payload;
        },
        setSpellSearch: (state, action: PayloadAction<string>) => {
            state.spellSearch = action.payload;
        },
        resetSpellSortAndFilter: (state) => {
            state.sortSpellsBy = SpellSortByOptions.level;
            state.levelRangeLow = 0;
            state.levelRangeHigh = 10;
            state.spellSchoolFilterList = [];
            state.spellSchoolFilterText = "";
            state.spellClassFilterList = [];
            state.spellClassFilterText = "";
            state.spellSubclassFilterList = [];
            state.spellSubclassFilterText = "";
            state.spellFilterByRitual = "Either";
            state.spellFilterByConcentration = "Either";
        }
    }
});

export const { 
    setSpells, 
    addSpell, 
    editSpell,
    removeSpell,
    setSelectedSpell,
    setCopySpell,
    setSortSpellsBy,
    setLevelRangeLow,
    setLevelRangeHigh,
    setSpellSchoolFilterList,
    setSpellSchoolFilterText,
    setSpellClassFilterList,
    setSpellClassFilterText,
    setSpellSubclassFilterList,
    setSpellSubclassFilterText,
    setSpellFilterByRitual,
    setSpellFilterByConcentration,
    resetSpellSortAndFilter,
    setSpellSearch
} = spellSlice.actions;

export const selectSpell = (state: RootState) => state.spells.spells
export const selectSelectedSpell = (state: RootState) => state.spells.selectedSpell
export const selectCopySpell = (state: RootState) => state.spells.copySpell;
export const selectSortSpellsBy = (state: RootState) => state.spells.sortSpellsBy;
export const selectLevelRangeLow = (state: RootState) => state.spells.levelRangeLow;
export const selectLevelRangeHigh = (state: RootState) => state.spells.levelRangeHigh;
export const selectSpellSchoolFilterList = (state: RootState) => state.spells.spellSchoolFilterList;
export const selectSpellSchoolFilterText = (state: RootState) => state.spells.spellSchoolFilterText;
export const selectSpellClassFilterList = (state: RootState) => state.spells.spellClassFilterList;
export const selectSpellClassFilterText = (state: RootState) => state.spells.spellClassFilterText;
export const selectSpellSubclassFilterList = (state: RootState) => state.spells.spellSubclassFilterList;
export const selectSpellSubclassFilterText = (state: RootState) => state.spells.spellSubclassFilterText;
export const selectSpellFilterByRitual = (state: RootState) => state.spells.spellFilterByRitual;
export const selectSpellFilterByConcentration = (state: RootState) => state.spells.spellFilterByConcentration;
export const selectSpellSearch = (state: RootState) => state.spells.spellSearch;

export default spellSlice.reducer