import { Box, styled } from "@mui/material";
import myTheme from "../../../../myTheme";
import { useAppDispatch } from "../../../../hooks/reduxHooks";
import { setRerollInitActive } from "../../../../redux/slices/modalSlice";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

const FabWrapper = styled(Box)(({ theme }) => ({
    position: "absolute",
    left: "3vw",
    bottom: "calc(10vh + 75px)",
    backgroundColor: myTheme.green,
    width: "60px",
    height: "60px",
    borderRadius: "100%",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
}))

const RerollInitFab = () => {

    const dispatch = useAppDispatch()

    const handleClick = () => {
        dispatch(setRerollInitActive(true));
    }

    return (
        <FabWrapper data-testid="rerollInitItemFab__wrapper" onClick={handleClick}>
            <ReplayOutlinedIcon 
                sx={{
                    fontSize: "50px",
                    color: myTheme.light
                }}
            />
        </FabWrapper>
    )
}

export default RerollInitFab;