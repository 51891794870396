import { Box, Grid, Typography, styled } from "@mui/material";
import { ISimpleSchema } from "../../../../types/CreatureTypes";
import { Wrapper } from "../../../dataDisplay/AccordionList/AccordionList";
import { StatLabel } from "../../../modals/ViewCreatureModal/ViewCreatureModal";
import SimpleSchemaEditor from "../../SimpleSchemaEditor/SimpleSchemaEditor";
import { useEffect, useState } from "react";
import PrimaryButton from "../../buttons/PrimaryButton";
import DeleteButton from "../../buttons/DeleteButton";
import XButton from "../../buttons/XButton";
import EditButton from "../../buttons/EditButton";
import myTheme from "../../../../myTheme";
import DsDropdown from "../../DsDropdown/DsDropdown";

const ItemNameText = styled(Typography)({
    width: "100%",
    backgroundColor: myTheme.medDark,
    color: myTheme.light,
    borderRadius: "5px",
    height: "30px",
    fontSize: "14px",
    padding: "5px 5px",
    margin: "auto 0",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
    overflow: "hidden",
    overflowX: "clip",
    textOverflow: "ellipsis",
});

export interface SimpleSchemaListEditorProps {
    label?: string;
    itemName: string;
    list: ISimpleSchema[];
    setList: React.Dispatch<React.SetStateAction<ISimpleSchema[]>>;
    dropdownList?: ISimpleSchema[];
    maxListLength?: number;
    permitNewItems?: boolean;
    alwaysEdit?: boolean;
}

const SimpleSchemaListEditor = ({
    label,
    itemName,
    list,
    setList,
    dropdownList,
    maxListLength,
    permitNewItems = true,
    alwaysEdit
}: SimpleSchemaListEditorProps) => {
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<ISimpleSchema>();
    const [dropdownItem, setDropdownItem] = useState<ISimpleSchema | "">();

    useEffect(() => {
        if (dropdownItem && !list.includes(dropdownItem)) {
            handleSubmit("add", dropdownItem);
        }
        setDropdownItem("");
    }, [dropdownItem]);

    const updateItem = (item: ISimpleSchema) => {
        setList(prev => {
            const newList = [...prev];
            const i = newList.findIndex(li => li._id === item._id);
            newList[i] = item;
            return newList;
        });
    };

    const deleteItem = (item: ISimpleSchema) => {
        setList(prev => prev.filter(li => li._id !== item._id));
    };

    const handleSubmit = (action: string, item: ISimpleSchema) => {
        if (action === "add") {
            setList((prev: ISimpleSchema[]) => [...prev, item]);
            setShowEditor(false);
        } else if (action === "update") {
            updateItem(item);
            setSelectedItem(undefined);
        }
    };

    const handleAddNewItem = () => {
        setShowEditor(true);
        setSelectedItem(undefined);
    };

    const handleCancel = () => {
        setShowEditor(false);
        setSelectedItem(undefined);
    };

    return (
        <Wrapper>
            {label && (
                <StatLabel>{label}</StatLabel>
            )}


            {(!maxListLength || list.length < maxListLength)
                && dropdownList && dropdownList.length > 0 && (
                    <Wrapper>
                        <DsDropdown xs={12}
                            name={itemName}
                            value={dropdownItem}
                            setValue={setDropdownItem}
                            data={dropdownList}
                            attribute="name"
                            placeholder={"Select " + itemName}
                            selectWholeObject={true}
                        />
                    </Wrapper>
                )}


            <Grid container rowSpacing={"5px"} columnSpacing={"20px"} sx={{
                boxSizing: "border-box",
                marginBottom: "5px"
            }}>
                {list.map((item: ISimpleSchema, i: number) => (
                    <Grid item container key={i} direction={"row"} columnSpacing={"20px"}>
                        {selectedItem && selectedItem?._id === item._id ? (
                            <Grid item xs={12}>
                                <SimpleSchemaEditor
                                    item={item}
                                    onSubmit={handleSubmit}
                                    onCancel={handleCancel}
                                />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={8.5} sx={{
                                    display: "flex",
                                    boxSizing: "border-box",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <ItemNameText>{item.name}</ItemNameText>
                                </Grid >
                                <Grid item xs={3.5} sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    gap: "5px"
                                }}>
                                    {(item.new || alwaysEdit) && (
                                        <EditButton
                                            onClick={() => setSelectedItem(item)}
                                        />
                                    )}
                                    <XButton
                                        onClick={() => deleteItem(item)}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                ))
                }
            </Grid >

            {showEditor && !selectedItem ? (
                <SimpleSchemaEditor
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                />
            ) : (
                <>
                    {(!maxListLength || list.length < maxListLength)
                        && permitNewItems && (
                            <PrimaryButton
                                label={`Add New ${itemName}`}
                                onClick={handleAddNewItem}
                            />
                        )}
                </>
            )}
        </Wrapper >
    );
}

export default SimpleSchemaListEditor;