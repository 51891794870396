import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../types/UserTypes";
import { RootState } from "../store";
import { IFriend } from "../../types/FriendTypes";

export interface FriendState {
    friends: IFriend[];
    sent: IFriend[];
    received: IFriend[];
}

const initialState: FriendState = {
    friends: [],
    sent: [],
    received: []
}

export const friendSlice = createSlice({
    name: "friend",
    initialState,
    reducers: {
        setFriends: (state, action: PayloadAction<IFriend[]>) => {
            state.friends = action.payload;
        },
        setSent: (state, action: PayloadAction<IFriend[]>) => {
            state.sent = action.payload;
        },
        setReceived: (state, action: PayloadAction<IFriend[]>) => {
            state.received = action.payload;
        }
    }
});

export const {
    setFriends,
    setSent,
    setReceived
} = friendSlice.actions;

export const selectFriends = (state: RootState) => state.friends.friends;
export const selectSent = (state: RootState) => state.friends.sent;
export const selectReceived = (state: RootState) => state.friends.received;

export default friendSlice.reducer;