import { Box, Grid, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { selectCSBItemTypes, selectCSBRarities } from "../../../redux/slices/statblockItemSlice";
import { resetItemSortAndFilter, selectItemFilterbyAttunement, selectItemRarityFilterList, selectItemRarityFilterText, selectItemTypeFilterList, selectItemTypeFilterText, selectMagicItem, selectSortItemsBy, setItemFilterByAttunement, setItemRarityFilterList, setItemRarityFilterText, setItemTypeFilterList, setItemTypeFilterText, setSelectedMagicItem, setSortItemsBy } from "../../../redux/slices/magicItemSlice";
import DsDropdown from "../../input/DsDropdown/DsDropdown";
import { MagicItemSortByOptions } from "../../../types/MagicItemTypes";
import DsFilter from "../../input/DsFilter/DsFilter";
import DsButton from "../../input/DsButton/DsButton";
import { GiRollingDices } from "react-icons/gi";
import { useState } from "react";
import { ISimpleSchema } from "../../../types/CreatureTypes";
import { compareSimpleSchemaLists, simpleSchemaListIncludes, simpleSchemaListIncludesName } from "../../../helper/searching/searches";
import { setRandomMagicItemActive, setViewMagicItemActive } from "../../../redux/slices/modalSlice";

const ContentGrid = styled(Grid)({});

const RandomMagicItemModal = () => {
    const [attunement, setAttumenet] = useState<string>("Either");
    const [rarities, setRarities] = useState<ISimpleSchema[]>([]);
    const [rarityText, setRarityText] = useState<string>("");
    const [itemType, setItemType] = useState<ISimpleSchema[]>([]);
    const [itemTypeText, setItemTypeText] = useState<string>("");

    const sbRarities = useAppSelector(selectCSBRarities);
    const sbItemtypes = useAppSelector(selectCSBItemTypes);

    const magicItems = useAppSelector(selectMagicItem);

    const dispatch = useAppDispatch();

    const handleRollTheDice = () => {
        const filtered = magicItems.filter(li => (
            (rarityText && li.rarity?.name.toUpperCase().includes(rarityText.toUpperCase())
                || !rarityText)
            && (rarities.length > 0 && simpleSchemaListIncludes(rarities, li.rarity)
                || rarities.length === 0)
            && (itemTypeText && simpleSchemaListIncludesName(li.item_type, itemTypeText)
                || !itemTypeText)
            && (itemType.length > 0 && compareSimpleSchemaLists(itemType, li.item_type)
                || itemType.length === 0)
            && (attunement === "No" && !li.requires_attunement
                || attunement === "Yes" && li.requires_attunement
                || attunement === "Either")
        ));

        const n = filtered.length;
        const rand = Math.floor(Math.random() * n);
        dispatch(setSelectedMagicItem(filtered[rand]));
        dispatch(setRandomMagicItemActive(false));
        dispatch(setViewMagicItemActive(true));
    };

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <ContentGrid container rowSpacing={"10px"} columnSpacing={"20px"} sx={{ boxSizing: "border-box" }}>

                <DsDropdown xs={12}
                    name="attunement"
                    label="Attunement"
                    value={attunement}
                    setValue={(e: any) => setAttumenet(e)}
                    data={[{ value: "Either" }, { value: "Yes" }, { value: "No" }]}
                    attribute="value"
                />

                <Grid item xs={12}>
                    <DsFilter
                        label="Rarity"
                        name="rarity"
                        list={rarities}
                        setList={(e: any) => {
                            setRarities(e);
                        }}
                        textValue={rarityText}
                        setTextValue={(e: any) => setRarityText(e)}
                        dropdownList={sbRarities}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DsFilter
                        label="Item Type"
                        name="itemType"
                        list={itemType}
                        setList={(e: any) => {
                            setItemType(e);
                        }}
                        textValue={itemTypeText}
                        setTextValue={(e: any) => setItemTypeText(e)}
                        dropdownList={sbItemtypes}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DsButton
                        label="Roll the Dice"
                        icon={<GiRollingDices size="16px" />}
                        onClick={handleRollTheDice}
                    />
                </Grid>
            </ContentGrid>
        </Box>
    )
}

export default RandomMagicItemModal;
