import { useEffect, useRef, useState } from "react";
import myTheme from "../../myTheme";
import { ContentContainer, OuterContainer } from "../Pages.styled";
import { ISpell, SpellSortByOptions } from "../../types/SpellTypes";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { selectLevelRangeHigh, selectLevelRangeLow, selectSortSpellsBy, selectSpell, selectSpellClassFilterList, selectSpellClassFilterText, selectSpellFilterByConcentration, selectSpellFilterByRitual, selectSpellSchoolFilterList, selectSpellSchoolFilterText, selectSpellSearch, selectSpellSubclassFilterList, selectSpellSubclassFilterText, setCopySpell, setSelectedSpell, setSpellSearch } from "../../redux/slices/spellSlice";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import GenericModal from "../../components/modals/GenericModal/GenericModal";
import { HeaderContainer, Heading, SearchBar } from "../CreaturePage/CreaturePage";
import { List } from "react-virtualized";
import SpellLi from "../../components/spellPageComponents/SpellLi/SpellLi";
import { selectAddSpellActive, selectFilterSpellActive, selectViewSpellActive, setAddSpellActive, setFilterSpellActive, setViewSpellActive } from "../../redux/slices/modalSlice";
import ViewSpellModal from "../../components/modals/ViewSpellModal/ViewSpellModal";
import AddSpellModal from "../../components/modals/AddSpellModal/AddSpellModal";
import DsLoader from "../../components/dataDisplay/DsLoader/Loader";
import FilterFab from "../../components/input/fabs/FilterFab/FilterFab";
import AddFab from "../../components/input/fabs/AddFab/AddFab";
import FilterSpellModal from "../../components/modals/FilterSpellModal/FilterSpellModal";
import { sortByLevel, sortByName } from "../../helper/searching/sorts";
import { compareSimpleSchemaLists, simpleSchemaListIncludes, simpleSchemaListIncludesName } from "../../helper/searching/searches";
import { usePersistedState } from "../../hooks/usePersistedState/usePersistedState";
import LoginButton from "../../components/input/buttons/LoginButton";
import RandomFab from "../../components/input/fabs/RandomFab/RandomFab";

const SpellPage = () => {
    const [listHeight, setListHeight] = useState<number>(600);
    const [filteredList, setFilteredList] = useState<ISpell[]>([]);

    const [dsToken] = usePersistedState<string>("dsToken", "");

    const spells = useAppSelector(selectSpell);
    const sortSpellsBy = useAppSelector(selectSortSpellsBy);
    const levelRangeLow = useAppSelector(selectLevelRangeLow);
    const levelRangeHigh = useAppSelector(selectLevelRangeHigh);
    const schoolFilterList = useAppSelector(selectSpellSchoolFilterList);
    const schoolFilterText = useAppSelector(selectSpellSchoolFilterText);
    const classFilterList = useAppSelector(selectSpellClassFilterList);
    const classFilterText = useAppSelector(selectSpellClassFilterText);
    const subclassFilterList = useAppSelector(selectSpellSubclassFilterList);
    const subclassFilterText = useAppSelector(selectSpellSubclassFilterText);
    const filterByRitual = useAppSelector(selectSpellFilterByRitual);
    const filterByConcentration = useAppSelector(selectSpellFilterByConcentration);
    const search = useAppSelector(selectSpellSearch);

    const dispatch = useAppDispatch();

    const listContainerRef = useRef<HTMLDivElement>(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        const refHeight = listContainerRef.current?.getBoundingClientRect().height;
        setListHeight(refHeight ? refHeight : 600);
    }, []);

    useEffect(() => {
        if (spells.length > 0) {
            filter();
        }
    }, [spells]);

    useEffect(() => {
        filter();
    }, [
        search,
        sortSpellsBy,
        levelRangeLow,
        levelRangeHigh,
        schoolFilterList,
        schoolFilterText,
        classFilterList,
        classFilterText,
        subclassFilterList,
        subclassFilterText,
        filterByRitual,
        filterByConcentration
    ]);

    const filter = () => {
        const filtered = spells.filter(li => (
            (search && li.name.toUpperCase().includes(search.toUpperCase())
                || !search)
            && li.level >= levelRangeLow
            && li.level <= levelRangeHigh
            && (schoolFilterText && li.school?.name.toUpperCase().includes(schoolFilterText.toUpperCase())
                || !schoolFilterText)
            && (schoolFilterList.length > 0 && simpleSchemaListIncludes(schoolFilterList, li.school)
                || schoolFilterList.length === 0)
            && (classFilterText && simpleSchemaListIncludesName(li.classes, classFilterText)
                || !classFilterText)
            && (classFilterList.length > 0 && compareSimpleSchemaLists(classFilterList, li.classes)
                || classFilterList.length === 0)
            && (subclassFilterText && simpleSchemaListIncludesName(li.subclasses, subclassFilterText)
                || !subclassFilterText)
            && (subclassFilterList.length > 0 && compareSimpleSchemaLists(subclassFilterList, li.subclasses)
                || subclassFilterList.length === 0)
            && (filterByConcentration === "No" && !li.concentration
                || filterByConcentration === "Yes" && li.concentration
                || filterByConcentration === "Either")
            && (filterByRitual === "No" && !li.ritual
                || filterByRitual === "Yes" && li.ritual
                || filterByRitual === "Either")
        ));
        if (sortSpellsBy === SpellSortByOptions.name) {
            setFilteredList(sortByName(filtered));
        } else if (sortSpellsBy === SpellSortByOptions.level) {
            setFilteredList(sortByLevel(filtered));
        }
    };

    return (
        <OuterContainer data-testid="creaturePage__container">
            <GenericModal
                title="Create Spell"
                visibilitySelector={selectAddSpellActive}
                visibilityToggle={setAddSpellActive}
                Component={<AddSpellModal />}
                confirmOnClose
            />

            <GenericModal
                visibilitySelector={selectViewSpellActive}
                visibilityToggle={setViewSpellActive}
                Component={<ViewSpellModal />}
                onClose={() => {
                    dispatch(setSelectedSpell(undefined));
                    dispatch(setCopySpell(false));
                }}
            />

            <GenericModal
                title="Filter Spell List"
                visibilitySelector={selectFilterSpellActive}
                visibilityToggle={setFilterSpellActive}
                Component={<FilterSpellModal />}
            />

            <HeaderContainer sx={{ backgroundColor: myTheme.spellPurple }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Heading>Spells</Heading>
                    </Grid>
                    <SearchBar xs={12}
                        name="search"
                        value={search}
                        setValue={(e: string) => dispatch(setSpellSearch(e))}
                        placeholder="Search Spells"
                        useClearButton
                    />
                </Grid>
            </HeaderContainer>
            <ContentContainer ref={listContainerRef}>
                {spells.length > 0 && (
                    <List
                        width={window.screen.width}
                        height={listHeight}
                        rowHeight={isMobile ? 88 : 103}
                        rowRenderer={({ index, key, style }) => (
                            <SpellLi
                                spell={filteredList[index]}
                                key={key}
                                style={style}
                            />
                        )}
                        rowCount={filteredList.length}
                        overscanRowCount={5}
                        containerStyle={{
                            borderBottom: "3px solid black"
                        }}
                    />
                )}

                {spells.length === 0 && !dsToken && (
                    <LoginButton />
                )}

                {spells.length === 0 && dsToken && (
                    <DsLoader />
                )}
            </ContentContainer>
            <AddFab
                visibilityToggle={setAddSpellActive}
                setSelected={setSelectedSpell}
            />
            <FilterFab visibilityToggle={setFilterSpellActive} />
        </OuterContainer>
    )
}

export default SpellPage;