import { Box, Typography, styled } from "@mui/material";
import { ICreature } from "../../../types/CreatureTypes";
import ListItem from "../../dataDisplay/ListItem/ListItem";
import { NameText } from "../../inititivePageComponents/InitLi/InitLi.styled";
import myTheme from "../../../myTheme";
import { parseNumericCR } from "../../../constants/Cr.constants";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { setSelectedCreature } from "../../../redux/slices/creatureSlice";
import { CSSProperties } from "react";
import { setViewCreatureActive } from "../../../redux/slices/modalSlice";

const CreatureLiWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
});

const CreatureTextContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 5,
    whiteSpace: "nowrap",
    width: "30%"
});

const CreatureCRContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    width: "30%",
    whiteSpace: "nowrap"
});

const SubText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.light,
    paddingLeft: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    overflowX: "clip",
    textOverflow: "ellipsis",
});

export interface CreatureLiProps {
    creature: ICreature,
    style: CSSProperties
}

const CreatureLi = ({ creature, style }: CreatureLiProps) => {

    const dispatch = useAppDispatch();

    const getCreatureTypeText = () => {
        if (creature.creatureType && creature.creatureType.length > 0) {
            let typeText = ''
            creature.creatureType.forEach((t, i) => {
                typeText = typeText + t.name.toLowerCase()
                if (creature.creatureType && i !== creature.creatureType.length - 1) typeText = typeText + ' '
            })
            return typeText
        }
        return ''
    }

    const handleClick = () => {
        dispatch(setSelectedCreature(creature));
        dispatch(setViewCreatureActive(true));
    }

    return (
        <ListItem onClick={handleClick} style={style}>
            <CreatureLiWrapper>
                <CreatureTextContainer>
                    <NameText>{creature.name}</NameText>
                    <SubText>{getCreatureTypeText()}, {creature.size ? creature.size.name.toLowerCase() : 'no size'}, {creature.alignment ? creature.alignment.toLowerCase() : 'no alignment'}</SubText>
                </CreatureTextContainer>
                <CreatureCRContainer>
                    <NameText sx={{textAlign: "right"}}>CR: {parseNumericCR(creature.challenge_rating)}</NameText>
                </CreatureCRContainer>
            </CreatureLiWrapper>
        </ListItem>
    );
};

export default CreatureLi