import axios from "axios";
import { IMagicItem } from "../types/MagicItemTypes";
import { API_BASE_URL } from "./api.constants";
import { getConfig } from "./api.helper";
import { ISpell } from "../types/SpellTypes";


export const getAllSpells = async (authToken: string) => {
    try {
        const res = await axios.get<{ data: ISpell[] }>(
            API_BASE_URL + "spell",
            getConfig(authToken)
        );
        if (res.status === 200) {
            return res.data.data;
        } else {
            throw new Error();
        }
    } catch (e) {
        console.log("Error getting all spells", e);
    }
};