import { useEffect, useState } from "react";
import DsInput from "../DsInput/DsInput";
import DsButton from "../DsButton/DsButton";
import PrimaryButton from "../buttons/PrimaryButton";
import { Box, styled } from "@mui/material";
import myTheme from "../../../myTheme";
import { ISimpleSchema } from "../../../types/CreatureTypes";
import { EMPTY_SIMPLE_SCHEMA } from "../../../constants/Schema.constants";
import { v4 } from "uuid";
import DsTextarea from "../DsTextarea/DsTextarea";

const ColContainer = styled(Box)({
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    border: "2px solid " + myTheme.primary,
    borderRadius: "5px",
    padding: "5px"
});

const RowContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    justifyContent: "center"
});

export interface SimpleSchemaEditorProps {
    item?: ISimpleSchema;
    onSubmit: (action: string, item: ISimpleSchema) => void;
    onCancel: () => void;
    mode?: string;
}

const SimpleSchemaEditor = ({ item, onSubmit, onCancel, mode = "Add" }: SimpleSchemaEditorProps) => {
    const [name, setName] = useState<string>("");
    const [desc, setDesc] = useState<string>("");

    useEffect(() => {
        if (item) {
            setName(item.name);
            if (item.desc) {
                setDesc(item.desc);
            } else if (item.description) {
                setDesc(item.description);
            }
        }
    }, [item]);

    const handleSubmit = () => {
        const action = item ? "update" : "add";
        const newItem: ISimpleSchema = item ? {
            ...item,
            name,
            description: desc,
            desc
        } : {
            name,
            description: desc,
            desc,
            new: true,
            _id: v4()
        }
        onSubmit(action, newItem);
    }

    return (
        <ColContainer>
            <DsInput xs={12} label={"Name"} value={name} setValue={setName} name="name" />
            <DsTextarea xs={12} label={"Description (optional)"} value={desc} setValue={setDesc} name="desc" />
            <RowContainer>
                <PrimaryButton 
                    label={mode}
                    onClick={handleSubmit}
                />
                <PrimaryButton
                    label="Cancel"
                    onClick={onCancel}
                />
            </RowContainer>
        </ColContainer>
    );
};

export default SimpleSchemaEditor;