import { useEffect, useState } from "react";
import DsLoader from "../../components/dataDisplay/DsLoader/Loader";
import loadingGif from "../../assets/gif/DungeonSuiteLoading.gif";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setPath } from "../../redux/slices/dungeonsuiteSlice";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { callEmailVerification, getUser, loginUser, loginUserGoogle, resetPassword, sendPasswordResetEmail, signupUser } from "../../api/authApi";
import DsInput from "../../components/input/DsInput/DsInput";
import { Box, Grid, Typography, styled } from "@mui/material";
import myTheme from "../../myTheme";
import DsButton from "../../components/input/DsButton/DsButton";
import { selectUser, setAuthToken, setUser } from "../../redux/slices/userSlice";
import { loadFromDb } from "../../helper/dungeonsuiteHelper";
import { usePersistedState } from "../../hooks/usePersistedState/usePersistedState";
import { LOGIN_PATH } from "../../constants/DS.constants";

const Heading = styled(Typography)({
    color: myTheme.light,
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    width: "100%"
});

const SubText = styled(Typography)({
    fontSize: "16px",
    color: myTheme.light
});

const LinkText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.light,
    textDecoration: "underline"
});

const InfoText = styled(Typography)({
    fontSize: "16px",
    textAlign: "center",
    margin: "10px 0",
    color: myTheme.light
});

const ErrorText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.creatureRed
});


const EmailVerificationPage = () => {
    const [dsToken, setDsToken] = usePersistedState<string>("dsToken", "");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const user = useAppSelector(selectUser);

    useEffect(() => {
        const params = new URLSearchParams(document.location.search);
        const u = params.get("id");
        const tok = params.get("token");
        if (u && tok) {
            verifyEmail(u, tok);
        }
    }, []);

    const verifyEmail = async (u: string, tok: string) => {
        const res = await callEmailVerification(u, tok);
        if (res) {
            handleReturnToLogin();
        }
    };

    const handleReturnToLogin = () => {
        dispatch(setPath(LOGIN_PATH));
        navigate(LOGIN_PATH);
    }

    const handleLogout = () => {
        setDsToken("");
        dispatch(setUser(undefined));
        dispatch(setAuthToken(""));

        dispatch(setPath(LOGIN_PATH));
        navigate(LOGIN_PATH);
    };

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            padding: "50px 50px"
        }}>
            <Heading sx={{ color: myTheme.primary }}>Dungeonsuite</Heading>
            <Heading>Email Verification</Heading>
            <Grid container rowSpacing="10px">
                <InfoText sx={{ marginTop: "30px" }}>Please check your <b>{user?.email}</b> email. We have sent you a link to verify your email and activate your account.</InfoText>
                <InfoText>Once you have clicked the link in your email, you may click the continue button below to login</InfoText>

                <Grid item xs={12}>
                    <DsButton
                        label="Continue"
                        onClick={handleReturnToLogin}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <LinkText onClick={handleLogout} sx={{ marginTop: "10px" }}>
                    Login with a different account
                </LinkText>
            </Grid>
        </Box >
    );
};

export default EmailVerificationPage;