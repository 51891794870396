import { Box } from "@mui/material";
import { Buffer } from "buffer";
import myTheme from "../../../myTheme";
import SVG from "react-inlinesvg";
import { CustomIconProps } from "../../../types/MiscTypes";

const D20Icon = ({ size, stroke = 'black', fill = 'none', strokeWidth = 10, onClick }: CustomIconProps) => {

    const xml = `
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530.82 609.26">
            <defs>
                <style>
                    .cls-1 {
                        fill: ${fill};
                        stroke-width: ${strokeWidth};
                    }

                    .cls-1,
                    .cls-2 {
                        stroke: ${stroke};
                        stroke-miterlimit: 10;
                    }

                    .cls-2 {
                        fill: ${fill};
                    }
                </style>
            </defs>
            <g>
                <polygon class="cls-1" points="262.39 603.46 526.77 455.25 520.76 148.8 264.39 4.59 6.02 150.81 4.01 453.24 262.39 603.46"/>
                <polygon class="cls-1" points="262.39 603.46 446.66 413.19 520.76 148.8 264.39 80.7 6.02 150.81 80.13 409.18 262.39 603.46"/>
                <polygon class="cls-1" points="80.13 409.18 264.39 80.7 446.66 413.19 80.13 409.18"/>
                <line class="cls-1" x1="4.02" y1="453.24" x2="80.13" y2="409.18"/>
                <line class="cls-1" x1="526.77" y1="455.25" x2="446.66" y2="413.19"/>
                <path class="cls-2" d="M305,82.68v0Z" transform="translate(-40.61 -1.97)"/>
                <path class="cls-2" d="M305,82.68" transform="translate(-40.61 -1.97)"/>
                <path class="cls-2" d="M305,6.57" transform="translate(-40.61 -1.97)"/>
                <line class="cls-1" x1="264.39" y1="80.7" x2="264.39" y2="4.59"/>
            </g>
        </svg>
    `
    const buff = Buffer.from(xml);
    const base64data = buff.toString("base64");

    return (
        <Box 
            sx={{
                width: size ? `${size}px` : "100%",
                height: size ? `${size}px` : "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
            }}
            data-testid="d20Icon__container"
            onClick={onClick}
        >
            <img src={`data:image/svg+xml;base64,${base64data }`} alt=""
                style={{
                    height: "100%"
                }}
            />
        </Box>
    )
}

export default D20Icon;