import myTheme from "../../myTheme";
import { ContentContainer, HeaderContainer, OuterContainer } from "../Pages.styled";
import loadingGif from "../../assets/gif/DungeonSuiteLoading.gif";
import repeatGif from "../../assets/gif/DungeonsuiteLoadingRepeat.gif";
import DsButton from "../../components/input/DsButton/DsButton";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { selectAuthToken, selectUser, setAuthToken, setUser } from "../../redux/slices/userSlice";
import { Box, Grid, styled } from "@mui/material";
import { setPath } from "../../redux/slices/dungeonsuiteSlice";
import { useNavigate } from "react-router-dom";
import { usePersistedState } from "../../hooks/usePersistedState/usePersistedState";
import SimpleSchemaListEditor from "../../components/input/listEditors/SimpleSchemaListEditor/SimpleSchemaListEditor";
import { addNewAttackType, addNewClass, addNewCondition, addNewCreatureType, addNewDamageType, addNewDistanceUnit, addNewItemType, addNewLanguage, addNewMagicSchool, addNewMovement, addNewRarity, addNewSense, addNewSize, addNewSkill, addNewSource, addNewSpellComponent, addNewSubclass, addNewTimeUnit, editAttackType, editClass, editCondition, editCreatureType, editDamageType, editDistanceUnit, editItemTypes, editLanguage, editMagicSchool, editMovement, editRarities, editSense, editSize, editSkill, editSource, editSpellComponent, editSubclass, editTimeUnit, selectCSBAttackTypes, selectCSBClasses, selectCSBConditions, selectCSBCreatureTypes, selectCSBDamageTypes, selectCSBDistanceUnits, selectCSBItemTypes, selectCSBLanguages, selectCSBMagicSchools, selectCSBMovements, selectCSBRarities, selectCSBSenses, selectCSBSizes, selectCSBSkills, selectCSBSources, selectCSBSpellComponents, selectCSBSubclasses, selectCSBTimeUnits, setAttackTypes, setClasses, setConditions, setCreatureTypes, setDamageTypes, setDistanceUnits, setItemTypes, setLanguages, setMagicSchools, setMovements, setRarities, setSenses, setSizes, setSkills, setSources, setSpellComponents, setSubclasses, setTimeUnits } from "../../redux/slices/statblockItemSlice";
import SimpleSchemaItemEditor from "../../components/input/listEditors/SimpleSchemaItemEditor/SimpleSchemaItemEditor";
import GoogleAd from "../../components/adSense/GoogleAd/GoogleAd";
import { LOGIN_PATH } from "../../constants/DS.constants";
import { StatLabel } from "../../components/modals/ViewCreatureModal/ViewCreatureModal";
import { selectFriends, selectReceived, selectSent } from "../../redux/slices/friendSlice";
import AccordionItem from "../../components/dataDisplay/AccordionItem/AccordionItem";
import { Tag } from "../../components/dataDisplay/TagScrollView/TagScrollView";
import DsInput from "../../components/input/DsInput/DsInput";
import { useEffect, useState } from "react";
import { sendFriendRequest, unfriend } from "../../api/friendApi";
import { loadAllFriends } from "../../helper/dungeonsuiteHelper";
import CheckmarkButton from "../../components/input/buttons/CheckmarkButton";
import DeleteButton from "../../components/input/buttons/DeleteButton";
import XButton from "../../components/input/buttons/XButton";
import SmallButton from "../../components/input/buttons/SmallButton";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { ErrorText } from "../LandingPage/LandingPage";

const LogoutContainer = styled(Box)({

});

const ItemEditorContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    padding: "0 20px",
    gap: "10px"
});

const FriendContainer = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "10px"
})

const TagContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    textAlign: "center"
});

const TagLine = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "10px"
})

const SendRequestOuterContainer = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    border: "2px solid " + myTheme.primary,
    borderRadius: "5px",
    padding: "5px 10px"
});

const SendRequestContainer = styled(Grid)({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end"
});

const SettingPage = () => {
    const [dsToken, setDsToken] = usePersistedState<string>("dsToken", "");

    const [requestUsername, setRequestUsername] = useState<string>("");
    const [sendError, setSendError] = useState<string>("");

    const user = useAppSelector(selectUser);
    const sources = useAppSelector(selectCSBSources).filter(i => i.createdBy === user?._id);
    const conditions = useAppSelector(selectCSBConditions).filter(i => i.createdBy === user?._id);
    const creatureTypes = useAppSelector(selectCSBCreatureTypes).filter(i => i.createdBy === user?._id);
    const damageTypes = useAppSelector(selectCSBDamageTypes).filter(i => i.createdBy === user?._id);
    const languages = useAppSelector(selectCSBLanguages).filter(i => i.createdBy === user?._id);
    const movements = useAppSelector(selectCSBMovements).filter(i => i.createdBy === user?._id);
    const senses = useAppSelector(selectCSBSenses).filter(i => i.createdBy === user?._id);
    const sizes = useAppSelector(selectCSBSizes).filter(i => i.createdBy === user?._id);
    const skills = useAppSelector(selectCSBSkills).filter(i => i.createdBy === user?._id);
    const itemTypes = useAppSelector(selectCSBItemTypes).filter(i => i.createdBy === user?._id);
    const rarities = useAppSelector(selectCSBRarities).filter(i => i.createdBy === user?._id);
    const attackTypes = useAppSelector(selectCSBAttackTypes).filter(i => i.createdBy === user?._id);
    const timeUnits = useAppSelector(selectCSBTimeUnits).filter(i => i.createdBy === user?._id);
    const classes = useAppSelector(selectCSBClasses).filter(i => i.createdBy === user?._id);
    const spellComponents = useAppSelector(selectCSBSpellComponents).filter(i => i.createdBy === user?._id);
    const distanceUnits = useAppSelector(selectCSBDistanceUnits).filter(i => i.createdBy === user?._id);
    const subclasses = useAppSelector(selectCSBSubclasses).filter(i => i.createdBy === user?._id);
    const magicSchools = useAppSelector(selectCSBMagicSchools).filter(i => i.createdBy === user?._id);

    const friends = useAppSelector(selectFriends);
    const sentRequests = useAppSelector(selectSent);
    const receivedRequests = useAppSelector(selectReceived);

    const authToken = useAppSelector(selectAuthToken);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setSendError("");
    }, [requestUsername]);

    const handleLogout = () => {
        setDsToken("");
        dispatch(setUser(undefined));
        dispatch(setAuthToken(""));

        dispatch(setPath(LOGIN_PATH));
        navigate(LOGIN_PATH);
    };

    const handleSendRequest = async (u: string) => {
        const res = await sendFriendRequest(u);
        console.log("res", res);
        if (res && res.status === 200) {
            setRequestUsername("");
            if (authToken) {
                loadAllFriends(authToken);
            }
        } else if (res) {
            setSendError(res.data.message);
        } else {
            setSendError("Failed to send request. Please try again.");
        }
    };

    const handleUnfriend = async (u: string) => {
        const res = await unfriend(u);
        if (res && res.status === 200) {
            if (authToken) {
                loadAllFriends(authToken);
            }
        }
    };

    const handleRefresh = async () => {
        if (authToken) {
            loadAllFriends(authToken);
        }
    };

    return (
        <OuterContainer data-testid="settingsPage__container">
            {/* <GoogleAd /> */}
            <HeaderContainer sx={{ backgroundColor: myTheme.settingBrown }}>
                <h2>Settings</h2>
            </HeaderContainer>
            <ContentContainer sx={{ paddingBottom: "250px" }}>
                <Box sx={{
                    padding: "20px 50px 50px 50px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px"
                }}>
                    <StatLabel sx={{ textAlign: "center" }}>Signed in as {user?.username}</StatLabel>
                    <DsButton
                        label="Logout"
                        onClick={handleLogout}
                    />
                </Box>

                <ItemEditorContainer sx={{ marginBottom: "30px" }}>
                    <FriendContainer>
                        <Box sx={{ flexGrow: 1 }}>
                            <AccordionItem
                                label="Friends"
                                content={
                                    <TagContainer>
                                        {friends.length > 0 ? (
                                            friends.map(f => (
                                                <TagLine>
                                                    <Tag key={f._id} sx={{ flexGrow: 1 }}>{f.username}</Tag>
                                                    <XButton onClick={() => handleUnfriend(f.username)} />
                                                </TagLine>
                                            ))
                                        ) : (
                                            <span>No Friends :(</span>
                                        )}
                                    </TagContainer>
                                }
                            />
                        </Box>
                        <SmallButton
                            color={myTheme.green}
                            icon={<ReplayOutlinedIcon />}
                            onClick={handleRefresh}
                        />
                    </FriendContainer>
                    <AccordionItem
                        label="Incoming Friend Requests"
                        defaultExpand={receivedRequests.length > 0}
                        content={
                            <TagContainer>
                                {receivedRequests.length > 0 ? (
                                    receivedRequests.map(f => (
                                        <TagLine>
                                            <Tag key={f._id} sx={{ flexGrow: 1 }}>{f.username}</Tag>
                                            <CheckmarkButton onClick={() => handleSendRequest(f.username)} />
                                            <XButton onClick={() => handleUnfriend(f.username)} />
                                        </TagLine>
                                    ))
                                ) : (
                                    <span>No Requests</span>
                                )}
                            </TagContainer>
                        }
                    />
                    <AccordionItem
                        label="Outgoing Friend Requests"
                        content={
                            <TagContainer>
                                {sentRequests.length > 0 ? (
                                    sentRequests.map(f => (
                                        <TagLine>
                                            <Tag key={f._id} sx={{ flexGrow: 1 }}>{f.username}</Tag>
                                            <XButton onClick={() => handleUnfriend(f.username)} />
                                        </TagLine>
                                    ))
                                ) : (
                                    <span>No Requests</span>
                                )}
                            </TagContainer>
                        }
                    />

                    <SendRequestOuterContainer>
                        <StatLabel sx={{ textAlign: "center" }}>Send Friend Request</StatLabel>
                        <SendRequestContainer container>
                            <DsInput xs={10}
                                name="friend"
                                label="Username"
                                value={requestUsername}
                                setValue={setRequestUsername}
                                selectAllOnFocus
                            />
                            <DsButton xs={2}
                                name="send"
                                // icon={<BiSave fontSize={"20px"} />}
                                label="Send"
                                onClick={() => handleSendRequest(requestUsername)}
                                buttonProps={{
                                    style: {
                                        height: "30px",
                                        backgroundColor: myTheme.primary,
                                        color: myTheme.light
                                    }
                                }}
                                sx={{ paddingLeft: "10px" }}
                            />
                            {sendError && (
                                <ErrorText>{sendError}</ErrorText>
                            )}
                        </SendRequestContainer>
                    </SendRequestOuterContainer>
                </ItemEditorContainer>

                <ItemEditorContainer>
                    <SimpleSchemaItemEditor
                        label="Homebrew Sources"
                        itemName="Source"
                        endpoint="source"
                        list={sources}
                        addItem={addNewSource}
                        editItem={editSource}
                        setList={setSources}
                        sharable
                        shareModalMessage="By sharing this source, the selected friends and groups will be able to view all content labeled with this source."
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Conditions"
                        itemName="Condition"
                        endpoint="condition"
                        list={conditions}
                        addItem={addNewCondition}
                        editItem={editCondition}
                        setList={setConditions}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Creature Types"
                        itemName="Creature Type"
                        endpoint="creatureType"
                        list={creatureTypes}
                        addItem={addNewCreatureType}
                        editItem={editCreatureType}
                        setList={setCreatureTypes}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Damage Types"
                        itemName="Damage Type"
                        endpoint="damageType"
                        list={damageTypes}
                        addItem={addNewDamageType}
                        editItem={editDamageType}
                        setList={setDamageTypes}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Languages"
                        itemName="Language"
                        endpoint="language"
                        list={languages}
                        addItem={addNewLanguage}
                        editItem={editLanguage}
                        setList={setLanguages}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Movements"
                        itemName="Movement"
                        endpoint="movement"
                        list={movements}
                        addItem={addNewMovement}
                        editItem={editMovement}
                        setList={setMovements}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Senses"
                        itemName="Sense"
                        endpoint="sense"
                        list={senses}
                        addItem={addNewSense}
                        editItem={editSense}
                        setList={setSenses}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Sizes"
                        itemName="Size"
                        endpoint="size"
                        list={sizes}
                        addItem={addNewSize}
                        editItem={editSize}
                        setList={setSizes}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Skills"
                        itemName="Skill"
                        endpoint="skill"
                        list={skills}
                        addItem={addNewSkill}
                        editItem={editSkill}
                        setList={setSkills}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Item Types"
                        itemName="Item Type"
                        endpoint="itemType"
                        list={itemTypes}
                        addItem={addNewItemType}
                        editItem={editItemTypes}
                        setList={setItemTypes}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Rarities"
                        itemName="Rarity"
                        endpoint="rarity"
                        list={rarities}
                        addItem={addNewRarity}
                        editItem={editRarities}
                        setList={setRarities}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Attack Types"
                        itemName="Attack Type"
                        endpoint="attackType"
                        list={attackTypes}
                        addItem={addNewAttackType}
                        editItem={editAttackType}
                        setList={setAttackTypes}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Units of Time"
                        itemName="Unit of Time"
                        endpoint="timeUnit"
                        list={timeUnits}
                        addItem={addNewTimeUnit}
                        editItem={editTimeUnit}
                        setList={setTimeUnits}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Classes"
                        itemName="Class"
                        endpoint="class"
                        list={classes}
                        addItem={addNewClass}
                        editItem={editClass}
                        setList={setClasses}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Spell Components"
                        itemName="Spell Component"
                        endpoint="spellComponent"
                        list={spellComponents}
                        addItem={addNewSpellComponent}
                        editItem={editSpellComponent}
                        setList={setSpellComponents}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Units of Distance"
                        itemName="Unit of Distance"
                        endpoint="distanceUnit"
                        list={distanceUnits}
                        addItem={addNewDistanceUnit}
                        editItem={editDistanceUnit}
                        setList={setDistanceUnits}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Subclasses"
                        itemName="Subclasses"
                        endpoint="subclass"
                        list={subclasses}
                        addItem={addNewSubclass}
                        editItem={editSubclass}
                        setList={setSubclasses}
                    />

                    <SimpleSchemaItemEditor
                        label="Homebrew Magic Schools"
                        itemName="Magic School"
                        endpoint="magicSchool"
                        list={magicSchools}
                        addItem={addNewMagicSchool}
                        editItem={editMagicSchool}
                        setList={setMagicSchools}
                    />

                </ItemEditorContainer>
            </ContentContainer>
        </OuterContainer>
    )
}

export default SettingPage;