
const Teams = {
    ENEMY: 'Enemy',
    PLAYER: 'Player',
    ALLY: 'Ally',
    NEUTRAL: 'Neutral',
    ENVIRONMENT: 'Environment'
}

export const teamDataSet = [
    { team: Teams.ENEMY },
    { team: Teams.PLAYER},
    { team: Teams.ALLY},
    { team: Teams.NEUTRAL},
    { team: Teams.ENVIRONMENT}
];

export default Teams;