export const LOGIN_PATH = "/ds/";
export const INIT_PATH = "/ds/initTracker";
export const CREATURE_PATH = "/ds/creatures";
export const SPELL_PATH = "/ds/spells";
export const ITEM_PATH = "/ds/items";
export const SETTING_PATH = "/ds/settings";
export const EMAIL_VERIFY_PATH = "/ds/emailVerification";
export const PASS_RESET_PATH = "/ds/passwordReset";

export const NAV_PAGES = [
    INIT_PATH,
    CREATURE_PATH,
    SPELL_PATH,
    ITEM_PATH,
    SETTING_PATH
]