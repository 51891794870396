import axios from "axios";
import { API_BASE_URL } from "./api.constants";
import { getConfig } from "./api.helper";
import { GetFriendResponse } from "../types/FriendTypes";
import store from "../redux/store";


export const getAllFriends = async (authToken: string) => {
    try {
        const res = await axios.get<GetFriendResponse>(
            API_BASE_URL + "friend",
            getConfig(authToken)
        );
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error();
        }
    } catch (e) {
        console.log("Error getting all friends", e);
    }
};

export const sendFriendRequest = async (username: string) => {
    const token = store.getState().user.authToken;
    if (!token) return undefined;
    try {
        const res = await axios.post(
            API_BASE_URL + "friend/request",
            { receiver: username },
            getConfig(token)
        );
        return res;
    } catch (e: any) {
        console.log("Error sending friend request");
        return e.response;
    }
}

export const unfriend = async (username: string) => {
    const token = store.getState().user.authToken;
    if (!token) return undefined;
    try {
        const res = await axios.post(
            API_BASE_URL + "friend/unfriend",
            { receiver: username },
            {
                ...getConfig(token),
                method: "DELETE"
            }
        );
        return res;
    } catch (e) {
        console.log("Error unfriending");
    }
}