import { Box, Grid, GridProps, styled, SxProps, Theme, Typography } from "@mui/material";
import myTheme from "../../../myTheme";
import { MdClose } from "react-icons/md";
import { useRef } from "react";

export const TextareaWrapper = styled(Grid)({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    boxSizing: "border-box",
    position: "relative"
})

export const TextareaLabel = styled(Typography)({
    color: myTheme.medGray,
    fontSize: "14px"
})

export const StyledTextarea = styled("textarea")({
    width: "100%",
    background: myTheme.medDark,
    color: myTheme.medGray,
    borderRadius: "5px",
    fontSize: "14px",
    paddingLeft: "5px",
    paddingRight: "5px",
    border: "none",
    boxSizing: "border-box",
    ":focus": {
        outline: "none",
        textDecoration: "none",
        border: "2px solid " + myTheme.primary
    }
});

export interface DsTextareaProps extends GridProps {
    label?: string;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    validate?: (item: string) => boolean
    name?: string;
    textareaStyles?: SxProps<Theme> | undefined;
    placeholder?: string;
    rows?: number;
}

const DsTextarea = ({
    label,
    value,
    setValue,
    validate,
    name = "default",
    textareaStyles,
    placeholder,
    rows = 8,
    ...props
}: DsTextareaProps) => {

    const ref = useRef<HTMLTextAreaElement>(null);

    const handleChange = (e: React.ChangeEvent<{ value: string }>) => {
        if (!validate) {
            setValue(e.target.value);
            return;
        }

        if (validate(e.target.value)) {
            setValue(e.target.value);
        }
    }

    return (
        <TextareaWrapper data-testid={`dsTextarea__${name}__container`} item {...props}>
            {label && <TextareaLabel data-testid={`dsTextarea__${name}__label`}>{label}</TextareaLabel>}
            <StyledTextarea
                ref={ref}
                data-testid={`dsTextarea__${name}__textarea`}
                value={value}
                onChange={handleChange}
                sx={textareaStyles}
                placeholder={placeholder}
                rows={rows}
            />
        </TextareaWrapper>
    )
}

export default DsTextarea;