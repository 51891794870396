import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import CR from '../../constants/Cr.constants';
import CreatureStatus from '../../constants/CreatureStatus';
import Teams from '../../constants/Teams.constants';
import { dTwenty } from '../../helper/dice/dice';
import continueSameName from '../../helper/initHelper/continueSameName';
import { IInitItem } from '../../types/InitiativeTypes';
import type { RootState } from "../store";
import { v4 } from 'uuid';

const sorter = function (list: IInitItem[]) {
    const newList = [...list];
    newList.sort((a, b) => b.initiative - a.initiative);
    return newList;
};

// Define a type for the slice state
interface InitState {
    initOrder: IInitItem[];
    selectedInitItem?: IInitItem;
}

// Define the initial state using that type
const initialState: InitState = {
    initOrder: []
}

export const initSlice = createSlice({
    name: 'init',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setInitOrder: (state, action: PayloadAction<IInitItem[]>) => {
            state.initOrder = action.payload
        },
        setSelectedInitItem: (state, action: PayloadAction<IInitItem>) => {
            state.selectedInitItem = action.payload;
        },
        addCreatureToInit: (state, action: PayloadAction<IInitItem & {qty: number, initRoll?: number}>) => {
            console.log('redux', action.payload)

            //Destructure payload and assign default values
            const {
                qty = 1,
                initMod = 0,
                team = Teams.ENEMY,
                nameFiller = '',
                name = '',
                hp = 1,
                secondRoll = 0,
                initRoll,
                _id,
                ac = 10,
                cr = CR.ZERO,
                status = CreatureStatus.ALIVE,
                statblock
            } = action.payload

            const newList = [...state.initOrder]

            //Create the requested number of creatures and push them onto the list
            for (let i = 1; i <= qty; i++) {
                let finishedRoll
                if (initRoll !== undefined) {
                    finishedRoll = initRoll
                } else {
                    finishedRoll = dTwenty()
                    let reRoll = dTwenty()
                    if (secondRoll === 1) {
                        //console.log('adv')
                        if (finishedRoll < reRoll) finishedRoll = reRoll
                    } else if (secondRoll === -1) {
                        //console.log('dis')
                        if (finishedRoll > reRoll) finishedRoll = reRoll
                    }
                    finishedRoll += initMod
                }
                //console.log(initiativeRoll);
                //console.log(finishedRoll);
                const unitInsert: IInitItem = {
                    initiative: finishedRoll,
                    team,
                    name: continueSameName(newList, name),
                    hp: Number(hp),
                    maxHp: Number(hp),
                    tempHp: 0,
                    _id: qty === 1 ? _id : v4(),
                    ac: Number(ac),
                    saves: {
                        success: 0,
                        fail: 0
                    },
                    initMod,
                    secondRoll,
                    nameFiller: name,
                    cr,
                    status,
                    locked: false,
                    statblock
                }
                newList.push(unitInsert)
                state.initOrder = sorter(newList)
            }
        },
        editInitItem: (state, action: PayloadAction<IInitItem>) => {
            const newList = [...state.initOrder]
            const newCreature = action.payload
            let j = 0
            for (let i = 0; i < state.initOrder.length; i++) {
                const c = state.initOrder[i]
                if (c._id === newCreature._id) {
                    newList[i] = newCreature
                    break
                }
            }
            state.initOrder = sorter(newList)
        },
        deleteInitItem: (state, action: PayloadAction<IInitItem>) => {
            const toDelete = action.payload
            const newList = [...state.initOrder]
            const creature = newList.find(c => c._id === toDelete._id)
            newList.splice(newList.indexOf(creature!), 1)
            state.initOrder = sorter(newList)
        }
    }
})

// Action creators are generated for each case reducer function
export const { setInitOrder, setSelectedInitItem, addCreatureToInit, editInitItem, deleteInitItem } = initSlice.actions

export const selectInitOrder = (state: RootState) => state.init.initOrder
export const selectLivingPlayers = (state: RootState) => state.init.initOrder.filter((c: IInitItem) => c.status === CreatureStatus.ALIVE && c.team === Teams.PLAYER)
export const selectSelectedInitItem = (state: RootState) => state.init.selectedInitItem;

export default initSlice.reducer