import { Box, Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import CreatureLi from "../../components/creaturePageComponents/CreatureLi/CreatureLi";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { selectCreatureFilters, selectCreatureSearch, selectCreatures, selectSelectedCreature, setCopyCreature, setCreatureSearch, setSelectedCreature } from "../../redux/slices/creatureSlice";
import { ContentContainer, OuterContainer } from "../Pages.styled";
import { List } from "react-virtualized";
import { useEffect, useRef, useState } from "react";
import GenericModal from "../../components/modals/GenericModal/GenericModal";
import ViewCreatureModal from "../../components/modals/ViewCreatureModal/ViewCreatureModal";
import { selectAddCreatureActive, selectFilterCreatureActive, selectViewCreatureActive, setAddCreatureActive, setFilterCreatureActive, setViewCreatureActive } from "../../redux/slices/modalSlice";
import AddCreatureModal from "../../components/modals/AddCreatureModal/AddCreatureModal";
import myTheme from "../../myTheme";
import DsInput from "../../components/input/DsInput/DsInput";
import { ICreature } from "../../types/CreatureTypes";
import DsLoader from "../../components/dataDisplay/DsLoader/Loader";
import AddFab from "../../components/input/fabs/AddFab/AddFab";
import FilterFab from "../../components/input/fabs/FilterFab/FilterFab";
import FilterCreatureModal from "../../components/modals/FilterCreatureModal/FilterCreatureModal";
import { compareSimpleSchemaLists, simpleSchemaListIncludesName } from "../../helper/searching/searches";
import { CreatureSearchFilters } from "../../types/SearchTypes";
import { sortByCR, sortByName } from "../../helper/searching/sorts";
import { usePersistedState } from "../../hooks/usePersistedState/usePersistedState";
import LoginButton from "../../components/input/buttons/LoginButton";

export const HeaderContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    zIndex: 100,
    boxShadow: "0px 2px 5px " + myTheme.darkest,
    padding: "0px 10px 8px 10px"
});

export const Heading = styled(Typography)({
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    color: "black"
});

export const SearchBar = styled(DsInput)({
    "> input": {
        backgroundColor: myTheme.light,
        boxShadow: "0px 2px 2px " + myTheme.darkest,
        borderRadius: "50px",
        padding: "0 15px",
        fontSize: "20px",
        color: myTheme.darkest,
        ":focus": {
            border: "none"
        }
    }
});

const CreaturePage = () => {
    const [listHeight, setListHeight] = useState<number>(600);
    const [filteredList, setFilteredList] = useState<ICreature[]>([]);

    const [dsToken] = usePersistedState<string>("dsToken", "");

    const listContainerRef = useRef<HTMLDivElement>(null);
    const creatures = useAppSelector(selectCreatures);
    const filters = useAppSelector(selectCreatureFilters);
    const creature = useAppSelector(selectSelectedCreature);
    const search = useAppSelector(selectCreatureSearch);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const dispatch = useAppDispatch();

    useEffect(() => {
        const refHeight = listContainerRef.current?.getBoundingClientRect().height;
        setListHeight(refHeight ? refHeight : 600);
    }, []);

    useEffect(() => {
        if (creatures.length > 0) {
            filter();
        }
    }, [creatures]);

    useEffect(() => {
        filter();
    }, [
        search,
        filters
    ]);

    const filter = () => {
        const filtered = creatures.filter(li => (
            (search && li.name.toUpperCase().includes(search.toUpperCase())
                || !search)
            && filters.crLow <= li.challenge_rating
            && filters.crHigh >= li.challenge_rating
            && (filters.typeString && simpleSchemaListIncludesName(li.creatureType, filters.typeString)
                || !filters.typeString)
            && (filters.typeList.length > 0 && compareSimpleSchemaLists(filters.typeList, li.creatureType)
                || filters.typeList.length === 0)
        ));
        if (filters.sortBy === CreatureSearchFilters.NAME) {
            setFilteredList(sortByName(filtered));
        } else if (filters.sortBy === CreatureSearchFilters.CR) {
            setFilteredList(sortByCR(filtered));
        }
    };

    return (
        <OuterContainer data-testid="creaturePage__container">
            <GenericModal
                title="Create Creature"
                visibilitySelector={selectAddCreatureActive}
                visibilityToggle={setAddCreatureActive}
                Component={<AddCreatureModal />}
                confirmOnClose
            />

            <GenericModal
                visibilitySelector={selectViewCreatureActive}
                visibilityToggle={setViewCreatureActive}
                Component={<ViewCreatureModal creature={creature} />}
                onClose={() => {
                    setSelectedCreature(undefined);
                    setCopyCreature(false);
                }}
            />

            <GenericModal
                title="Filter Creatures"
                visibilitySelector={selectFilterCreatureActive}
                visibilityToggle={setFilterCreatureActive}
                Component={<FilterCreatureModal />}
            />

            <HeaderContainer sx={{ backgroundColor: myTheme.creatureRed }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Heading>Creatures</Heading>
                    </Grid>
                    <SearchBar xs={12}
                        name="search"
                        value={search}
                        setValue={(e: string) => dispatch(setCreatureSearch(e))}
                        placeholder="Search Creatures"
                        useClearButton
                    />
                </Grid>
            </HeaderContainer>
            <ContentContainer ref={listContainerRef}>
                {creatures.length > 0 && (
                    <List
                        width={window.screen.width}
                        height={listHeight}
                        rowHeight={isMobile ? 60 : 80}
                        rowRenderer={({ index, key, style }) => (
                            <CreatureLi
                                creature={filteredList[index]}
                                key={key}
                                style={style}
                            />
                        )}
                        rowCount={filteredList.length}
                        overscanRowCount={5}
                        containerStyle={{
                            borderBottom: "3px solid black"
                        }}
                    />
                )}

                {creatures.length === 0 && !dsToken && (
                    <LoginButton />
                )}

                {creatures.length === 0 && dsToken && (
                    <DsLoader />
                )}
            </ContentContainer>
            <AddFab
                visibilityToggle={setAddCreatureActive}
                setSelected={setSelectedCreature}
            />
            <FilterFab visibilityToggle={setFilterCreatureActive} />
        </OuterContainer>
    )
}

export default CreaturePage;