import { useEffect, useState } from "react";
import DsLoader from "../../components/dataDisplay/DsLoader/Loader";
import loadingGif from "../../assets/gif/DungeonSuiteLoading.gif";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setPath } from "../../redux/slices/dungeonsuiteSlice";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { callEmailVerification, callRequestEmailVerification, getUser, loginUser, loginUserGoogle, setNewUsername, signupUser } from "../../api/authApi";
import DsInput from "../../components/input/DsInput/DsInput";
import { Box, Grid, Typography, styled } from "@mui/material";
import myTheme from "../../myTheme";
import DsButton from "../../components/input/DsButton/DsButton";
import { selectAuthToken, selectUser, setAuthToken, setUser } from "../../redux/slices/userSlice";
import { loadFromDb } from "../../helper/dungeonsuiteHelper";
import { usePersistedState } from "../../hooks/usePersistedState/usePersistedState";
import { EMAIL_VERIFY_PATH, INIT_PATH, PASS_RESET_PATH } from "../../constants/DS.constants";

const Heading = styled(Typography)({
    color: myTheme.light,
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    width: "100%"
});

const SubText = styled(Typography)({
    fontSize: "16px",
    color: myTheme.light
});

const InfoText = styled(Typography)({
    fontSize: "16px",
    textAlign: "center",
    margin: "10px 0",
    color: myTheme.light
});

const LinkText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.light,
    textDecoration: "underline",
    textAlign: "center"
});

export const ErrorText = styled(Typography)({
    fontSize: "16px",
    fontStyle: "italic",
    color: myTheme.creatureRed
});


const LandingPage = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [landingView, setLandingView] = useState<string>("");

    const [dsToken, setDsToken] = usePersistedState<string>("dsToken", "");

    const token = useAppSelector(selectAuthToken);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        checkExistingToken();
    }, [dsToken]);

    useEffect(() => {
        setError("");
    }, [email, password, confirmPassword]);

    useEffect(() => {
        if (landingView === "loader") {
            setTimeout(() => {
                dispatch(setPath(INIT_PATH));
                navigate(INIT_PATH);
            }, 1000);
        }
    }, [landingView]);

    const checkExistingToken = async () => {
        if (dsToken) {
            const success = await loadAppData(dsToken);
            if (!success) {
                setLandingView("login");
            }
        } else {
            setLandingView("login");
        }
    }

    const loadAppData = async (tok: string) => {
        const user = await getUser(tok);
        if (!user) return false;

        setDsToken(tok);

        dispatch(setAuthToken(tok));
        dispatch(setUser(user));

        if (!user.emailValidated) {
            callRequestEmailVerification(tok);
            dispatch(setPath(EMAIL_VERIFY_PATH));
            navigate(EMAIL_VERIFY_PATH);
            return true;
        }

        if (!user.username) {
            setLandingView("setUsername");
            return true;
        }

        loadFromDb(tok);

        setLandingView("animation");
        setTimeout(() => {
            setLandingView("loader");
        }, 4000);
        return true;
    }

    const handleLogin = async () => {
        if (email && password) {
            const tok = await loginUser(email, password);
            if (tok) {
                await loadAppData(tok);
            } else {
                setError("Invalid email or password");
            }
        } else {
            setError("Invalid email or password");
        }
    };

    const handleGoogleLogin = async (res: CredentialResponse) => {
        if (res.credential) {
            const tok = await loginUserGoogle(res.credential);
            if (tok) {
                await loadAppData(tok);
            } else {
                setError("Google login failed");
            }
        } else {
            setError("Google login failed");
        }
    }

    const handleRegister = async () => {
        if (!password || !confirmPassword || password !== confirmPassword) {
            setError("Passwords must match");
            return;
        }
        if (!email) {
            setError("Enter a valid email");
            return;
        }
        const tok = await signupUser(email, password);
        if (tok) {
            await loadAppData(tok);
        } else {
            setError("Invalid email or password");
        }
    };

    const handleChangeToRegister = () => {
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setLandingView("register");
    };

    const handleChangeToLogin = () => {
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setLandingView("login");
    };

    const handlePasswordReset = () => {
        dispatch(setPath(PASS_RESET_PATH));
        navigate(PASS_RESET_PATH);
    }

    const handleSetUsername = async () => {
        if (!username) {
            setError("Enter a username");
            return;
        }
        if (!token) {
            return;
        }
        const res = await setNewUsername(username, token);
        if (res.username) {
            loadAppData(token);
        } else {
            setError("Username is already in use. Please try a different username");
        }
    };

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            padding: landingView === "login" || landingView === "register" || landingView === "emailVerification" || landingView === "setUsername" ? "50px 50px" : "0px"
        }}>
            {landingView === "login" && (
                <>
                    <Heading sx={{ color: myTheme.primary }}>Dungeonsuite</Heading>
                    <Heading>Login</Heading>
                    <Grid container rowSpacing="10px">
                        <DsInput
                            name="email"
                            label="Email"
                            value={email}
                            setValue={setEmail}
                        />
                        <DsInput
                            name="password"
                            label="Password"
                            value={password}
                            setValue={setPassword}
                            type="password"
                        />

                        {error && (
                            <Grid item xs={12} sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <ErrorText>{error}</ErrorText>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <DsButton
                                label="Login"
                                onClick={handleLogin}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <SubText>Login with Google</SubText>
                            <GoogleLogin
                                onSuccess={handleGoogleLogin}
                                onError={() => {
                                    setError("Google login failed");
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <LinkText onClick={handleChangeToRegister}>
                                Click here to Register a new account
                            </LinkText>
                            <LinkText onClick={handlePasswordReset} sx={{ marginTop: "10px" }}>
                                Forgot your password? Click here to reset your password
                            </LinkText>
                        </Grid>
                    </Grid>
                </>
            )}

            {landingView === "register" && (
                <>
                    <Heading sx={{ color: myTheme.primary }}>Dungeonsuite</Heading>
                    <Heading>Register</Heading>
                    <Grid container rowSpacing="10px">
                        <DsInput
                            name="email"
                            label="Email"
                            value={email}
                            setValue={setEmail}
                        />
                        <DsInput
                            name="password"
                            label="Password"
                            value={password}
                            setValue={setPassword}
                            type="password"
                        />
                        <DsInput
                            name="confirmPassword"
                            label="Confirm Password"
                            value={confirmPassword}
                            setValue={setConfirmPassword}
                            type="password"
                        />

                        {error && (
                            <Grid item xs={12} sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <ErrorText>{error}</ErrorText>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <DsButton
                                label="Register"
                                onClick={handleRegister}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <SubText>Register with Google</SubText>
                            <GoogleLogin
                                onSuccess={handleGoogleLogin}
                                onError={() => {
                                    setError("Google registration failed");
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <LinkText onClick={handleChangeToLogin}>
                                Click here to Login with existing account
                            </LinkText>
                        </Grid>
                    </Grid>
                </>
            )}

            {landingView === "emailVerification" && (
                <>
                    <Heading sx={{ color: myTheme.primary }}>Dungeonsuite</Heading>
                    <Heading>Email Verification</Heading>
                    <Grid container rowSpacing="10px">
                        <InfoText sx={{ marginTop: "30px" }}>Please check your email. We have sent you a link to verify your email and activate your account.</InfoText>
                        <InfoText>Once you have clicked the link in your email, you may click the continue button below to login</InfoText>

                        <Grid item xs={12}>
                            <DsButton
                                label="Continue"
                                onClick={() => window.location.reload()}
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            {landingView === "setUsername" && (
                <>
                    <Heading sx={{ color: myTheme.primary }}>Dungeonsuite</Heading>
                    <Heading>Set Your Username</Heading>
                    <Grid container rowSpacing="10px">
                        <DsInput
                            name="username"
                            label="Username"
                            value={username}
                            setValue={setUsername}
                        />

                        {error && (
                            <Grid item xs={12} sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <ErrorText>{error}</ErrorText>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <DsButton
                                label="Submit"
                                onClick={handleSetUsername}
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            {landingView === "animation" && (
                <div>
                    <img src={loadingGif}
                        width={"100%"}
                    />
                </div>
            )}

            {landingView === "loader" && (
                <div>
                    <DsLoader />
                </div>
            )}
        </Box>
    );
};

export default LandingPage;