import { Box, styled } from "@mui/material";
import myTheme from "../../../../myTheme";
import { useAppDispatch } from "../../../../hooks/reduxHooks";
import { setClearInitActive, setRerollInitActive } from "../../../../redux/slices/modalSlice";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { IoMdTrash } from "react-icons/io";

const FabWrapper = styled(Box)(({ theme }) => ({
    position: "absolute",
    left: "3vw",
    bottom: "10vh",
    backgroundColor: myTheme.creatureRed,
    width: "60px",
    height: "60px",
    borderRadius: "100%",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
}))

const ClearInitFab = () => {

    const dispatch = useAppDispatch()

    const handleClick = () => {
        dispatch(setClearInitActive(true));
    }

    return (
        <FabWrapper data-testid="clearInitFab__wrapper" onClick={handleClick}>
            <IoMdTrash color={myTheme.light} size={"44px"} />
        </FabWrapper>
    )
}

export default ClearInitFab;