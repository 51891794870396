import { Box } from "@mui/material";
import { Buffer } from 'buffer';

const D20Dis = ({size = 10, stroke = 'black', fill = 'none', strokeWidth = 10, onClick }: any) => {

    const xml = `
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530.82 609.26">
            <defs>
                <style>
                    .cls-1 {
                        fill: none;
                        stroke-width: ${strokeWidth};
                    }

                    .cls-1,
                    .cls-2,
                    .cls-3 {
                        stroke: ${stroke};
                        stroke-miterlimit: 10;
                        stroke-width: ${strokeWidth}
                    }

                    .cls-2 {
                        fill: none;
                    }

                    .cls-4 {
                        fill: ${fill};
                        stroke-width: ${strokeWidth};
                        stroke: ${stroke};
                    }
                </style>
            </defs>
            <g>
                <polygon class="cls-4" points="396.67 79.17 520.17 148.64 526.17 454.73 262.09 602.77 4.01 452.73 6.02 150.64 264.09 4.59 342.29 48.58 307.01 112.69 347.09 183.87 289.07 283.76 397.33 177.89 356.66 113.89 396.67 79.17"/>
                <polyline class="cls-2" points="316.94 94.65 264.09 80.61 6.02 150.64 80.04 408.71 262.09 602.77 446.15 412.71 520.17 148.64 364.32 107.24"/>
                <polyline class="cls-2" points="345.31 228.76 446.15 412.71 80.04 408.71 264.09 80.61 333.51 207.25"/>
                <path class="cls-2" d="M383.7,50.4" transform="translate(-41.41 -1.82)"/>
                <line class="cls-2" x1="4.02" y1="452.73" x2="80.04" y2="408.71"/>
                <line class="cls-2" x1="526.17" y1="454.73" x2="446.15" y2="412.71"/>
                <path class="cls-3" d="M305.5,82.43v0Z" transform="translate(-41.41 -1.82)"/>
                <path class="cls-3" d="M305.5,82.43" transform="translate(-41.41 -1.82)"/>
                <path class="cls-3" d="M305.5,6.41" transform="translate(-41.41 -1.82)"/>
                <line class="cls-2" x1="264.09" y1="80.62" x2="264.09" y2="4.59"/>
            </g>
        </svg>
    `
    
    const buff = Buffer.from(xml);
    const base64data = buff.toString("base64");

    return (
        <Box
            sx={{
                width: size ? `${size}px` : "100%",
                height: size ? `${size}px` : "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
            }}
            data-testid="d20DisIcon__container"
            onClick={onClick}
        >
            <img src={`data:image/svg+xml;base64,${base64data}`} alt=""
                style={{
                    height: "100%"
                }}
            />
        </Box>
    )
}

export default D20Dis;