import { Box } from "@mui/material";
import { HTMLProps } from "react";
import myTheme from "../../../myTheme";

export interface BorderButtonProps extends HTMLProps<HTMLDivElement> {
    targetPath: string;
    currentPath: string;
    Icon: JSX.Element;
}

const BorderButton = ({ targetPath, currentPath, Icon, id = "default" }: BorderButtonProps) => {

    const matches = () => {
        return targetPath === currentPath;
    }

    return (
        <Box
            data-testid={matches() ? `borderButton__${id}--selected` : `borderButton__${id}--default`}
            sx={{
                height: "100%",
                borderRadius: "100%",
                boxSizing: "border-box",
                boxShadow: matches() ? "0px 5px 8px " + myTheme.darkest : "none",
                transition: "box-shadow 0.3s ease-in-out",
                ":hover": {
                    boxShadow:  "0px 5px 8px " + myTheme.darkest
                }
            }}
        >
            {Icon}
        </Box>
    )
}

export default BorderButton;