import { IInitItem } from "../../types/InitiativeTypes";

const continueSameName = function (list: IInitItem[], name: string) {
    let k = 1;
    let outputName = 'default name';
    console.log(list, name)
    for (let j = 0; j < list.length; j++) {
        if (list[j].nameFiller === `${name}`) {
            if (list[j].name === `${name}`) {
                list[j].name = `${name} 1`;
            }
            k++;
        }
    }
    if (k === 1) {
        outputName = `${name}`;
    } else {
        outputName = `${name} ${k}`;
    }
    return outputName;
};

export default continueSameName;