import { Box, Grid, SelectChangeEvent, styled, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import DsButton from "../../input/DsButton/DsButton";
import DsDropdown from "../../input/DsDropdown/DsDropdown";
import DsInput from "../../input/DsInput/DsInput";
import { BiSave } from 'react-icons/bi';
import { ISimpleSchema } from "../../../types/CreatureTypes";
import SimpleSchemaListEditor from "../../input/listEditors/SimpleSchemaListEditor/SimpleSchemaListEditor";
import { selectAuthToken } from "../../../redux/slices/userSlice";
import { createSpell } from "../../../api/postData";
import { setAddSpellActive } from "../../../redux/slices/modalSlice";
import { updateSpell } from "../../../api/updateData";
import { addNewClass, addNewDistanceUnit, addNewItemType, addNewLanguage, addNewMagicSchool, addNewMovement, addNewRarity, addNewSense, addNewSize, addNewSkill, addNewSpellComponent, addNewSubclass, addNewTimeUnit, selectCSBClasses, selectCSBConditions, selectCSBCreatureTypes, selectCSBDamageTypes, selectCSBDistanceUnits, selectCSBItemTypes, selectCSBLanguages, selectCSBMagicSchools, selectCSBMovements, selectCSBRarities, selectCSBSenses, selectCSBSizes, selectCSBSkills, selectCSBSpellComponents, selectCSBSubclasses, selectCSBTimeUnits } from "../../../redux/slices/statblockItemSlice";
import { createNewItems, createNewItemsNoValue } from "../../../helper/createNewItems";
import SimpleSchemaValueListEditor from "../../input/listEditors/SimpleSchemaValueListEditor/SimpleSchemaValueListEditor";
import { ABILITY_SCORES, ALIGNMENTS } from "../../../constants/SbItem.constants";
import { resetSpellSortAndFilter, selectCopySpell, selectLevelRangeHigh, selectLevelRangeLow, selectSelectedSpell, selectSortSpellsBy, selectSpellClassFilterList, selectSpellClassFilterText, selectSpellFilterByConcentration, selectSpellFilterByRitual, selectSpellSchoolFilterList, selectSpellSchoolFilterText, selectSpellSubclassFilterList, selectSpellSubclassFilterText, setCopySpell, setLevelRangeHigh, setLevelRangeLow, setSelectedSpell, setSortSpellsBy, setSpellClassFilterList, setSpellClassFilterText, setSpellFilterByConcentration, setSpellFilterByRitual, setSpellSchoolFilterList, setSpellSchoolFilterText, setSpellSubclassFilterList, setSpellSubclassFilterText } from "../../../redux/slices/spellSlice";
import { IDistanceSchema, IDurationSchema, ISpell, SpellSortByOptions } from "../../../types/SpellTypes";
import DsTextarea from "../../input/DsTextarea/DsTextarea";
import { getSpellLevelDataSet, parseNumericSpellLevel, parseSpellLevel } from "../../../constants/SpellLevel.constants";
import DsDropdownRange from "../../input/DsDropdownRange/DsDropdownRange";
import DsFilter from "../../input/DsFilter/DsFilter";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

const ContentGrid = styled(Grid)({});

const FilterSpellModal = () => {
    const sbSchools = useAppSelector(selectCSBMagicSchools);
    const sbClasses = useAppSelector(selectCSBClasses);
    const sbSubclasses = useAppSelector(selectCSBSubclasses);

    const sortSpellsBy = useAppSelector(selectSortSpellsBy);
    const levelRangeLow = useAppSelector(selectLevelRangeLow);
    const levelRangeHigh = useAppSelector(selectLevelRangeHigh);
    const schoolFilterList = useAppSelector(selectSpellSchoolFilterList);
    const schoolFilterText = useAppSelector(selectSpellSchoolFilterText);
    const classFilterList = useAppSelector(selectSpellClassFilterList);
    const classFilterText = useAppSelector(selectSpellClassFilterText);
    const subclassFilterList = useAppSelector(selectSpellSubclassFilterList);
    const subclassFilterText = useAppSelector(selectSpellSubclassFilterText);
    const filterByRitual = useAppSelector(selectSpellFilterByRitual);
    const filterByConcentration = useAppSelector(selectSpellFilterByConcentration);

    const dispatch = useAppDispatch();

    const handleSetLowValueLevel = (e: any) => {
        const lv = parseSpellLevel(e);
        dispatch(setLevelRangeLow(lv));
        if (lv > levelRangeHigh) {
            dispatch(setLevelRangeHigh(lv));
        }
    }

    const handleSetHighValueLevel = (e: any) => {
        const lv = parseSpellLevel(e);
        dispatch(setLevelRangeHigh(lv));
        if (lv < levelRangeLow) {
            dispatch(setLevelRangeLow(lv));
        }
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <ContentGrid container rowSpacing={"10px"} columnSpacing={"20px"} sx={{ boxSizing: "border-box" }}>

                <DsDropdown xs={12}
                    name="sortBy"
                    label="Sort By"
                    value={sortSpellsBy}
                    setValue={(e: any) => dispatch(setSortSpellsBy(e))}
                    data={[{ value: SpellSortByOptions.name }, { value: SpellSortByOptions.level }]}
                    attribute="value"
                />

                <Grid item xs={12}>
                    <DsDropdownRange
                        label="Level Range"
                        lowValue={parseNumericSpellLevel(levelRangeLow)}
                        highValue={parseNumericSpellLevel(levelRangeHigh)}
                        setLowValue={handleSetLowValueLevel}
                        setHighValue={handleSetHighValueLevel}
                        data={getSpellLevelDataSet()}
                        attribute="level"
                    />
                </Grid>

                <DsDropdown xs={12}
                    name="concentration"
                    label="Concentration"
                    value={filterByConcentration}
                    setValue={(e: any) => dispatch(setSpellFilterByConcentration(e))}
                    data={[{ value: "Either" }, { value: "Yes" }, { value: "No" }]}
                    attribute="value"
                />

                <DsDropdown xs={12}
                    name="ritual"
                    label="Ritual"
                    value={filterByRitual}
                    setValue={(e: any) => dispatch(setSpellFilterByRitual(e))}
                    data={[{ value: "Either" }, { value: "Yes" }, { value: "No" }]}
                    attribute="value"
                />

                <Grid item xs={12}>
                    <DsFilter
                        label="Magic School"
                        name="school"
                        list={schoolFilterList}
                        setList={(e: any) => {
                            dispatch(setSpellSchoolFilterList(e(schoolFilterList)))
                        }}
                        textValue={schoolFilterText}
                        setTextValue={(e: any) => dispatch(setSpellSchoolFilterText(e))}
                        dropdownList={sbSchools}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DsFilter
                        label="Class"
                        name="class"
                        list={classFilterList}
                        setList={(e: any) => {
                            dispatch(setSpellClassFilterList(e(classFilterList)))
                        }}
                        textValue={classFilterText}
                        setTextValue={(e: any) => dispatch(setSpellClassFilterText(e))}
                        dropdownList={sbClasses}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DsFilter
                        label="Subclass"
                        name="subclass"
                        list={subclassFilterList}
                        setList={(e: any) => {
                            dispatch(setSpellSubclassFilterList(e(subclassFilterList)))
                        }}
                        textValue={subclassFilterText}
                        setTextValue={(e: any) => dispatch(setSpellSubclassFilterText(e))}
                        dropdownList={sbSubclasses}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DsButton
                        label="Reset Filters"
                        icon={<ReplayOutlinedIcon sx={{ fontSize: "16px" }} />}
                        onClick={() => dispatch(resetSpellSortAndFilter())}
                    />
                </Grid>
            </ContentGrid>
        </Box>
    )
}

export default FilterSpellModal;
