import { Box, Grid, Modal, styled, Typography } from "@mui/material";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import myTheme from "../../../myTheme";
import { RootState } from "../../../redux/store";
import { useState } from "react";
import { Wrapper } from "../../dataDisplay/AccordionList/AccordionList";
import DsButton from "../../input/DsButton/DsButton";

export const ModalOuterContainer = styled(Box)({
    width: "80%",
    maxHeight: "80%",
    boxSizing: "border-box",
    overflowY: "scroll",
    backgroundColor: myTheme.bg,
    border: "2px solid " + myTheme.darkest,
    borderRadius: "15px"
})

export const ModalContentContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
})

export const ModalInnerContainer = styled(Box)({
    width: "100%",
    boxSizing: "border-box",
    padding: "0 15px 15px 15px"
})

export const ModalTitle = styled(Typography)({
    fontSize: "26px",
    color: myTheme.primary,
    textAlign: "center"
});

export const LabelText = styled(Typography)({
    color: myTheme.medGray,
    fontSize: "14px",
    textAlign: "center"
});

export interface GenericModalProps {
    visibilitySelector: (state: RootState) => boolean
    visibilityToggle: ActionCreatorWithPayload<boolean, string>
    Component?: JSX.Element
    Icon?: JSX.Element
    title?: string;
    name?: string;
    onClose?: () => void;
    confirmOnClose?: boolean;
}

const GenericModal = ({
    onClose,
    confirmOnClose,
    visibilitySelector,
    visibilityToggle,
    Component,
    Icon,
    title,
    name = "default"
}: GenericModalProps) => {
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    const modalVisible = useAppSelector(visibilitySelector);

    const dispatch = useAppDispatch();

    const handleClose = () => {
        if (confirmOnClose) {
            setConfirmOpen(true);
        } else {
            handleConfirm();
        }
    };

    const handleConfirm = () => {
        if (onClose) {
            onClose();
        }
        setConfirmOpen(false);
        dispatch(visibilityToggle(false));
    };

    const handleGoBack = () => {
        setConfirmOpen(false);
    };

    return (
        <Modal
            open={modalVisible}
            onClose={handleClose}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                marginBottom: "7.75vh",
                "& .MuiModal-backdrop": {
                    marginBottom: "7.75vh"   
                }
            }}
            data-testid={`modal__${name}__modal`}
        >
            <ModalOuterContainer>
                <Modal
                    open={confirmOpen}
                    onClose={handleGoBack}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none"
                    }}
                    data-testid={`modal__confirm__modal`}
                >
                    <ModalOuterContainer>
                        <ModalContentContainer>
                            <ModalTitle sx={{ padding: "10px 20px" }}>Are you sure you want to close this menu?</ModalTitle>
                            <ModalInnerContainer>
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <LabelText>Unsaved work will be lost</LabelText>
                                    <Wrapper sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "20px"
                                    }}>
                                        <DsButton label="Yes, close it" onClick={handleConfirm} />
                                        <DsButton label="No, go back" onClick={handleGoBack} />
                                    </Wrapper>
                                </Box>
                            </ModalInnerContainer>
                        </ModalContentContainer>
                    </ModalOuterContainer>
                </Modal>
                <ModalContentContainer>
                    {title && <ModalTitle>{title}</ModalTitle>}
                    <ModalInnerContainer>
                        {Component}
                    </ModalInnerContainer>
                </ModalContentContainer>
            </ModalOuterContainer>
        </Modal>
    )
}

export default GenericModal;