import { Box, Grid, Modal, styled, TextField, Typography } from "@mui/material";
import { randomUUID } from "crypto";
import { useEffect, useState } from "react";
import { getCRDataSet, parseCr, parseNumericCR } from "../../../constants/Cr.constants";
import Status from "../../../constants/CreatureStatus";
import Teams, { teamDataSet } from "../../../constants/Teams.constants";
import { validateInt, validatePosInt } from "../../../helper/validation/inputValidation";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import myTheme from "../../../myTheme";
import { addCreatureToInit } from "../../../redux/slices/initSlice";
import { IInitItem } from "../../../types/InitiativeTypes";
import DsButton from "../../input/DsButton/DsButton";
import DsDropdown from "../../input/DsDropdown/DsDropdown";
import DsInput from "../../input/DsInput/DsInput";
import { BiSave } from 'react-icons/bi';
import SimpleSchemaListEditor from "../../input/listEditors/SimpleSchemaListEditor/SimpleSchemaListEditor";
import { selectAuthToken } from "../../../redux/slices/userSlice";
import { createCreature, createSpell } from "../../../api/postData";
import { setAddCreatureActive, setAddSpellActive } from "../../../redux/slices/modalSlice";
import { updateSpell } from "../../../api/updateData";
import { addNewClass, addNewDistanceUnit, addNewMagicSchool, addNewSource, addNewSpellComponent, addNewSubclass, addNewTimeUnit, selectCSBClasses, selectCSBConditions, selectCSBCreatureTypes, selectCSBDamageTypes, selectCSBDistanceUnits, selectCSBItemTypes, selectCSBLanguages, selectCSBMagicSchools, selectCSBMovements, selectCSBRarities, selectCSBSenses, selectCSBSizes, selectCSBSkills, selectCSBSources, selectCSBSpellComponents, selectCSBSubclasses, selectCSBTimeUnits } from "../../../redux/slices/statblockItemSlice";
import { createNewItems, createNewItemsNoValue } from "../../../helper/createNewItems";
import SimpleSchemaValueListEditor from "../../input/listEditors/SimpleSchemaValueListEditor/SimpleSchemaValueListEditor";
import { selectCopySpell, selectSelectedSpell, setCopySpell, setSelectedSpell } from "../../../redux/slices/spellSlice";
import { IDistanceSchema, IDurationSchema, ISpell } from "../../../types/SpellTypes";
import DsTextarea from "../../input/DsTextarea/DsTextarea";
import { getSpellLevelDataSet, parseNumericSpellLevel, parseSpellLevel } from "../../../constants/SpellLevel.constants";
import { ISimpleSchema } from "../../../types/CreatureTypes";

const ContentGrid = styled(Grid)({});

const AddSpellModal = () => {

    const [name, setName] = useState<string>("");
    const [castingTimes, setCastingTimes] = useState<IDurationSchema[]>([]);
    const [classes, setClasses] = useState<ISimpleSchema[]>([]);
    const [components, setComponents] = useState<ISimpleSchema[]>([]);
    const [concentration, setConcentration] = useState<string>("false");
    const [durations, setDurations] = useState<IDurationSchema[]>([]);
    const [higherLevel, setHigherLevel] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [level, setLevel] = useState<string>("Cantrip");
    const [material, setMaterial] = useState<string>("");
    const [ranges, setRanges] = useState<IDistanceSchema[]>([]);
    const [ritual, setRitual] = useState<string>("false");
    const [schools, setSchools] = useState<ISimpleSchema[]>([]);
    const [subclasses, setSubclasses] = useState<ISimpleSchema[]>([]);
    const [source, setSource] = useState<ISimpleSchema[]>([]);

    const dispatch = useAppDispatch();
    const authToken = useAppSelector(selectAuthToken);
    const selectedSpell = useAppSelector(selectSelectedSpell);
    const copySpell = useAppSelector(selectCopySpell);
    const sbTimeUnits = useAppSelector(selectCSBTimeUnits);
    const sbClasses = useAppSelector(selectCSBClasses);
    const sbSpellComponents = useAppSelector(selectCSBSpellComponents);
    const sbDistanceUnits = useAppSelector(selectCSBDistanceUnits);
    const sbSubclasses = useAppSelector(selectCSBSubclasses);
    const sbSchools = useAppSelector(selectCSBMagicSchools);
    const sbSources = useAppSelector(selectCSBSources);

    useEffect(() => {
        if (selectedSpell) {
            setName(copySpell ? "Copy of " + selectedSpell.name : selectedSpell.name);
            setCastingTimes(selectedSpell.casting_time ? [{ ...selectedSpell.casting_time }] : []);
            setClasses(selectedSpell.classes);
            setComponents(selectedSpell.components);
            setConcentration(selectedSpell.concentration ? "true" : "false");
            setDurations(selectedSpell.duration ? [{ ...selectedSpell.duration }] : []);
            setHigherLevel(selectedSpell.higher_level ? selectedSpell.higher_level : "");
            setDescription(selectedSpell.description ? selectedSpell.description : "");
            setLevel(parseNumericSpellLevel(selectedSpell.level));
            setMaterial(selectedSpell.material ? selectedSpell.material : "");
            setRanges(selectedSpell.range ? [{ ...selectedSpell.range }] : []);
            setRitual(selectedSpell.ritual ? "true" : "false");
            setSchools(selectedSpell.school ? [{ ...selectedSpell.school }] : []);
            setSubclasses(selectedSpell.subclasses);
            setSource(selectedSpell.source && typeof selectedSpell.source === "object" ? [{ ...selectedSpell.source }] : []);
        }
    }, [selectedSpell]);

    const handleAdd = async () => {

        const newCastingTimes = await createNewItems(castingTimes, "timeUnit", "timeUnit", addNewTimeUnit);
        const newClasses = await createNewItemsNoValue(classes, "class", addNewClass);
        const newSpellComponents = await createNewItemsNoValue(components, "spellComponent", addNewSpellComponent);
        const newDurations = await createNewItems(durations, "timeUnit", "timeUnit", addNewTimeUnit);
        const newRanges = await createNewItems(ranges, "distanceUnit", "distanceUnit", addNewDistanceUnit);
        const newSchools = await createNewItemsNoValue(schools, "magicSchool", addNewMagicSchool);
        const newSubclasses = await createNewItemsNoValue(subclasses, "subclass", addNewSubclass);
        const newSources = await createNewItemsNoValue(source, "source", addNewSource);

        const newSpell: ISpell = selectedSpell && !copySpell ? {
            ...selectedSpell,
            name,
            casting_time: newCastingTimes ? newCastingTimes[0] : undefined,
            classes: newClasses ? newClasses : [],
            components: newSpellComponents ? newSpellComponents : [],
            concentration: concentration === "true",
            duration: newDurations ? newDurations[0] : undefined,
            higher_level: higherLevel,
            level: parseSpellLevel(level),
            material,
            range: newRanges ? newRanges[0] : undefined,
            ritual: ritual === "true",
            school: newSchools ? newSchools[0] : undefined,
            subclasses: newSubclasses ? newSubclasses : [],
            description,
            source: newSources && newSources?.length > 0 ? newSources[0] : selectedSpell.source
        } : {
            name,
            casting_time: newCastingTimes ? newCastingTimes[0] : undefined,
            classes: newClasses ? newClasses : [],
            components: newSpellComponents ? newSpellComponents : [],
            concentration: concentration === "true",
            duration: newDurations ? newDurations[0] : undefined,
            higher_level: higherLevel,
            level: parseSpellLevel(level),
            material,
            range: newRanges ? newRanges[0] : undefined,
            ritual: ritual === "true",
            school: newSchools ? newSchools[0] : undefined,
            subclasses: newSubclasses ? newSubclasses : [],
            description,
            source: newSources && newSources?.length > 0 ? newSources[0] : "62081d41cd68980016f931af"
        }

        let res;

        if (selectedSpell && !copySpell) {
            res = await updateSpell(newSpell);
        } else {
            res = await createSpell(newSpell, authToken);
        }

        if (res) {
            dispatch(setSelectedSpell(undefined));
            dispatch(setCopySpell(false));
            dispatch(setAddSpellActive(false));
        }
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <ContentGrid container rowSpacing={"10px"} columnSpacing={"20px"} sx={{ boxSizing: "border-box" }}>
                {/* Row */}
                <DsInput xs={12} label={"Name"} value={name} setValue={setName} name="name" />

                <DsDropdown xs={12}
                    name="level"
                    label="Level"
                    value={level}
                    setValue={setLevel}
                    data={getSpellLevelDataSet()}
                    attribute="level"
                />

                <Grid item xs={12}>
                    <SimpleSchemaListEditor
                        label="Magic School"
                        itemName="Magic School"
                        list={schools}
                        setList={setSchools}
                        dropdownList={sbSchools}
                        maxListLength={1}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SimpleSchemaListEditor
                        label="Classes"
                        itemName="Class"
                        list={classes}
                        setList={setClasses}
                        dropdownList={sbClasses}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SimpleSchemaListEditor
                        label="Subclasses"
                        itemName="Subclass"
                        list={subclasses}
                        setList={setSubclasses}
                        dropdownList={sbSubclasses}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SimpleSchemaValueListEditor
                        label="Casting Time"
                        itemName="Unit of Time"
                        list={castingTimes}
                        setList={setCastingTimes}
                        dropdownList={sbTimeUnits}
                        valueName="Time"
                        valueAttrName="time"
                        itemAttrName="timeUnit"
                        defaultValue={0}
                        maxListLength={1}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SimpleSchemaValueListEditor
                        label="Duration"
                        itemName="Unit of Time"
                        list={durations}
                        setList={setDurations}
                        dropdownList={sbTimeUnits}
                        valueName="Time"
                        valueAttrName="time"
                        itemAttrName="timeUnit"
                        defaultValue={0}
                        maxListLength={1}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SimpleSchemaValueListEditor
                        label="Range"
                        itemName="Unit of Distance"
                        list={ranges}
                        setList={setRanges}
                        dropdownList={sbDistanceUnits}
                        valueName="Distance"
                        valueAttrName="distance"
                        itemAttrName="distanceUnit"
                        defaultValue={0}
                        maxListLength={1}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SimpleSchemaListEditor
                        label="Components"
                        itemName="Component"
                        list={components}
                        setList={setComponents}
                        dropdownList={sbSpellComponents}
                    />
                </Grid>

                <DsInput xs={12}
                    label={"Materials"}
                    value={material}
                    setValue={setMaterial}
                    name="materials"
                />

                <DsDropdown xs={12}
                    name="ritual"
                    label="Ritual"
                    value={ritual}
                    setValue={setRitual}
                    data={[{ value: "true" }, { value: "false" }]}
                    attribute="value"
                />

                <DsDropdown xs={12}
                    name="concentration"
                    label="Concentration"
                    value={concentration}
                    setValue={setConcentration}
                    data={[{ value: "true" }, { value: "false" }]}
                    attribute="value"
                />

                {/* Row */}
                <DsTextarea xs={12}
                    name="description"
                    label="Description"
                    value={description}
                    setValue={setDescription}
                />

                <DsTextarea xs={12}
                    name="higherLevel"
                    label="At Higher Levels"
                    value={higherLevel}
                    setValue={setHigherLevel}
                />

                <Grid item xs={12}>
                    <SimpleSchemaListEditor
                        label="Source"
                        itemName="Source"
                        list={source}
                        setList={setSource}
                        dropdownList={sbSources}
                        maxListLength={1}
                    />
                </Grid>

                {/* Row */}
                <DsButton xs={12}
                    name="add"
                    icon={<BiSave fontSize={"20px"} />}
                    label="Save Magic Item"
                    onClick={handleAdd}
                />
            </ContentGrid>
        </Box>
    )
}

export default AddSpellModal;
