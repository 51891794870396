import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ISimpleSchema } from "../../types/CreatureTypes"
import { sortByName } from "../../helper/searching/sorts"
import { RootState } from "../store"
import { IMagicItem, MagicItemSortByOptions } from "../../types/MagicItemTypes"

interface MagicItemState {
    magicItems: IMagicItem[];
    selectedMagicItem?: IMagicItem;
    copyMagicItem: boolean;

    sortItemsBy: string;
    itemRarityFilterList: ISimpleSchema[];
    itemRarityFilterText: string;
    itemTypeFilterList: ISimpleSchema[];
    itemTypeFilterText: string;
    itemFilterByAttunement: string;
    itemSearch: string;
}

const initialState: MagicItemState = {
    magicItems: [],
    copyMagicItem: false,
    sortItemsBy: MagicItemSortByOptions.name,
    itemRarityFilterList: [],
    itemRarityFilterText: "",
    itemTypeFilterList: [],
    itemTypeFilterText: "",
    itemFilterByAttunement: "Either",
    itemSearch: ""
}

export const magicItemSlice = createSlice({
    name: "magicItems",
    initialState,
    reducers: {
        setMagicItems: (state, action: PayloadAction<IMagicItem[]>) => {
            state.magicItems = sortByName(action.payload);
        },
        addMagicItem: (state, action: PayloadAction<IMagicItem>) => {
            const newList = sortByName([...state.magicItems, action.payload])
            state.magicItems = newList;
        },
        editMagicItem: (state, action: PayloadAction<IMagicItem>) => {
            const newItem = action.payload;
            let i = 0;
            state.magicItems.forEach((item, index) => {
                if (item._id === newItem._id) i = index
            })
            const newArr = [...state.magicItems];
            newArr[i] = newItem;
            state.magicItems = newArr;
        },
        removeMagicItem: (state, action: PayloadAction<IMagicItem>) => {
            const newList = state.magicItems.filter(li => li._id !== action.payload._id);
            state.magicItems = newList;
        },
        setSelectedMagicItem: (state, action: PayloadAction<IMagicItem | undefined>) => {
            state.selectedMagicItem = action.payload
        },
        setCopyMagicItem: (state, action: PayloadAction<boolean>) => {
            state.copyMagicItem = action.payload;
        },
        setSortItemsBy: (state, action: PayloadAction<string>) => {
            state.sortItemsBy = action.payload;
        },
        setItemRarityFilterList: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.itemRarityFilterList = [...action.payload];
        },
        setItemRarityFilterText: (state, action: PayloadAction<string>) => {
            state.itemRarityFilterText = action.payload;
        },
        setItemTypeFilterList: (state, action: PayloadAction<ISimpleSchema[]>) => {
            state.itemTypeFilterList = [...action.payload];
        },
        setItemTypeFilterText: (state, action: PayloadAction<string>) => {
            state.itemTypeFilterText = action.payload;
        },
        setItemFilterByAttunement: (state, action: PayloadAction<string>) => {
            state.itemFilterByAttunement = action.payload;
        },
        setItemSearch: (state, action: PayloadAction<string>) => {
            state.itemSearch = action.payload;
        },
        resetItemSortAndFilter: (state) => {
            state.sortItemsBy = MagicItemSortByOptions.name;
            state.itemRarityFilterList = [];
            state.itemRarityFilterText = "";
            state.itemTypeFilterList = [];
            state.itemTypeFilterText = "";
            state.itemFilterByAttunement = "Either";
        }
    }
});

export const { 
    setMagicItems, 
    addMagicItem, 
    editMagicItem,
    removeMagicItem,
    setSelectedMagicItem,
    setCopyMagicItem,
    setSortItemsBy,
    setItemRarityFilterList,
    setItemRarityFilterText,
    setItemTypeFilterList,
    setItemTypeFilterText,
    setItemFilterByAttunement,
    resetItemSortAndFilter,
    setItemSearch
} = magicItemSlice.actions;

export const selectMagicItem = (state: RootState) => state.magicItems.magicItems
export const selectSelectedMagicItem = (state: RootState) => state.magicItems.selectedMagicItem
export const selectCopyMagicItem = (state: RootState) => state.magicItems.copyMagicItem;
export const selectSortItemsBy = (state: RootState) => state.magicItems.sortItemsBy;
export const selectItemTypeFilterList = (state: RootState) => state.magicItems.itemTypeFilterList;
export const selectItemTypeFilterText = (state: RootState) => state.magicItems.itemTypeFilterText;
export const selectItemRarityFilterList = (state: RootState) => state.magicItems.itemRarityFilterList;
export const selectItemRarityFilterText = (state: RootState) => state.magicItems.itemRarityFilterText;
export const selectItemFilterbyAttunement = (state: RootState) => state.magicItems.itemFilterByAttunement;
export const selectItemSearch = (state: RootState) => state.magicItems.itemSearch;

export default magicItemSlice.reducer