import { GridProps, styled } from "@mui/material";
import myTheme from "../../../myTheme";
import { InputWrapper } from "../DsInput/DsInput"
import { IconType } from "react-icons/lib";
import { DetailedHTMLProps, HTMLProps } from "react";

const StyledButton = styled("button")({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    width: "100%",
    color: myTheme.darkest,
    backgroundColor: myTheme.offWhite,
    borderRadius: "5px",
    padding: "5px 15px",
    fontSize: "14px",
    border: "none",
    ":hover": {
        cursor: "pointer"
    }
})

export interface DsButtonProps extends GridProps {
    label?: string;
    name?: string;
    icon?: JSX.Element;
    buttonProps?: DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
}

const DsButton = ({ onClick, label, name = "default", icon, buttonProps, ...props }: DsButtonProps) => {

    return (
        <InputWrapper data-testid={`dsButton__${name}__container`} item onClick={onClick} {...props}>
            {icon ? (
                <StyledButton {...buttonProps}>
                    {icon}{label}
                </StyledButton>
            ) : (
                <StyledButton {...buttonProps}>
                {label}
                 </StyledButton>
            )}
        </InputWrapper>
    )
}

export default DsButton;